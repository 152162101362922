<template>
    <div class="p-0">
        <div class="d-flex flex-column bg-white overflow-hidden position-relative" :style="`height: ${containerHeight}`" v-if="activeCalendar">
            <div class="position-absolute top-0 end-0 mt-4 me-4">
                <b-icon class="darkenTextOnHover text-purple" font-scale="1.25" icon="x-lg" @click="loadCalendar()" role="button"/>
            </div>
            <Calendar class="flex-fill" :openCreateCalendar="openCreateCalendar" :operator="operator" :templates="templates" :emitMSG="emitMSG"/>
        </div>
        <div class="d-flex flex-column bg-grey overflow-hidden pt-5 position-relative" :style="`height: ${containerHeight}`" v-else-if="showKanbanCards">
            <div class="position-absolute top-0 end-0 mt-3 me-3">
                <b-icon class="darkenTextOnHover text-purple" font-scale="1.25" icon="x-lg" @click="showKanbanCards = false" role="button"/>
            </div>
            <KanbanCards :user="user" :loadingKanbanCards="loadingKanbanCards" :kanbanSteps="kanbanSteps" :addingToStep="addingToStep" :getTagInfo="getTagInfo" :timestampDiff="timestampDiff" :mediaURL="mediaURL" />
        </div>
        <b-row class="m-0 bg-white" v-else>
            <b-col id="col1" class="m-0 p-0 overflow-hidden border-end d-flex flex-column" :style="`height: ${containerHeight}`">
                <div class="px-3 d-flex justify-content-between align-items-center flex-shrink-0" style="padding: 1.76em;">
                    <span class="position-relative">
                        <h6 class="text-secondary m-0" ref="attendanceDiv" @focus="getTabulations">Painel de Atendimento</h6>
                        <span class="numbercircle">{{ attendancesLength + groupsLength }}</span>
                    </span>
                    <div class="d-flex gap-3" style="max-height:1em" v-if="operator.status">
                        <b-icon id="recoverContact" font-scale="1.25" icon="arrow-left-right" @click="getRetrieveContacts(1, true); getHSMModels()" role="button" v-b-modal.modalRetrieveContact/>
                        <b-tooltip custom-class="mt-1 top-0" boundary="document" target="recoverContact" title="Recuperar Contato" placement="bottom"/>
                        <b-icon id="activeComm" class="darkenTextOnHover" font-scale="1.25" icon="archive" @click="showActiveCommunicationModal()" role="button" v-if="!operator.disableActiveCommunication"/>
                        <b-tooltip custom-class="mt-1 top-0" boundary="document" target="activeComm" title="Comunicação Ativa" placement="bottom"/>
                        <span v-if="operator.channelConfig?.modules?.schedule">
                            <div id="calendar" class="d-flex h-100">
                                <b-img  class="darkenTextOnHover" style="width: 1.25rem; height: 1.25rem;" :src="require('../assets/images/scheduleIcons/notebook.png')" role="button" @click="loadCalendar()"/>
                            </div>
                            <b-tooltip custom-class="mt-2 top-0" boundary="document" target="calendar" title="Agenda" placement="bottom"/>
                        </span>
                        <span v-if="operator.channelConfig?.modules?.kanban">
                            <b-icon id="kanbanCards" font-scale="1.25" icon="kanban" @click="getKanbanCards(); showKanbanCards = true" role="button"/>
                            <b-tooltip custom-class="mt-1 top-0" boundary="document" target="kanbanCards" title="Visualizar Kanban" placement="bottom"/>
                        </span>
                    </div>
                </div>
                <div class="p-3 d-flex justify-content-between align-items-center" style="background-color: hsl(248deg 50% 97%); color: #777; font-weight: 500" role="button" @click="requestNotificationPermission" v-if="browserNotification != 'granted'">
                    <b-icon font-scale="2" icon="bell"/>
                    <span>
                        <div style="font-size: 0.9em">Receba Notificações de Novas Mensagens</div>
                        <div style="font-size: 0.8em">Ative Notificações na Área de Trabalho</div>
                    </span>
                    <b-icon icon="chevron-double-right"/>
                </div>
                <div class="bg-light-purple2 d-flex align-items-center px-3 py-2" v-if="operatorHasGroups || operator?.channelConfig?.modules?.internalChat">
                    <div class="d-flex align-items-center darkenTextOnHover" :class="attendanceType === 'contacts' ? 'text-dark-purple' : 'text-light-purple'" @click="pickAttendanceType('contacts')" role="button">
                        <div class="position-relative me-3">
                            <b-icon font-scale="1.5" icon="person"/>
                            <span class="numbercircle2 bg-orange text-white rounded-circle position-absolute start-50 bottom-0 ms-1">
                                <!-- {{ attendancesLength || 0 }} -->
                                {{ attendancesUnreadCount || 0 }}
                            </span>
                        </div>
                        <div class="fw-semibold">
                            Atendimentos
                        </div>
                    </div>
                    <div class="d-flex align-items-center darkenTextOnHover ms-4" :class="attendanceType === 'groups' ? 'text-dark-purple' : 'text-light-purple'" @click="pickAttendanceType('groups')" role="button" v-if="operatorHasGroups">
                        <div class="position-relative me-3">
                            <b-icon font-scale="1.5" icon="people"/>
                            <span class="numbercircle2 bg-orange text-white rounded-circle position-absolute start-50 bottom-0 ms-1">
                                <!-- {{ groupsLength || 0 }} -->
                                {{ groupsUnreadCount || 0 }}
                            </span>
                        </div>
                        <div class="fw-semibold">
                            Grupos
                        </div>
                    </div>
                    <div class="d-flex align-items-center darkenTextOnHover ms-4" :class="attendanceType === 'internalChat' ? 'text-dark-purple' : 'text-light-purple'" @click="pickAttendanceType('internalChat')" role="button" v-if="operator?.channelConfig?.modules?.internalChat">
                        <div class="position-relative me-3">
                            <b-icon font-scale="1.5" icon="headset"/>
                            <span class="numbercircle2 bg-orange text-white rounded-circle position-absolute start-50 bottom-0 ms-1">
                                {{ internalChatsUnreadCount || 0 }}
                            </span>
                        </div>
                        <div class="fw-semibold">
                            Chat Interno
                        </div>
                    </div>
                </div>
                <div class="px-3 py-3 d-flex align-items-center flex-shrink-0" style="background-color: hsl(251deg 49% 93%);">
                    <form @submit.prevent="searchOldAttendances" class="w-100">
                        <b-input-group class="d-flex align-items-center bg-white rounded-pill text-secondary">
                            <template #prepend>
                                <span @click="searchOldAttendances">
                                    <b-icon class="bg-white ms-2" icon="search" role="button"/>
                                </span>
                                <span class="bg-success text-light rounded-pill d-inline-flex mx-1 px-2 py-1 w-fit smaller-text d-flex align-items-center justify-content-end" v-if="clientSearch.filterText">
                                    <span>
                                        {{ clientSearch.filterText }}
                                    </span>
                                    <b-icon class="text-dark darkenTextOnHover ms-2" icon="x-circle-fill" role="button" @click="cleanSearchFilter"/>
                                </span>
                            </template>
                            <b-form-input type="search" class="border-0 shadow-none rounded-pill" 
                            :placeholder="searchPlaceholder" 
                            style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                            v-model="clientSearch.value"
                            />
                        </b-input-group>
                        <!-- <div class="text-light" v-if="attendanceType == 'contacts' && clientSearch?.value">
                            <span class="bg-secondary rounded-pill d-inline-flex me-2 mt-2 px-2 py-1 w-fit smaller-text darkenOnHover" role="button" @click="selectSearchFilter(1)">
                                Fila de Atendimento
                            </span>
                            <span class="bg-secondary rounded-pill d-inline-flex me-2 mt-2 px-2 py-1 w-fit smaller-text darkenOnHover" role="button" @click="selectSearchFilter(2)" v-if="operator.channelConfig?.enableCustomerPortfolio">
                                Carteira de Clientes
                            </span>
                            <span class="bg-secondary rounded-pill d-inline-flex me-2 mt-2 px-2 py-1 w-fit smaller-text darkenOnHover" role="button" @click="selectSearchFilter(3)">
                                Finalizados
                            </span>
                            <span class="bg-secondary rounded-pill d-inline-flex me-2 mt-2 px-2 py-1 w-fit smaller-text darkenOnHover" role="button" @click="selectSearchFilter(4)">
                                Abandonados
                            </span>
                            <span class="bg-secondary rounded-pill d-inline-flex px-2 mt-2 py-1 w-fit smaller-text darkenOnHover" role="button" @click="selectSearchFilter(5)">
                                Dado Enriquecido
                            </span>
                        </div> -->
                    </form>
                </div>
                <InternalChats :socket="socket" :operator="operator" :user="user" :timestampDiff="timestampDiff" :mediaURL="mediaURL" :search="internalSearch" :internalNewMessage="internalNewMessage" @loadOperatorChat="getInternalChat" @internalChatsUnreadCount="(count) => internalChatsUnreadCount = count" v-if="attendanceType === 'internalChat'" />
                <div class="clientsWrapper h-100" @scroll="handleScrollAttendance" v-else>
                    <b-overlay class="h-100" :show="startingAttendance || loadingAttendances">
                        <div v-for="item in showAtts()" :key="item?._id">
                            <b-row class="clientsDiv attendancesRow m-0 p-3 border-bottom d-flex gap-2" @click.self="selectClient(item)" v-if="!clientSearch.value">
                            <!-- <b-row class="clientsDiv attendancesRow m-0 p-3 border-bottom d-flex gap-2" v-bind:class="{ 'bg-secondary':item.status == 'finished' || item.status == 'abandoned', 'bg-success':item.status == 'in_attendance', 'bg-primary':item.status == 'waiting', 'bg-light-purple2':item.status == 'offline_operators' }" style="--bs-bg-opacity: .25;" @click.self="selectClient(item)" v-if="!clientSearch.value"> -->
                                <b-col cols="2" class="p-0 d-flex align-items-center flex-basis-content" @click="selectClient(item)">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(item.photoURL)"/>
                                </b-col>
                                <b-col class="p-0 d-flex align-items-center overflow-hidden" @click.self="selectClient(item)">
                                    <div class="d-flex flex-column ps-2 w-100 mw-100" @click="selectClient(item)">
                                        <div class="font-weight-bold text-secondary text-truncate position-relative w-min mw-100">
                                            <span>
                                                {{ (item.clientName || verifyPrivacy(item.clientNumber)) || item.name }}
                                                <span v-if="item.state">
                                                    {{ " " }} de {{ item.state }}
                                                </span>
                                                &#8205; &#8205; &#8205; &#8205;
                                            </span>
                                            <div class="position-absolute rounded-circle" v-bind:class="{ 'bg-secondary':item.status == 'finished' || item.status == 'abandoned' || item.status == 'in_survey', 'bg-success':item.status == 'in_attendance', 'bg-primary':item.status == 'waiting', 'bg-purple':item.status == 'offline_operators' }" :style="`right: 0em; top:0px; width:10px; height:10px`"></div>
                                        </div>
                                        <div class="text-secondary text-truncate small-text lh-085" v-if="item.clientCompany">
                                            {{ item.clientCompany }}
                                        </div>
                                        <div class="text-secondary text-truncate small-text" :class="{ 'lh-085': !item.clientCompany }" v-if="item.clientName != item.clientNumber">
                                            {{ verifyPrivacy(formatNumber(item.clientNumber)) }}
                                        </div>
                                        <Steps
                                            class="text-white mt-1 w-100"
                                            variant="pink"
                                            :steps="item.kanbanSteps?.map((step, index) => ({ _id: step._id, tooltipText: `Etapa ${$options.filters.pad(index + 1, 2)} ${step.name}` }))"
                                            :currentStepIndex="item.kanbanSteps?.findIndex(el => el._id == item.kanbanStepId) + 1 || 0"
                                            style="max-width: 10rem"
                                            v-if="item.cardKanbanId && operator.channelConfig?.modules?.kanban"
                                        />
                                        <div class="clientStatus text-secondary" :class="{ 'lh-085': !item.clientNumber && !item.clientCompany }" style="font-size: 0.8em">
                                            <span v-if="item.draft">
                                                <span class="text-success fw-semibold">
                                                    Rascunho:
                                                </span>
                                                {{ item.draft }}
                                                &#8203;
                                            </span>
                                            <span class="text-truncate" v-else>
                                                <span class="me-1" v-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.fromMe">
                                                    <b-icon icon="clock" v-if="!lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.statusAck"/>
                                                    <b-icon icon="check-all" scale="1.3" variant="primary" v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.statusAck==3"/>
                                                    <b-icon icon="check-all" scale="1.3" v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.statusAck==2"/>
                                                    <b-icon icon="check" scale="1.3" v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.statusAck==1"/>
                                                </span>
                                                <!-- {{ lastMsg(item.msgs)?.show }} -->
                                                <span v-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=image'">
                                                    <b-icon icon="camera-fill"/>
                                                    Foto
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=ptt' || lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=audio'">
                                                    <b-icon icon="mic-fill"/>
                                                    Áudio
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=video'">
                                                    <b-icon icon="camera-video-fill"/>
                                                    Vídeo
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=application' || lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=document' || lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=file'">
                                                    <b-icon icon="file-earmark-fill"/>
                                                    Arquivo
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=vcard'">
                                                    <b-icon icon="person-fill"/>
                                                    Contato
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=vcardArray'">
                                                    <b-icon icon="people-fill"/>
                                                    Contatos
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=location'">
                                                    <b-icon icon="geo-alt-fill"/>
                                                    Localização
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=sticker'">
                                                    <b-icon icon="sticky-fill"/>
                                                    Sticker
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=template'">
                                                    Modelo HSM
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=product_list'">
                                                    <b-icon icon="heart"/>
                                                    Lista de produtos
                                                </span>
                                                <span v-else-if="lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=checkout_cart' || lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show == 'type=order'">
                                                    <b-icon icon="cart4"/>
                                                    Carrinho de produtos
                                                </span>
                                                <span v-else>{{lastMsg(item.msgs?.length ? item.msgs : [item.lastMsg])?.show}}</span>
                                                &#8203;
                                            </span>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="2" class="p-0 d-flex align-items-center flex-basis-content position-relative gap-2">
                                    <div class="d-flex align-items-center align-self-center py-0 text-secondary" 
                                        style="height: min-content"
                                        v-if="operator.channelConfig?.modules?.operatorAttendanceTimer && item.status !== 'finished' && item.status !== 'abandoned' && item.status !== 'in_survey' && item.status !== 'validate' && !item.from"
                                    >
                                        {{ item.timer }}
                                    </div>
                                    <div class="d-flex align-items-center align-self-center bg-primary rounded-circle text-white px-2 py-0" 
                                        style="height: min-content"
                                        v-if="item.newMsgs"
                                        @click="selectClient(item)"
                                    >
                                        {{ item.newMsgs }}
                                    </div>
                                    <b-icon class="bg-purple text-white p-1 rounded-circle darkenOnHover" font-scale="1.5" icon="chevron-down" @click="showContacts(item)" v-if="attendanceType == 'groups'"/>
                                    <div v-if="item.status == 'finished' || item.status == 'abandoned' || item.status == 'in_survey' || item.status == 'validate'">
                                        <b-icon id="recoverSelected" font-scale="1.25" icon="arrow-left-right" @click="startAttendance(item); getHSMModels()"/>
                                        <b-tooltip custom-class="mt-1 top-0" boundary="document" target="recoverSelected" title="Recuperar Contato" placement="bottomright"/>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="clientsDiv attendancesRow m-0 p-3 border-bottom d-flex rounded-bottom" @click.self="selectClient(item)" v-else>
                                <b-col cols="2" class="p-0 d-flex align-items-center flex-basis-content me-2" @click="selectClient(item)">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(item.photoURL)"/>
                                </b-col>
                                <b-col class="p-0 d-flex align-items-center overflow-hidden">
                                    <div class="d-flex flex-column ps-2 mw-100" @click="selectClient(item)">
                                        <div class="font-weight-bold text-secondary text-truncate position-relative w-min mw-100">
                                            <span>
                                                {{ item.clientName || verifyPrivacy(item.clientNumber) || item.name }}
                                                <span v-if="item.state">
                                                    {{ " " }} de {{ item.state }}
                                                </span>
                                                &#8205; &#8205; &#8205; &#8205;
                                            </span>
                                            <div class="position-absolute rounded-circle" v-bind:class="{ 'bg-secondary':item.status == 'finished' || item.status == 'abandoned' || item.status == 'in_survey', 'bg-success':item.status == 'in_attendance', 'bg-primary':item.status == 'waiting', 'bg-purple':item.status == 'offline_operators' }" :style="`right: 0em; top:0px; width:10px; height:10px`"></div>
                                        </div>
                                        <div class="text-secondary text-truncate small-text lh-085" v-if="item.clientCompany">
                                            {{ item.clientCompany }}
                                        </div>
                                        <div class="text-secondary text-truncate small-text" :class="{ 'lh-085': !item.clientCompany }" v-if="item.clientName != item.clientNumber">
                                            {{ verifyPrivacy(formatNumber(item.clientNumber)) }}
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="2" class="p-0 d-flex align-items-center justify-content-end position-relative flex-basis-content ms-3">
                                    <b-icon class="bg-purple text-white rounded-circle p-1 darkenOnHover" font-scale="1.5" icon="chevron-down" @click="showContacts(item)" v-if="attendanceType == 'groups'"/>
                                    <div v-if="item.status == 'finished' || item.status == 'abandoned' || item.status == 'in_survey' || item.status == 'validate'">
                                        <b-icon id="recoverSelected" font-scale="1.25" icon="arrow-left-right" @click="startAttendance(item); getHSMModels()"/>
                                        <b-tooltip custom-class="mt-1 top-0" boundary="document" target="recoverSelected" title="Recuperar Contato" placement="bottomright"/>
                                    </div>
                                </b-col>
                            </b-row>
                            <div v-if="item.showContacts">
                                <div class="bg-light text-secondary text-center py-2 border-bottom" v-if="!item.participants?.length">
                                    Nenhum Contato Encontrado!
                                </div>
                                <div v-else>
                                    <div v-for="contact in item.participants" :key="contact._id">
                                        <div class="d-flex align-items-center p-3 bg-light border-bottom">
                                            <b-avatar class="bg-secondary text-white" :src="verifyPhotoURL(contact.photoURL)"/>
                                            <div class="ms-2 text-secondary">
                                                {{ contact.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </div>
                <div class="d-flex flex-column px-3 text-secondary flex-shrink-0" style="background-color: hsl(248deg 50% 97%); padding: 1.15em 0" v-if="attendanceType !== 'internalChat'">
                    <h6 class="m-0">Status do Atendimento</h6>
                    <div class="d-flex gap-2 smaller-text">
                        <span class="d-inline-flex align-items-center gap-1" :class="{ 'text-decoration-line-through': statusFilter && statusFilter !== 'finished' }" @click="pickAttendancesFilter('finished')" role="button">
                            <span class="text-white bg-secondary rounded-pill fw-normal h-fit" style="font-size:0.75em; padding: 0.3em 0.5em">{{ finishedAttendanceCount }}</span>
                            Finalizado
                        </span>
                        <span class="d-inline-flex align-items-center gap-1" :class="{ 'text-decoration-line-through': statusFilter && statusFilter !== 'offline_operators' }" @click="pickAttendancesFilter('offline_operators')" role="button">
                            <span class="text-white bg-purple rounded-pill fw-normal h-fit" style="font-size:0.75em; padding: 0.3em 0.5em">{{ queueAttendanceCount }}</span>
                            Fila de Espera
                        </span>
                        <span class="d-inline-flex align-items-center gap-1" :class="{ 'text-decoration-line-through': statusFilter && statusFilter !== 'in_attendance' }" @click="pickAttendancesFilter('in_attendance')" role="button">
                            <span class="text-white bg-success rounded-pill fw-normal h-fit" style="font-size:0.75em; padding: 0.3em 0.5em">{{ inAttendanceCount }}</span>
                            Em Atendimento
                        </span>
                        <span class="d-inline-flex align-items-center gap-1" :class="{ 'text-decoration-line-through': statusFilter && statusFilter !== 'waiting' }" @click="pickAttendancesFilter('waiting')" role="button">
                            <span class="text-white bg-primary rounded-pill fw-normal h-fit" style="font-size:0.75em; padding: 0.3em 0.5em">{{ waitingAttendanceCount }}</span>
                            Aguardando Atendimento
                        </span>
                    </div>
                </div>
            </b-col>
            <b-col id="col2" class="m-0 p-0 overflow-hidden border-left d-flex flex-column" :style="`flex-grow: 1.9; height: calc(100dvh - ${headerHeight}px)`" v-if="attendanceType !== 'internalChat'">
                <div class="py-3 px-3 d-flex justify-content-between align-items-center flex-shrink-0" v-if="clientSelected._id">
                    <span class="d-flex align-items-center gap-3" role="button" @click.self="getClientData(true)">
                        <b-icon id="mobile-back-button" icon="arrow-left" font-scale="1.25" style="display: none" @click="hideMessages"/>
                        <span class="position-relative" @click.self="getClientData(true)">
                            <span @click="getClientData(true)">
                                <b-avatar class="bg-secondary text-white" size="2.75em" :src="verifyPhotoURL(clientSelected.photoURL)"/>
                            </span>
                            <span class="position-absolute" style="left: 3.75em; top: 0em;">
                                <div class="rounded-pill px-2 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(clientSelected.tag)?.color || '#000'}; font-size: 0.42em; padding: .3em 0`" role="button" @click="toggleTagName(clientSelected)" v-if="clientSelected.tag">
                                    <b-icon icon="circle-fill" font-scale="0.9"/>
                                    &#8205;
                                    <div class="ms-1 text-uppercase" v-show="clientSelected.showTagName">
                                        {{ getTagInfo(clientSelected.tag)?.name }}
                                    </div>
                                </div>
                            </span>
                        </span>
                        <span class="d-flex align-self-center" @click="getClientData(true)">
                            <h6 class="text-secondary m-0" style="font-size: 0.9em;">
                                {{(clientSelected.clientName || verifyPrivacy(clientSelected.clientNumber)) || clientSelected.name }}
                                <span v-if="clientSelected.state">
                                    {{ " " }} de {{ clientSelected.state }}
                                </span>
                            </h6>
                        </span>
                    </span>
                    <span class="hstack gap-3">
                        <!-- <div
                            id="timer-container"
                            class="hstack justify-content-center rounded-circle position-relative"
                            :class="!attendanceWindowIsOpened && 'border border-3 border-danger'"
                            style="width: 3em; height: 3em"
                            v-b-tooltip.hover.bottomright="{ customClass: 'top-0 mt-1', boundary: 'document', variant: attendanceWindowIsOpened ? 'success' : 'danger' }" :title="attendanceWindowIsOpened ? 'Tempo restante da janela de conversação' : 'Janela de conversação fechada'"
                            v-if="clientSelected.channelType === 'gupshup' || clientSelected.channelType === 'cloud'"
                        >
                            <b-icon icon="x" font-scale="2" class="text-danger" v-if="!attendanceWindowIsOpened"/>
                            <div class="text-success small-text" v-else>
                                {{ windowRemainingTime }}
                            </div>
                            <canvas id="border-canvas" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></canvas>
                        </div> -->
                        <div class="crmButtonWrapper fw-semibold d-flex" role="button" @click="toggleCrmData(); !clientSelected.crmData && getClientCRMData()" v-if="operator.channelConfig?.modules?.crm && ['all', ...operator.department].some(e => operator.channelConfig.rdDepartments?.includes(e))">
                            <div class="border border-dark border-end-0 rounded-start-1 px-1"></div>
                            <div class="border border-dark rounded-end-1 px-1 small-text d-flex align-items-center justify-content-center">
                                CRM
                            </div>
                        </div>
                        <div class="panelicons" v-for="item in whatsappicons" :key="item.id" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" :title="item.tooltip">
                            <b-icon class="panelicon" :icon="item.name" :style="item.style" v-if="item.name=='search'"/>
                            <b-dropdown menu-class="dropdownanexes" toggle-class="p-0 border-0 bg-transparent bg-transparent2" no-caret no-flip v-else-if="clientSelected.status!='finished' && clientSelected.status!='abandoned' && clientSelected.status != 'in_survey' && item.name=='paperclip' && attendanceWindowIsOpened && operator && !operator.disableMedia">
                                <template #button-content>
                                    <b-icon class="panelicon" :icon="item.name" :style="item.style" scale="1.4"/>
                                </template>
                                <b-dropdown-item-button class="rounded-circle mt-0 dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(291deg 55% 58%)" @click="toggleInsertFile('image/*')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Fotos">
                                    <b-icon icon="image-fill"/>
                                </b-dropdown-item-button>
                                <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(88deg 50% 56%)" @click="toggleInsertFile('.mp4')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Vídeos">
                                    <b-icon icon="camera-video-fill"/>
                                </b-dropdown-item-button>
                                <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(236deg 53% 59%)" 
                                @click="toggleInsertFile('application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow')" 
                                v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Arquivo"
                                >
                                <b-icon icon="file-earmark-fill"/>
                                </b-dropdown-item-button>
                                <!-- <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(33deg 86% 57%)" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Localização">
                                    <b-icon icon="geo-alt-fill"/>
                                </b-dropdown-item-button>
                                <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(199deg 94% 50%)" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Contato">
                                    <b-icon icon="person-fill"/>
                                </b-dropdown-item-button> -->
                            </b-dropdown>
                            <b-icon class="panelicon" :icon="item.name" :style="item.style" v-b-modal.modal-journal-text v-else-if="item.name=='journal-text'"/>
                            <!-- <b-icon class="panelicon" :icon="item.name" :style="item.style" v-else/> -->
                            <div class="iconnotifications rounded-circle">{{item.notificationsnumber}}</div>
                        </div>
                    </span>
                </div>
                <div class="invisible flex-shrink-0" style="padding: 1.63em;" v-else>
                    123
                </div>
                <div class="p-2 p-sm-3 text-white d-flex align-items-center w-100 flex-shrink-0" style="background-color: hsl(142deg 28% 49%)" v-if="!insertFile">
                    <span class="d-flex gap-2 w-100" v-if="clientSelected?._id && !clientSelected?.from">
                        <div id="protocolDiv" class="rounded-pill px-3 px-sm-4 py-1 py-sm-2 w-100 box-clamp-1 lh-2" style="background-color: hsl(87deg 55% 65%);">
                            <span class="h-100 d-inline-flex align-items-center gap-1 me-1 protocolText">
                                Protocolo: <span class="fw-bold">{{ clientSelected.protocol }}</span>
                            </span>
                            <span v-if="clientSelected.channelNumber">
                                Ativo em: <span class="fw-bold">{{ clientSelected.channelNumber | formatNumber }}</span>
                            </span>
                        </div>
                        <span class="d-flex align-self-center align-self-sm-auto gap-2" v-if="clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id && !clientSelected.from">
                            <b-button class="finishBtn rounded-circle btn-danger border-0" v-b-tooltip.hover.bottom="{ customClass: 'mt-1 top-0', boundary: 'document' }" title="Finalizar Atendimento"  @click="finishAttendance(clientSelected)"><b-icon scale="1.75" icon="x"/></b-button>
                            <b-button class="forwardBtn rounded-circle border-0" style="background-color: hsl(87deg 55% 65%); border-color: hsl(87deg 55% 65%);" v-b-tooltip.hover.bottom="{ customClass: 'mt-1 me-6-2 top-0', boundary: 'document' }" title="Encaminhar Atendimento" @click="getForwardList()" v-b-modal.modal-fwdprotocol><b-icon scale="1.75" icon="arrow-left-short"/></b-button>
                        </span>
                    </span>
                    <div class="d-flex align-items-center w-100" v-else-if="clientSelected.from">
                        <b-input-group class="d-flex align-items-center rounded-pill text-secondary w-100" :class="clientSelected ? 'bg-white' : 'bg-disabled'">
                            <template #prepend>
                                <b-icon class="bg-inherit mx-2" icon="search" :role="clientSelected ? 'button' : 'text'"/>
                            </template>
                            <b-form-input type="search" class="border-0 shadow-none rounded-pill p-2" 
                            :placeholder="clientSelected.msgs?.length ? 'Buscar Mensagem' : 'Nenhuma mensagem!'" 
                            :disabled="!clientSelected.msgs?.length"
                            style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                            v-model="inputSearchGroupMsg"
                            @input="searchGroupMsg"
                            />
                        </b-input-group>
                        <div class="d-flex align-items-center ms-2" v-if="inputSearchGroupMsg">
                            <div class="w-max border-bottom border-white">
                                {{ msgSearchActual }} de {{ msgSearchTotal }}
                            </div>
                            <b-icon class="mx-2 darkenTextOnHover" icon="chevron-up" @click="goToMessageSearch('up')" role="button"/>
                            <b-icon class="darkenTextOnHover" icon="chevron-down" @click="goToMessageSearch('down')" role="button"/>
                        </div>
                    </div>
                    <div class="rounded-pill px-4 py-2 w-100" style="background-color: hsl(87deg 55% 65%)" v-else>
                        <b-icon font-scale="0.9" icon="arrow-left" v-if="window.innerWidth <= 425" @click="hideMessages"/>
                        Sem Atendimento
                    </div>
                </div>
                <div class="msgsScroll h-100 overflow-y-auto" v-if="!insertFile">
                    <b-overlay class="h-100" :show="loadingMessages" rounded="sm">
                        <div class="position-relative h-100">
                            <div class="msgsdiv">
                                <b-button class="msgsScrollButton border-0 btn-secondary" v-bind:class="{'btn-success': newMessage}" @click="scrollBottomMsgsDiv()" v-if="clientSelected?.msgs?.length">
                                    <b-icon font-scale="0.9" icon="chevron-double-down" />
                                </b-button>
                                <b-table primary-key="timestamp" head-variant="transparent" borderless class="tablemsgs" id="tablemsgs" :fields="['body']" sort-by="timestamp" :items="clientSelected.msgs" v-if="clientSelected?._id">
                                    <template #head()>
                                        <div class="d-flex justify-content-center">
                                            <div class="d-flex align-items-center" role="button" @click="loadProtocols()" v-if="!clientSelected.from">
                                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" style="animation:spinner-border .75s linear infinite" font-scale="2" icon="arrow-repeat" v-if="loadingProtocol"/>
                                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" font-scale="2" icon="arrow-repeat" v-else/>
                                                Carregar Histórico
                                            </div>
                                            <div class="d-flex align-items-center" role="button" @click="loadGroupPreviousMessages()" v-else-if="clientSelected.from">
                                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" style="animation:spinner-border .75s linear infinite" font-scale="2" icon="arrow-repeat" v-if="loadingPreviousGroupMessages"/>
                                                <b-icon class="text-white bg-success rounded-circle p-1 me-2" font-scale="2" icon="arrow-repeat" v-else/>
                                                Carregar Mensagens
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell()="data">
                                        <div class="rounded mx-5 p-2 my-2 text-white" style="background-color: rgba(0, 0, 0, 0.4)" v-if="data.item.type=='protocolStart'">
                                            <div class="font-weight-bold">Atendimento iniciado em: {{ formatDate(data.item.createdAt) }} - Protocolo: {{ data.item.protocol }}</div>
                                        </div>
                                        <div class="text-white w-100 d-flex justify-content-center" v-else-if="data.item.type=='date'">
                                            <div class="font-weight-bold w-fit rounded mx-5 p-2 my-2" style="background-color: rgba(0, 0, 0, 0.4)">{{ new Date(data.item.date) | date('DD/MM/YY') }}</div>
                                        </div>
                                        <div id="msgs" class="h-100 d-flex position-relative" :class="{ 'justify-content-end': data.item.fromMe }" v-else>
                                            <span class="d-flex align-items-center" :class="{ 'mb-4': data.item.react?.length , 'justify-content-end': data.item.fromMe}">
                                                <div class="reactButton d-none align-items-center h-100" :class="{ 'justify-content-end': data.item.fromMe, 'order-2': !data.item.fromMe }" v-if="inMessageSupportedTypes(data.item.type)">
                                                    <b-icon class="bg-secondary text-white rounded-circle p-1 darkenOnHover" font-scale="1.5" icon="emoji-smile" role="button" @click="toggleDialogReactEmoji(data.item)"/>
                                                </div>
                                                <div ref="inputReactEmojiPicker" v-if="data.item.showReactEmojiDialog">
                                                    <VEmojiPicker class="inputReactEmojiPicker" style="z-index: 1001" @select="selectReactEmoji" lang="pt-BR" :i18n="i18n" :style="msgsHeight ? `height: calc(${msgsHeight} - 5em)` : 'height: 18vw'" />
                                                </div>
                                                <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: 1000" @click="toggleDialogReactEmoji(data.item)" v-if="data.item.showReactEmojiDialog">
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-4 pt-3" style="max-width: 50%" v-bind:class="{ 'msgreceived': !data.item?.fromMe, 'msgsent': data.item?.fromMe }" v-if="data.item.type == 'image'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" v-if="data.item.isForwarded"/>
                                                    <img class="rounded" v-bind:src="hasFullPath(data.item.mediaURL)" style="max-width:100%; height: auto;" @click="selectMedia(hasFullPath(data.item.mediaURL))" v-b-modal.modal-receivedmedia v-if="data.item.mediaURL"/>
                                                    <div style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                                    <div style="white-space:break-spaces" v-else-if="data.item.body && notBase64(data.item.body)">{{data.item.body}}</div>
                                                    <Msgdt :item="data.item"/>
                                                    <div v-if="data.item.fromMe && ((Date.now() / 1000) - data.item.timestamp >= resentTime) && !data.item.statusAck && ((!clientSelected.from && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id) || (clientSelected.from))">
                                                        <div class="small-text text-red d-flex align-items-end gap-1 w-fit" v-b-tooltip.hover.top="{ customClass: 'top-0', boundary: 'document', variant: 'red' }" title="Reenviar Mensagem" @click="resendMsg(data.item)" role="button" v-if="!data.item.tryingResend">
                                                            <b-icon icon="arrow-clockwise" />
                                                            <span class="smaller-text">
                                                                Talvez a mensagem não tenha sido enviada. Clique aqui para reenviar.
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <b-spinner small variant="secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                </div>
                                                <div class="p-1 pb-4 w-50" v-bind:class="{ 'msgreceived': !data.item?.fromMe, 'msgsent': data.item?.fromMe }" v-else-if="data.item.type=='video'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <video class="rounded" controls style="max-width:100%">
                                                        <source :src="hasFullPath(data.item.mediaURL)" type="video/mp4">
                                                    </video>
                                                    <div class="px-2" style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                                    <div class="px-2" style="white-space:break-spaces" v-else-if="data.item.body && notBase64(data.item.body)">{{data.item.body}}</div>
                                                    <Msgdt :item="data.item"/>
                                                    <div v-if="data.item.fromMe && ((Date.now() / 1000) - data.item.timestamp >= resentTime) && !data.item.statusAck && ((!clientSelected.from && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id) || (clientSelected.from))">
                                                        <div class="small-text text-red d-flex align-items-end gap-1 w-fit" v-b-tooltip.hover.top="{ customClass: 'top-0', boundary: 'document', variant: 'red' }" title="Reenviar Mensagem" @click="resendMsg(data.item)" role="button" v-if="!data.item.tryingResend">
                                                            <b-icon icon="arrow-clockwise" />
                                                            <span class="smaller-text">
                                                                Talvez a mensagem não tenha sido enviada. Clique aqui para reenviar.
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <b-spinner small variant="secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-4" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" v-else-if="data.item.type=='ptt' || data.item.type=='audio'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <video class="rounded audioplayer" v-bind:class="{'apreceived':!data.item?.fromMe ,'apsent':data.item?.fromMe}" controls :src="hasFullPath(data.item.mediaURL)" style="height:3em; width:18em"></video>
                                                    <!-- <div class="text-secondary position-absolute" style="font-size: 0.7em; bottom: 2px; left:5px" v-if="browser!='Firefox'">Caso não toque, utilize o Firefox!</div> -->
                                                    <Msgdt :item="data.item" />
                                                    <div v-if="data.item.fromMe && ((Date.now() / 1000) - data.item.timestamp >= resentTime) && !data.item.statusAck && ((!clientSelected.from && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id) || (clientSelected.from))">
                                                        <div class="small-text text-red d-flex align-items-end gap-1 w-fit" v-b-tooltip.hover.top="{ customClass: 'top-0', boundary: 'document', variant: 'red' }" title="Reenviar Mensagem" @click="resendMsg(data.item)" role="button" v-if="!data.item.tryingResend">
                                                            <b-icon icon="arrow-clockwise" />
                                                            <span class="smaller-text">
                                                                Talvez a mensagem não tenha sido enviada. Clique aqui para reenviar.
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <b-spinner small variant="secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='location'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <div class="p-1 pb-4" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" style="width:50%">
                                                        <Msgfromgroup :msg="data.item"/>
                                                        <Msgforwarded :msg="data.item" />
                                                        <LMap class="m-0" :loc="data.item"/>
                                                        <Msgdt :item="data.item" />
                                                        <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                            <b-icon icon="info-circle" />
                                                            Mensagem apagada pelo contato!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='vcard'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <div class="p-2 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }">
                                                        <Msgfromgroup :msg="data.item"/>
                                                        <Msgforwarded :msg="data.item" />
                                                        <div class="d-flex align-items-center">
                                                            <b-avatar class="bg-secondary text-white me-2"/>
                                                            <span class="d-flex flex-column">
                                                                <div class="fw-bold">{{data.item.vcardName}}</div>
                                                                <div>{{data.item.vcardNumber}}</div>
                                                            </span>
                                                        </div>
                                                        <!-- <div class="d-flex align-items-center justify-content-center border-top text-primary" @click="appendToAttendances(data.item)" role="button">Enviar Mensagem</div> -->
                                                        <Msgdt :item="data.item" />
                                                        <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                            <b-icon icon="info-circle" />
                                                            Mensagem apagada pelo contato!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='vcardArray'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <div class="p-2 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }">
                                                        <Msgfromgroup :msg="data.item"/>
                                                        <Msgforwarded :msg="data.item" />
                                                        <div class="d-flex flex-column gap-1">
                                                            <div class="d-flex align-items-center" v-for="vcard in data.item.contacts" :key="vcard.vcardNumber">
                                                                <b-avatar class="bg-secondary text-white me-2"/>
                                                                <span class="d-flex flex-column">
                                                                    <div class="fw-bold">{{vcard.vcardName}}</div>
                                                                    <div>{{vcard.vcardNumber}}</div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="d-flex align-items-center justify-content-center border-top text-primary" @click="appendToAttendances(data.item)" role="button">Enviar Mensagem</div> -->
                                                        <Msgdt :item="data.item" />
                                                        <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                            <b-icon icon="info-circle" />
                                                            Mensagem apagada pelo contato!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item.type=='document' || data.item.type=='application' || data.item.type=='file'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <div class="p-1 pb-4" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }">
                                                        <Msgfromgroup :msg="data.item"/>
                                                        <Msgforwarded :msg="data.item" />
                                                        <!-- {{data.item}} -->
                                                        <div role="button" @click="downloadMedia(hasFullPath(data.item.mediaURL),data.item.ext)">
                                                            <div class="downloadmediadiv rounded text-decoration-none">
                                                                <div v-if="data.item.filename" class="text-white box-clamp-1">{{data.item.filename}}</div>
                                                                <div v-else class="text-white box-clamp-1">{{data.item.mediaURL.split('/').pop().split('=').at(-1)}}</div>
                                                                <b-icon 
                                                                    :icon="(fileExt(data.item.caption)=='csv' || fileExt(data.item.caption)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                                                    :class="{ 'text-success': (fileExt(data.item.caption)=='csv' || fileExt(data.item.caption)=='xlsx'), 'text-danger': fileExt(data.item.caption)=='pdf'}" 
                                                                    font-scale="4"
                                                                />
                                                                <div>Baixar</div>
                                                                <div>Documento</div>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.item.caption">{{data.item.caption}}</div>
                                                        <div style="position: absolute; left: 5px; bottom: 2px; font-size: 0.7em; color: #555; text-transform: uppercase;">{{fileExt(data.item.caption)}}</div>
                                                        <Msgdt :item="data.item" />
                                                        <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                            <b-icon icon="info-circle" />
                                                            Mensagem apagada pelo contato!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" v-else-if="data.item?.type=='sticker'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <div  class="p-1 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }">
                                                        <Msgfromgroup :msg="data.item"/>
                                                        <Msgforwarded :msg="data.item" />
                                                        <img :src="hasFullPath(data.item.mediaURL)" v-if="data.item.mediaURL" style="width:100%"> 
                                                        <!-- Sticker (Não Suportado) -->
                                                        <Msgdt :item="data.item" />
                                                        <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                            <b-icon icon="info-circle" />
                                                            Mensagem apagada pelo contato!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" v-else-if="data.item?.type=='interactive' || data.item.body?.type=='interactive'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <!-- <Msgfromgroup :msg="data.item"/>
                                                        <Msgforwarded :msg="data.item" />
                                                    <span style="white-space:break-spaces">{{data.item.body}}</span> 
                                                    <span class="fw-bold">Lista Interativa</span>
                                                    <Msgdt :item="data.item" /> -->
                                                    <span v-if="data.item.body?.interactive.type == 'list'">
                                                        <div class="p-2 pb-3 position-relative">
                                                            <Msgfromgroup :msg="data.item"/>
                                                            <Msgforwarded :msg="data.item" />
                                                            <div class="fw-bold mb-1" style="white-space:break-spaces" v-if="data.item.body.interactive?.header?.text">{{ data.item.body.interactive.header.text }}</div> 
                                                            <div style="white-space:break-spaces" v-if="data.item.body.interactive?.body?.text">{{ data.item.body.interactive.body.text }}</div> 
                                                            <div class="smaller-text text-secondary" style="white-space:break-spaces" v-if="data.item.body.interactive?.footer?.text">{{ data.item.body.interactive.footer.text }}</div> 
                                                            <Msgdt :item="data.item" />
                                                            <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                                <b-icon icon="info-circle" />
                                                                Mensagem apagada pelo contato!
                                                            </div>
                                                        </div>
                                                        <div class="border-top d-flex align-items-center justify-content-center text-primary p-1">
                                                            <b-icon icon="list" class="me-1"/>
                                                            {{ data.item.body.interactive.action.button }}
                                                        </div>
                                                    </span>
                                                    <span v-else-if="data.item.body?.interactive?.type == 'button'">
                                                        <div class="p-2 pb-3 position-relative">
                                                            <Msgfromgroup :msg="data.item"/>
                                                            <Msgforwarded :msg="data.item" />
                                                            <div class="fw-bold mb-1" style="white-space:break-spaces" v-if="data.item.body.interactive?.header?.type == 'text' && data.item.body.interactive?.header?.text">{{ data.item.body.interactive.header.text }}</div> 
                                                            <div class="mb-1" v-else-if="data.item.body.interactive?.header?.type == 'image' && data.item.body.interactive?.header?.image?.link">
                                                                <img class="rounded" v-bind:src="hasFullPath(data.item.body.interactive.header.image.link)" style="max-width:100%; max-height: 5em;" @click="selectMedia(hasFullPath(data.item.body.interactive.header.image.link))" v-b-modal.modal-receivedmedia/>
                                                            </div> 
                                                            <div style="white-space:break-spaces" v-if="data.item.body.interactive?.body?.text">{{ data.item.body.interactive.body.text }}</div> 
                                                            <div class="smaller-text text-secondary" style="white-space:break-spaces" v-if="data.item.body.interactive?.footer?.text">{{ data.item.body.interactive.footer.text }}</div> 
                                                            <Msgdt :item="data.item" />
                                                            <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                                <b-icon icon="info-circle" />
                                                                Mensagem apagada pelo contato!
                                                            </div>
                                                        </div>
                                                        <div class="border-top border-2 d-flex align-items-center justify-content-center text-primary p-1" v-for="button in data.item.body.interactive.action.buttons" :key="button._id">
                                                            {{ button.reply.title }}
                                                        </div>
                                                    </span>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" v-else-if="data.item.type=='button_reply'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <span style="white-space:break-spaces">{{ data.item.body.button_reply?.title || data.item.body.title }}</span> 
                                                    <Msgdt :item="data.item" />
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                    <!-- <div class="messagecornerreceived"></div> -->
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" v-else-if="data.item.type=='list_reply'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <span style="white-space:break-spaces">{{ data.item.body.list_reply?.title || data.item.body.title }}</span> 
                                                    <span>{{ data.item.body.list_reply?.description || data.item.body?.description }}</span> 
                                                    <Msgdt :item="data.item" />
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" style="min-width: 12em; max-width: 20em;" v-else-if="data.item.type=='product_list'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <b-row class="m-0 p-1" role="button" v-b-modal.viewProductsList @click="selectProductList(data.item.body)">
                                                        <b-col class="p-0" cols="4">
                                                            <b-img class="w-100 rounded-start" :src="data.item.body.imageURL" v-if="data.item.body.imageURL"/>
                                                            <b-skeleton-img class="rounded-start pe-none" animation="" no-aspect v-else/>
                                                        </b-col>
                                                        <b-col class="d-flex flex-column justify-content-center p-2 bg-secondary bg-opacity-10 rounded-end">
                                                            <div class="fw-semibold">
                                                                {{ data.item.body.header.text }}
                                                            </div>
                                                            <div class="smaller-text text-secondary" v-if="data.item.body.productList">
                                                                {{ data.item.body.productList.length }} {{ 'item' | pluralize(data.item.body.productList.length) }}
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="p-1">
                                                        {{ data.item.body.body.text }}
                                                    </div>
                                                    <div class="border-top border-2 d-flex align-items-center justify-content-center text-primary p-1 fw-semibold">
                                                        <span class="darkenTextOnHover" role="button" v-b-modal.viewProductsList @click="selectProductList(data.item.body)">
                                                            Ver Itens
                                                        </span>
                                                    </div>
                                                    <Msgdt :item="data.item" />
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item?.fromMe,'msgsent': data.item?.fromMe }" style="min-width: 12em; max-width: 20em;" v-else-if="data.item.type=='order'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <b-row class="m-0 p-1" role="button" v-b-modal.viewProductsCart @click="selectProductCart(data.item.body)">
                                                        <b-col class="p-0" cols="4">
                                                            <b-img class="w-100 rounded-start" :src="data.item.body[0].image" v-if="data.item.body[0]?.image"/>
                                                            <b-skeleton-img class="rounded-start pe-none" animation="" no-aspect v-else/>
                                                        </b-col>
                                                        <b-col class="d-flex flex-column justify-content-center p-2 bg-secondary bg-opacity-10 rounded-end">
                                                            <div class="fw-semibold hstack gap-1">
                                                                <b-icon icon="cart-fill"/>
                                                                <div>
                                                                    Itens: {{ data.item.body.length }}
                                                                </div>
                                                            </div>
                                                            <div class="smaller-text text-secondary" v-if="data.item.body?.length">
                                                                Subtotal: R$ {{ String(data.item.body.reduce((acc, product) => acc + product.amount, 0)) | floatingPoint }}
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="border-top border-1 d-flex align-items-center justify-content-center text-primary p-1 fw-semibold">
                                                        <span class="darkenTextOnHover" role="button" v-b-modal.viewProductsCart @click="selectProductCart(data.item.body)">
                                                            Ver carrinho enviado
                                                        </span>
                                                    </div>
                                                    <Msgdt :item="data.item" />
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe, 'msgsent': data.item?.fromMe, 'bg-secondary': data.item.highlight }" v-else-if="data.item.type=='quick_reply'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <MsgReplied :mediaURL="mediaURL" :msg="data.item"  :client="clientSelected ? clientSelected : null" v-if="data.item.quotedMsg" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <img class="rounded" v-bind:src="hasFullPath(data.item.mediaURL)" style="max-width:100%; height: auto;" @click="selectMedia(hasFullPath(data.item.mediaURL))" v-b-modal.modal-receivedmedia v-if="data.item.mediaURL"/>
                                                    <div class="px-2" style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                                    <span style="white-space:break-spaces">{{ parseMsgBody(data.item.body.text) }}</span>
                                                    <Msgdt :item="data.item" />
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                    <!-- <div class="messagecornerreceived"></div> -->
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-1 pb-1 pe-5" v-bind:class="{ 'msgreceived': !data.item?.fromMe, 'msgsent': data.item?.fromMe, 'bg-secondary': data.item.highlight }" v-else-if="data.item.type=='text' || data.item.type=='chat'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <MsgReplied :mediaURL="mediaURL" :msg="data.item"  :client="clientSelected ? clientSelected : null" v-if="data.item.quotedMsg" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <MsgCtwa :ctwaContext="data.item.ctwaContext" />
                                                    <img class="rounded" v-bind:src="hasFullPath(data.item.mediaURL)" style="max-width:100%; height: auto;" @click="selectMedia(hasFullPath(data.item.mediaURL))" v-b-modal.modal-receivedmedia v-if="data.item.mediaURL"/>
                                                    <div class="px-2" style="white-space:break-spaces" v-if="data.item.caption">{{data.item.caption}}</div>
                                                    <span style="white-space:break-spaces">
                                                        {{ parseMsgBody(data.item.body) }}
                                                    </span>
                                                    <Msgdt :item="data.item" />
                                                    <div v-if="data.item.fromMe && ((Date.now() / 1000) - data.item.timestamp >= resentTime) && !data.item.statusAck && ((!clientSelected.from && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id) || (clientSelected.from))">
                                                        <div class="small-text text-red d-flex align-items-end gap-1 w-fit" v-b-tooltip.hover.top="{ customClass: 'top-0', boundary: 'document', variant: 'red' }" title="Reenviar Mensagem" @click="resendMsg(data.item)" role="button" v-if="!data.item.tryingResend">
                                                            <b-icon icon="arrow-clockwise" />
                                                            <span class="smaller-text">
                                                                Talvez a mensagem não tenha sido enviada. Clique aqui para reenviar.
                                                            </span>
                                                        </div>
                                                        <div v-else>
                                                            <b-spinner small variant="secondary" />
                                                        </div>
                                                    </div>
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                    <!-- <div class="messagecornerreceived"></div> -->
                                                </div>
                                                <div :id="data.item.idWpp?.replace('==','').replace('.','') || `d${parseInt(data.item.timestamp)}`" class="p-0" v-bind:class="{ 'msgreceived': !data.item.fromMe,'msgsent': data.item.fromMe }" v-else-if="data.item.type == 'template'">
                                                    <MsgOptions :isFromCurrentOperator="clientSelected.operatorId == operator._id" :mediaURL="mediaURL" :msg="data.item" :client="clientSelected ? clientSelected : null" @setReplyMessageEvent="setReplyMessage" @setEditMessageEvent="setEditMessage" @deleteMessageEvent="deleteMessage" @forwardMessageEvent="setForwardMessage" :fromAttendance="true" />
                                                    <Msgfromgroup :msg="data.item"/>
                                                    <Msgforwarded :msg="data.item" />
                                                    <span class="p-2 pb-3">
                                                        <div class="templateHeader mb-1">
                                                            <span class="fw-bold" v-if="getTemplateHeader(data.item.body?.components)?.format == 'TEXT' || data.item.body?.header?.text">{{ getTemplateHeader(data.item.body.components).text || data.item.body?.header.text }}</span>
                                                            <b-img v-else-if="getTemplateHeader(data.item.body?.components)?.format == 'IMAGE' && getTemplateHeader(data.item.body.components).example" :src="getTemplateHeader(data.item.body.components).example.header_handle[0]"/>
                                                            <span v-else-if="getTemplateHeader(data.item.body?.components)?.format == 'DOCUMENT' && getTemplateHeader(data.item.body.components).example">
                                                                <div role="button" @click="downloadMedia(getTemplateHeader(data.item.body.components).example.header_handle[0])">
                                                                    <div class="downloadmediadiv rounded text-decoration-none">
                                                                        <b-icon icon="file-earmark-fill" font-scale="4"/>
                                                                        <!-- <div>Baixar</div>
                                                                        <div>Documento</div> -->
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <div class="vstack gap-1" v-else-if="data.item.body?.mediaId">
                                                                <div class="text-secondary smaller-text" v-if="data.item.body?.name">
                                                                    Template HSM: {{ data.item.body?.name }}
                                                                </div>
                                                                <div class="p-5 w-100 d-flex align-items-center justify-content-center bg-secondary rounded text-white">
                                                                    <b-icon font-scale="2.5" icon="image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span class="templateBody">{{ getTemplateBody(data.item.body.components || data.item.body.template?.components) || data.item.body.body }}</span>
                                                        <div class="templateFooter small-text text-secondary">{{ getTemplateFooter(data.item.body.components || data.item.body.template?.components) || data.item.body.footer }}</div>
                                                    </span>
                                                    <span class="templateButton" v-if="getTemplateButton(data.item.body.components || data.item.body.template?.components) || data.item.body.buttons?.length">
                                                        <div class="border-top border-2 d-flex align-items-center justify-content-center text-primary p-1" v-for="button in (getTemplateButton(data.item.body.components || data.item.body.template?.components) || data.item.body.buttons)" :key="button._id" @click="openLink(button.value || button.url)">
                                                            {{ button.text ||  button.parameters[0].text}}
                                                        </div>
                                                    </span>
                                                    <div class="smaller-text text-secondary hstack gap-1" v-if="data.item.deleted && !data.item.fromMe">
                                                        <b-icon icon="info-circle" />
                                                        Mensagem apagada pelo contato!
                                                    </div>
                                                    <Msgdt :item="data.item" />
                                                    <!-- <div class="messagecornerreceived"></div> -->
                                                </div>
                                            </span>
                                            <div class="position-absolute bg-grey rounded-pill px-2 mx-3 border border-1 border-secondary" style="bottom: -0em; z-index: 1" v-if="data.item.react?.length" role="button" @click="toggleReactInfo(data.item)">
                                                <span v-for="emoji in data.item.react" :key="emoji._id">
                                                    <span>{{ emoji.reaction }}</span>
                                                </span>
                                                <span class="ms-2" v-if="data.item.react?.length > 1">{{ data.item.react.length }}</span>
                                            </div>
                                            <div class="position-absolute bottom-0 mx-2 bg-secondary rounded shadow-sm" style="z-index: 2" v-if="data.item.showReactInfo">
                                                <div v-for="item in data.item.react" :key="item._id">
                                                    <div class="d-flex align-items-center justify-content-between px-2 py-1">
                                                        <div>
                                                            {{ item.name || item.number || "----" }}
                                                        </div>
                                                        <span class="ms-2">{{ item.reaction }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: 1000" @click="toggleReactInfo(data.item)" v-if="data.item.showReactInfo">
                                            </div>
                                            <div class="rounded p-2 mt-2 text-white text-center w-100" style="background-color: rgba(255, 79, 79, 0.75)" v-if="data.item.type=='closedWindow' && !attendanceWindowIsOpened">
                                                <div class="font-weight-bold">{{ data.item.text }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>
                                <div class="position-absolute top-50 start-50 translate-middle bg-white rounded w-50 overflow-y-auto mh-75 overflow-y-auto shadow-sm" @focusout="hideQuickAnswersDiv()" tabindex="-1" v-if="operator.channelConfig?.modules?.quickAnswers && showQuickAnswers">
                                    <div class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom text-secondary">
                                        <div>
                                            Respostas Rápidas
                                        </div>
                                        <div class="hstack gap-2">
                                            <div v-b-modal.createQuickAnswer v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Criar">
                                                <b-icon class="darkenTextOnHover" font-scale="2" icon="plus" />
                                            </div>
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Fechar">
                                                <b-icon class="darkenTextOnHover" font-scale="1.5" icon="lightning-charge-fill" @click="toggleQuickAnswers" role="button" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center text-secondary p-3" v-if="loadingQuickAnswers">
                                        <b-spinner />
                                    </div>
                                    <div class="text-center text-secondary p-2" v-else-if="!quickAnswers?.length">
                                        Nenhuma resposta rápida encontrada!
                                    </div>
                                    <div v-else>
                                        <div class="quickAnswer d-flex align-items-center justify-content-between border-bottom px-3 py-2 darkenOnHover bg-white mw-100" role="button" v-for="item in quickAnswers" @click="setQuickAnswer(item.content)" :key="item._id">
                                            <div class="mw-50">
                                                <div class="text-secondary small-text">
                                                    {{ item.name }}
                                                </div>
                                                <div class="text-truncate" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" :title="item.content">
                                                    {{ item.content }}
                                                </div>
                                            </div>
                                            <div>
                                                <b-iconstack font-scale="2">
                                                    <b-icon stacked icon="circle-fill" variant="secondary"/>
                                                    <b-icon scale="0.5" stacked icon="person" variant="white" v-if="item.name == 'Perfil Comercial'"/>
                                                    <b-icon scale="0.5" stacked icon="geo-alt-fill" variant="white" v-else-if="item.name == 'Endereço'"/>
                                                    <b-icon scale="0.5" stacked icon="clock" variant="white" v-else-if="item.name == 'Horário'"/>
                                                    <b-icon scale="0.5" stacked icon="chat-left-fill" variant="white" v-else/>
                                                </b-iconstack>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </div>
                <div class="flex-shrink-0" v-if="!insertFile">
                    <div class="bg-msgInput pt-2 px-5" v-if="replyMessage?.body">
                        <div class="d-flex align-items-center justify-content-between bg-silver mx-2 rounded border-start border-5" :class="replyMessage.fromMe ? 'border-success' : 'border-primary'" style="font-size: 0.9em">
                            <div class="ms-2">
                                <div class="fw-bold" :class="replyMessage.fromMe ? 'text-success' : 'text-primary'">
                                    {{ replyMessage.fromMe ? 'Você' : clientSelected.clientName }}
                                </div>
                                <div>
                                    {{ replyMessage.body }}
                                </div>
                            </div>
                            <div>
                                <b-icon icon="x" font-scale="2" @click="replyMessage = {}" role="button" />
                            </div>
                        </div>
                    </div>
                    <div class="bg-msgInput pt-2 px-5" v-if="editMessage?.body">
                        <div class="d-flex align-items-center justify-content-between bg-silver mx-2 rounded border-success border-start border-5" style="font-size: 0.9em">
                            <div class="ms-2">
                                <div class="fw-bold text-success">
                                    Editar mensagem
                                </div>
                                <div>
                                    {{ editMessage.body }}
                                </div>
                            </div>
                            <div>
                                <b-icon icon="x" font-scale="2" @click="editMessage = {}" role="button" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between position-relative px-2 px-sm-3 py-2 py-sm-3 bg-msgInput" v-if="!recordedMedia.url">
                        <VEmojiPicker class="inputFileSubEmojiPicker inputMessageEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                        <b-icon class="me-2 text-secondary darkenTextOnHover" font-scale="2" icon="emoji-laughing" @click="toggleDialogEmoji"/>
                        <form class="w-100" @submit.prevent="sendMSG">
                            <!-- <b-input class="rounded-pill" :placeholder="(clientSelected._id && clientSelected.status != 'finished' && clientSelected.operatorId != operator._id) ? `Este atendimento está aberto com outro operador!` : `Digite uma Mensagem...`" v-model="inputSend"
                            :disabled="clientSelected.status=='finished' || clientSelected.operatorId != operator._id"
                            /> -->
                            <b-input-group class="d-flex align-items-center bg-white rounded-pill text-truncate">
                                <b-form-textarea
                                :class="`msgsTextarea text-wrap border-0 shadow-none text-truncate ${isMsgTextareaDisabled() ? 'px-sm-5 ps-3' : 'mx-sm-4 ms-3'}`"
                                :placeholder="(clientSelected._id && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId != operator._id && !clientSelected.from) ? `Este atendimento está aberto com outro operador!` : !attendanceWindowIsOpened ? '' : ( clientSelected.deletedOnWhatsapp ? `Você não faz mais parte deste grupo. Ele ficará visível até ${this.$options.filters.date(clientSelected.whatsappDeletedAt, 'DD/MM/YY')}` : `Digite uma Mensagem...`)" 
                                v-model="inputSend"
                                :disabled="isMsgTextareaDisabled()"
                                rows="1"
                                max-rows="4"
                                no-resize
                                @keydown.enter.exact.prevent="sendMSG()"
                                @input="verifyInput"
                                />
                                <template #append v-if="attendanceType == 'contacts' ? (clientSelected.status!='finished' && clientSelected.status!='abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id && attendanceWindowIsOpened) : clientSelected.from && !clientSelected.inactive">
                                    <div class="hstack">
                                        <span class="d-inline-flex" @click="createCalendar=!createCalendar; getHSMModels()" v-if="operator.channelConfig?.modules?.schedule && (clientSelected._id && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id)">
                                            <b-img  class="darkenTextOnHover me-1 me-sm-2 filter-secondary" style="width: 1.25rem; height: 1.25rem;" :src="require('../assets/images/scheduleIcons/caderno.png')" role="button" />
                                        </span>
                                        <span @click="toggleQuickAnswers" v-if="operator.channelConfig?.modules?.quickAnswers && (clientSelected._id && clientSelected.status != 'finished' && clientSelected.status != 'abandoned' && clientSelected.status != 'in_survey' && clientSelected.operatorId == operator._id) && quickAnswers?.length">
                                            <b-icon class="me-1 me-sm-3 text-secondary" font-scale="1.2" icon="lightning-charge-fill" role="button" />
                                        </span>
                                    </div>
                                </template>
                            </b-input-group>
                        </form>
                        <span v-if="!recorder">
                            <b-icon class="ms-1 text-secondary darkenTextOnHover" icon="cursor-fill" font-scale="2" rotate="45" @click.prevent="sendMSG()" v-if="inputSend?.length > 0" />
                            <b-icon class="ms-1 text-secondary darkenTextOnHover" icon="mic-fill" font-scale="2" @click="recAudio" v-else-if="operator && !operator.disableAudio" />
                            <!-- <b-icon class="ms-2 text-secondary darkenTextOnHover" :icon="browser=='Firefox'?'mic-fill':'mic-mute-fill'" font-scale="2" @click="recAudio" v-else-if="operator && !operator.disableAudio" /> -->
                        </span>
                        <span class="d-flex" v-else>
                            <!-- <b-icon class="ms-2 text-secondary" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio" /> -->
                            <div class="ms-2 d-flex align-items-center">{{ recordingTime }}</div>
                            <b-icon class="text-danger icon darkenTextOnHover" icon="stop-fill" font-scale="2" @click="confirmAudio" />
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between position-relative" style="background-color: hsl(251deg 49% 93%); padding: 1em" v-else>
                        <b-icon class="me-2 text-danger icon darkenTextOnHover" icon="trash-fill" font-scale="2" @click="stopAudio" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Cancelar" />
                        <video class="rounded audioplayer w-100" style="height:3em" controls>
                            <source :src="recordedMedia.url">
                        </video>
                        <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Enviar" /> 
                    </div>
                </div>
                <!-- <span :style="`height: calc(100dvh - 18.5em)`" v-else> -->
                <span :style="`height: calc(100dvh)`" v-else>
                    <b-overlay class="d-flex flex-column h-100" :show="inputMediaOverlay" rounded="sm">
                        <div class="px-2 text-white d-flex align-items-center w-100 flex-shrink-0" style="background-color: hsl(87deg 55% 65%); font-weight: 600; padding: 0.76em">
                            <b-icon class="icon" icon="x" font-scale="3" role="button" @click="toggleInsertFile()" />
                            <div style="font-size: 1.25em">Pré-Visualizar</div>
                        </div>
                        <div class="insertFileBackground h-100 p-3 px-4 d-flex flex-column" style="background-color: hsl(26deg 24% 87%);">
                            <b-form class="inputFileForm h-100">
                                <div class="position-relative h-100">
                                    <b-form-file
                                    @input="setInputMedia"
                                    class="inputFile h-100" v-bind:class="{'border-0':mediaToSend.length}"
                                    :accept="fileParam"
                                    drop-placeholder="SOLTAR AQUI"
                                    multiple
                                    >
                                        <template slot="placeholder">
                                            <div>
                                                <div class="addMediaWrapper d-flex flex-column align-items-center" v-if="!mediaToSend?.length">
                                                    <div><b-icon scale="3" icon="plus"/></div>
                                                    <div>Adicionar</div>
                                                    <div>Arquivo</div>
                                                </div>
                                            </div>
                                        </template>
                                        <template slot="file-name">
                                            <div class="invisible"></div>
                                        </template>
                                    </b-form-file>
                                    <img class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='image'">
                                    <video class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-else-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='video'"></video>
                                    <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center top-0 start-50 translate-middle-x mh-100 mw-100" v-else-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='audio'">
                                        <b-icon class="mb-3" font-scale="4" icon="file-earmark-music"/>
                                        {{mediaToSend[mediaToSend.length-1].name}}
                                    </div>
                                    <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center" style="height: 100%; width: 50%; left:50%; transform: translateX(-50%); top: 0" v-else-if="mediaToSend?.length && (mediaToSend[mediaToSend.length-1].type=='document' || mediaToSend[mediaToSend.length-1].type=='application')">
                                        <!-- <embed style="width: 100%; height: 100%" :src="mediaToSend[mediaToSend.length - 1].url" v-if="fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'"> -->
                                        <pdf style="height: 90%" class="overflow-y-auto" :src="mediaToSend[mediaToSend.length - 1].url" :page="1" v-if="fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'"></pdf>
                                        <div class="vstack gap-1 align-items-center justify-content-center" v-else>
                                            <b-icon
                                                class="mb-3" 
                                                :icon="(fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                                :class="{ 'text-success': (fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx'), 'text-danger': fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'}" 
                                                font-scale="4"
                                            />
                                            {{mediaToSend[mediaToSend.length-1].name}}
                                        </div>
                                    </div>
                                </div>
                            </b-form>
                            <div class="position-relative" v-if="currentMedia && (currentMedia.type.includes('image') || currentMedia.type.includes('video'))">
                                <b-input class="inputFileSub" placeholder="Legenda" v-model="inputSend" @input="setCaptionMedia"/>
                                <b-icon class="inputFileSubEmoji darkenTextOnHover" icon="emoji-smile" @click="toggleDialogEmoji"/>
                                <VEmojiPicker class="inputFileSubEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                            </div>
                        </div>
                        <div class="position-relative d-flex align-items-center justify-content-center flex-shrink-0" style="background-color: hsl(251deg 49% 93%); height: 80px;">
                            <b-button class="inputFileSendBtn p-3 btn-success rounded-circle text-white" @click="sendInputMedia()"><b-icon icon="cursor-fill" rotate="45" scale="2"/></b-button>
                            <span v-if="mediaToSend?.length > 4">
                                <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-left" style="left:4em" @click="scrollInputMediaLeft"/>
                                <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-right" style="right:4em" @click="scrollInputMediaRight"/>
                            </span>
                            <div class="inputMediaArray d-flex align-items-center justify-content-center" style="overflow-x:hidden; width: 450px">
                                <span class="position-relative" v-for="item in mediaToSend" :key="item.id">
                                    <b-icon font-scale="0.75" class="position-absolute darkenTextOnHover" style="right:1em; top:0.25em; z-index:1" icon="x-circle-fill" variant="danger" @click="removeMediaToSend(item)"/>
                                    <img class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-if="item.type=='image'">
                                    <video class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-else-if="item.type=='video'"></video>
                                    <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='audio'" v-b-tooltip="{customClass: 'mt-5 top-0'}" :title="item.name">
                                        <b-icon class="p-2 bg-white" icon="file-earmark-music" style="height: 100%; width: 100%;"/>
                                    </div>
                                    <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='document' || item.type=='application'" v-b-tooltip="{customClass: 'mt-5 top-0'}" :title="item.name">
                                        <b-icon class="p-2 bg-white" icon="file-earmark" style="height: 100%; width: 100%;"/>
                                        <div 
                                            style="position: absolute; left: 50%; bottom: 50%; font-size: 0.7em; color: #555; text-transform: uppercase; transform: translate(-65%,60%);"
                                        >
                                            {{fileExt(item.name)}}
                                        </div>
                                    </div>
                                </span>
                                <b-form-file
                                @input="setInputMedia"
                                class="inputFile inputFileSmall"
                                :accept="fileParam"
                                drop-placeholder="SOLTAR AQUI"
                                multiple
                                v-if="mediaToSend?.length"
                                >
                                    <template slot="placeholder">
                                        <div>
                                            <div class="d-flex flex-column align-items-center">
                                                <div><b-icon scale="3" icon="plus"/></div>
                                                <div>Adicionar</div>
                                                <div>Arquivo</div>
                                            </div>
                                        </div>
                                    </template>
                                    <template slot="file-name">
                                        <div>
                                            <div class="d-flex flex-column align-items-center">
                                                <div><b-icon scale="3" icon="plus"/></div>
                                                <div>Adicionar</div>
                                                <div>Arquivo</div>
                                            </div>
                                        </div>
                                    </template>
                                </b-form-file>
                            </div>
                        </div>
                    </b-overlay>
                </span>
            </b-col>
            <b-col id="col2" class="m-0 p-0 overflow-hidden border-left d-flex flex-column" :style="`flex-grow: 1.9; height: calc(100dvh - ${headerHeight}px)`" v-else>
                <InternalChat :socket="socket" :operator="operator" :user="user" :timestampDiff="timestampDiff" :mediaURL="mediaURL" :chat="internalChat" :i18n="i18n" :internalNewMessage="internalNewMessage" @selectedMedia="selectMedia" />
            </b-col>
            <b-col id="col3" class="m-0 p-0 position-relative" style="background-color: hsl(248deg 50% 97%);" v-bind:class="{ 'd-none': !clientSelected}" v-if="clientSelected && (clientData || showCrmData)">
                <!-- <b-icon class="position-absolute icon end-0 text-dark" icon="x" @click="clientData = false"/> -->
                <div v-if="clientData">
                    <div class="overflow-hidden d-flex flex-column" style="background-color: hsl(248deg 50% 97%);" :style="`height: ${containerHeight}`">
                        <div class="d-flex p-3 flex-shrink-0 gap-2">
                            <b-icon class="d-block d-sm-none align-self-center" icon="arrow-left" font-scale="1.25" @click="hideClientData"/>
                            <div class="position-relative">
                                <b-avatar class="bg-secondary text-white" size="4em" :src="verifyPhotoURL(clientSelected.photoURL)"/>
                                <span class="position-absolute" :style="`left: 5em; top: ${(operator.channelConfig?.modules?.products || (clientSelected.cardKanbanId && operator.channelConfig?.modules?.kanban)) ? '-.75em' : '0em'};`">
                                    <div class="rounded-pill px-2 py-1 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(clientSelected.tag)?.color || '#000'}; font-size: 0.46em`" role="button" @click="toggleTagName(clientSelected)" v-if="clientSelected.tag">
                                        <b-icon icon="circle-fill"/>
                                        &#8205;
                                        <div class="ms-1 text-uppercase" v-show="clientSelected.showTagName">
                                            {{ getTagInfo(clientSelected.tag)?.name }}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div class="clientsDivInfo d-flex flex-column align-self-center gap-1">
                                <div class="d-flex gap-2">
                                    <div class="d-flex align-items-center" v-if="!editClientName">
                                        <h6 class="text-secondary text-truncate m-0" style="font-size:1.1em">{{ (clientSelected.clientName || verifyPrivacy(clientSelected.clientNumber)) || clientSelected.name }}</h6>
                                        <b-icon class="text-secondary ms-2 darkenTextOnHover" icon="pencil-square" font-scale="1.25" @click="editClientName = true" v-if="operator && !operator.disableEditName && attendanceType == 'contacts'" role="button"/>
                                    </div>
                                    <div class="d-flex align-items-center" v-else>
                                        <input class="border-0 bg-transparent py-1" style="border-bottom: 1px solid #ccc !important" type="text" v-model="clientSelected.clientName">
                                        <b-button class="btn-success rounded ms-2 border-0" @click="saveClientName()">Salvar</b-button>
                                    </div>
                                    <span class="text-secondary darkenTextOnHover" v-b-toggle:clientData v-b-tooltip.hover.bottomright="{ customClass: 'top-0', boundary: 'document' }" title="Dados do Contato">
                                        <span class="when-open">
                                            <b-icon icon="chevron-up"/>
                                        </span>
                                        <span class="when-closed">
                                            <b-icon icon="chevron-down"/>
                                        </span>
                                    </span>
                                </div>
                                <Steps
                                    class="text-white"
                                    variant="pink"
                                    :steps="clientSelected.kanbanSteps?.map((step, index) => ({ _id: step._id, tooltipText: `Etapa ${this.$options.filters.pad(index + 1, 2)} ${step.name}` }))"
                                    :currentStepIndex="clientSelected.kanbanSteps?.findIndex(el => el._id == clientSelected.kanbanStepId) + 1 || 0"
                                    @click="updateCardStep(clientSelected.kanbanCard, $event)"
                                    style="max-width: 10rem"
                                    v-if="clientSelected.cardKanbanId && operator.channelConfig?.modules?.kanban"
                                />
                                <div class="d-flex gap-3" v-if="operator.channelConfig?.modules?.products">
                                    <div class="d-flex gap-2">
                                        <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Lista de Produtos" v-b-modal.productsList>
                                            <b-iconstack class="darkenTextOnHover" font-scale="1.5" role="button"> 
                                                <b-icon class="text-danger" stacked icon="circle-fill"/>
                                                <b-icon stacked class="text-white" icon="heart" scale="0.5"/>
                                            </b-iconstack>
                                        </div>
                                        <div v-if="clientSelected.productsList?.length">
                                            ({{ clientSelected.productsList.length }})
                                        </div>
                                    </div>
                                    <div class="d-flex gap-2">
                                        <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Carrinho de Produtos" v-b-modal.productsCart @click="checkCartAvailability">
                                            <b-iconstack class="darkenTextOnHover" font-scale="1.5" role="button">
                                                <b-icon class="text-purple" stacked icon="circle-fill"/>
                                                <b-icon stacked class="text-white" icon="cart4" scale="0.5"/>
                                            </b-iconstack>
                                        </div>
                                        <div v-if="clientSelected.productsCart?.length">
                                            ({{ clientSelected.productsCart.length }})
                                        </div>
                                    </div>
                                </div>
                                <!-- <div>
                                    <b-form-select class="border-0 border-bottom border-grey bg-transparent text-secondary" v-model="clientSelected.clientCompany">
                                        <b-form-select-option value="Gobot">
                                            Gobot
                                        </b-form-select-option>
                                        <b-form-select-option value="Aida">
                                            Aida
                                        </b-form-select-option>
                                        <b-form-select-option value="add">
                                            Adicionar cliente
                                        </b-form-select-option>
                                    </b-form-select>
                                </div>
                                <div class="mt-2" v-if="clientSelected.clientCompany == 'add'">
                                    <b-form-input placeholder="add"/>
                                    <b-button class="p-0">+</b-button>
                                </div> -->
                                <!-- <div class="clientStatus text-secondary" style="font-size: 0.8em">{{clientSelected.status}}</div> -->
                            </div>
                        </div>
                        <b-collapse visible id="clientData" ref="clientData">
                            <div class="px-3 flex-shrink-0">
                                <b-row class="m-0">
                                    <!-- <b-col class="d-flex flex-column align-items-center" style="flex-grow:0.25">
                                        <b-icon class="text-secondary" icon="clock" font-scale="1.75em"/>
                                        <div class="text-secondary">01h10mn</div>
                                    </b-col> -->
                                    <b-col class="p-0 text-secondary" v-if="attendanceType == 'contacts'">
                                        <div class="d-flex align-items-center pb-2 border-bottom">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Número do Canal">
                                                <b-icon font-scale="1.2" icon="phone"/>
                                            </div>
                                            <div class="ps-2">{{ clientSelected.channelNumber }}</div>
                                        </div>
                                        <div class="d-flex align-items-center py-2 border-bottom">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Gênero">
                                                <b-icon font-scale="1.2" icon="person-badge"/>
                                            </div>
                                            <div class="ps-2 hstack gap-2" v-if="!editClientGenre">
                                                <div>
                                                    {{ parseGender(clientSelected.genre) }}
                                                </div>
                                                <div>
                                                    <b-icon class="darkenTextOnHover" icon="pencil-square" role="button" @click="editClientGenre = true"/>
                                                </div>
                                            </div>
                                            <div class="ps-2 w-100 hstack gap-2" v-else>
                                                <b-form-select class="p-1 rounded border-grey w-100" v-model="clientSelected.genre">
                                                    <option :value="null">Não Identificado</option>
                                                    <option value="M">Masculino</option>
                                                    <option value="F">Feminino</option>
                                                </b-form-select>
                                                <b-button class="btn-success rounded border-0 py-1" @click="saveClientGenre(clientSelected)">Salvar</b-button>
                                            </div>
                                        </div>
                                        <!-- <div class="d-flex align-items-center py-2 border-bottom">
                                            <b-icon font-scale="1.2" icon="person-badge" v-b-tooltip.hover="{ customClass: 'top-0', boundary: 'document' }" title="CPF/CNPJ"/>
                                            <div class="ps-2">{{formatCpfCnpj(clientSelected.document)}}</div>
                                        </div> -->
                                        <div class="d-flex align-items-center py-2 border-bottom">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Estado">
                                                <b-icon font-scale="1.2" icon="geo-alt"/>
                                            </div>
                                            <div class="ps-2">{{ parseState(clientSelected.state) }}</div>
                                        </div>
                                        <div class="d-flex align-items-center py-2 border-bottom">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Email">
                                                <b-icon font-scale="1.2" icon="at"/>
                                            </div>
                                            <div class="d-flex align-items-center" v-if="!editClientEmail">
                                                <div class="ps-2">{{ clientSelected.email || '_ _ _ _' }}</div>
                                                <div class="ms-2">
                                                    <b-icon class="darkenTextOnHover" icon="pencil-square" role="button" @click="editClientEmail = true"/>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center" v-else>
                                                <input class="border-0 bg-transparent" style="border-bottom: 1px solid #ccc !important" type="text" v-model="clientSelected.email">
                                                <b-button class="btn-success rounded ms-2 border-0 py-1" @click="saveClientEmail(clientSelected)">Salvar</b-button>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center py-2 border-bottom">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Código do Cliente">
                                                <b-icon font-scale="1.2" icon="asterisk" />
                                            </div>
                                            <div class="d-flex align-items-center" v-if="!editClientId">
                                                <div class="ps-2">{{ clientSelected.clientId || '_ _ _ _' }}</div>
                                                <div class="ms-2">
                                                    <b-icon class="darkenTextOnHover" icon="pencil-square" role="button" @click="editClientId = true"/>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center" v-else>
                                                <input class="border-0 bg-transparent" style="border-bottom: 1px solid #ccc !important" type="text" v-model="clientSelected.clientId">
                                                <b-button class="btn-success rounded ms-2 border-0 py-1" @click="saveClientId(clientSelected)">Salvar</b-button>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center py-2">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="CPF/CNPJ">
                                                <b-icon font-scale="1.2" icon="person-badge"/>
                                            </div>
                                            <div class="hstack" v-if="!editClientDocument">
                                                <div class="ps-2" v-if="clientSelected.document">{{ verifyPrivacy(clientSelected.document) | formatDocument }}</div>
                                                <div class="ps-2" v-else>
                                                    _ _ _ _
                                                </div>
                                                <div class="ms-2">
                                                    <b-icon class="darkenTextOnHover" icon="pencil-square" role="button" @click="editClientDocument = true"/>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <input class="border-0 bg-transparent" style="border-bottom: 1px solid #ccc !important" type="text" v-maska='["###.###.###-##", "##.###.###/####-##"]' v-model="clientSelected.document">
                                                <b-button class="btn-success rounded ms-2 border-0 py-1" @click="saveClientDocument(clientSelected)">Salvar</b-button>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center py-2">
                                            <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Número do Contato">
                                                <b-icon font-scale="1.2" icon="telephone"/>
                                            </div>
                                            <div class="ps-2">{{ verifyPrivacy(clientSelected.clientNumber) }}</div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-collapse>
                        <div class="overflow-y-auto bg-white h-100 border-top d-flex flex-column">
                            <span v-if="operator.channelConfig?.enableCustomerPortfolio && attendanceType == 'contacts'">
                                <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-vinculated @click="getOperators" role="button">
                                    <b-icon class="me-2" icon="person" font-scale="1.5"/>
                                    Vínculo
                                </div>
                                <b-collapse id="collapse-vinculated">
                                    <div class="px-4 pt-2 pb-3">
                                        <b-form-group label="Selecione o Operador:">
                                            <b-form-select class="w-100 p-2 rounded border-grey" :disabled="clientSelected.responsibleOperatorId ? true : false" v-model="clientSelected.responsibleOperatorIdAux">
                                                <option v-for="item in operators" :key="item.id" :value="item._id">{{ item.name }}</option>
                                                <option :value="null">Nenhum</option>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-button class="mt-3" variant="success" v-if="!clientSelected.responsibleOperatorId" @click="saveClientResponsibleOperator">Vincular</b-button>
                                    </div>
                                </b-collapse>
                            </span>
                            <span v-if="tags?.length">
                                <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-tag @click="getOperators" role="button">
                                    <b-icon class="me-2" icon="tag" font-scale="1.5"/>
                                    Etiqueta
                                </div>
                                <b-collapse id="collapse-tag">
                                    <div class="px-4 pt-2 pb-3">
                                        <b-form-group label="Selecione a Etiqueta:">
                                            <b-form-select class="w-100 p-2 rounded border-grey" v-model="clientSelected.tag" :options="selectTagOptions"/>
                                        </b-form-group>
                                        <b-button class="mt-3" variant="success" @click="saveClientTag()">Salvar</b-button>
                                    </div>
                                </b-collapse>
                            </span>
                            <span v-if="operator.channelConfig?.modules?.crm && ['all', ...operator.department].some(e => operator.channelConfig.rdDepartments?.includes(e))">
                                <!-- <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-crm.clientData role="button" @click="!clientSelected.crmData && getClientCRMData()">
                                    <span class="fw-semibold me-2 border border-secondary rounded" style="font-size: .5em; padding: 0.3em">
                                        CRM
                                    </span>
                                    CRM
                                </div> -->
                                <b-collapse id="collapse-crm">
                                    <div class="py-3 px-4">
                                        <div class="text-center p-3" v-if="loadingClientCRMData">
                                            <b-spinner/>
                                        </div>
                                        <div class="d-flex flex-column gap-3" v-else-if="clientSelected.crmData">
                                            <div class="d-flex flex-column gap-2">
                                                <b-form-group label="Cliente:">
                                                    <!-- <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.organization_id" @change="rdUpdateContact(clientSelected.crmData)">
                                                        <b-form-select-option disabled :value="null || undefined">
                                                            Selecionar Cliente
                                                        </b-form-select-option>
                                                        <b-form-select-option v-for="org in clientSelected.crmOrganizations" :key="org._id" :value="org._id">
                                                            {{ org.name }}
                                                        </b-form-select-option>
                                                    </b-form-select> -->
                                                    <v-select class="searchSelect" :options="clientSelected.crmOrganizations" :filter="(options, search) => { return options.filter(el => el && !el.hidden) }" :loading="loadingCRMOrganizations" :reduce="name => name?._id" label="name" v-model="clientSelected.crmData.organization_id" @input="rdOrgChanged" @search="rdSearchOrganizations">
                                                        <div slot="no-options">Por favor, informe o nome do cliente!</div>
                                                    </v-select>
                                                </b-form-group>
                                                <b-button class="border-grey bg-transparent text-secondary text-start" v-b-modal.rdCreateOrganization>
                                                    Cadastrar Novo Cliente
                                                </b-button>
                                            </div>
                                            <span class="d-flex flex-column gap-3" v-if="clientSelected.crmData?.organization_id">
                                                <div class="d-flex flex-column gap-2">
                                                    <b-form-group label="Negócio:">
                                                        <!-- <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.deal" @change="rdPickedDeal">
                                                            <b-form-select-option disabled :value="null || undefined">
                                                                Selecionar Negócio
                                                            </b-form-select-option>
                                                            <b-form-select-option v-for="deal in clientSelected.crmDeals" :key="deal._id" :value="deal">
                                                                {{ deal.name }}
                                                            </b-form-select-option>
                                                        </b-form-select> -->
                                                        <v-select class="searchSelect" :options="clientSelected.crmDeals"  :loading="loadingCRMDeals" :reduce="name => name" label="name" v-model="clientSelected.crmData.deal" @input="rdPickedDeal" @search="rdSearchDeals">
                                                            <div slot="no-options">Por favor, informe o nome do negócio!</div>
                                                        </v-select>
                                                    </b-form-group>
                                                    <b-button class="border-grey bg-transparent text-secondary text-start" v-b-modal.rdCreateDeal>
                                                        Cadastrar Novo Negócio
                                                    </b-button>
                                                </div>
                                                <span class="d-flex flex-column gap-3" v-if="clientSelected.crmData?.deal">
                                                    <div class="d-flex flex-column gap-2">
                                                        <b-form-group label="Escolha a Etapa do Funil:">
                                                            <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.deal.deal_stage._id" @change="rdUpdateStage(clientSelected.crmData)">
                                                                <b-form-select-option disabled :value="null || undefined">
                                                                    Selecione a Etapa do Funil
                                                                </b-form-select-option>
                                                                <b-form-select-option v-for="stage in clientSelected.crmDealStages" :key="stage._id" :value="stage._id">
                                                                    {{ stage.name }}
                                                                </b-form-select-option>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="d-flex flex-column gap-2">
                                                        <!-- <b-form-group label="Produto:">
                                                            <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.deal.product">
                                                                <b-form-select-option disabled :value="null || undefined">
                                                                    Selecionar produto
                                                                </b-form-select-option>
                                                                <b-form-select-option v-for="product in clientSelected.crmData.deal.deal_products" :key="product._id" :value="product">
                                                                    {{ product.name }}
                                                                </b-form-select-option>
                                                            </b-form-select>
                                                        </b-form-group>
                                                        <span class="d-flex flex-column gap-3" v-if="clientSelected.crmData.deal.product">
                                                            <b-form-group label="Quantidade:">
                                                                <b-form-input class="border-grey" type="number" v-model="clientSelected.crmData.deal.product.amount"/>
                                                            </b-form-group>
                                                            <b-form-group label="Preço unitário:">
                                                                <b-form-input class="border-grey" v-model="clientSelected.crmData.deal.product.price"/>
                                                            </b-form-group>
                                                        </span> -->
                                                        <b-button class="border-grey bg-transparent text-secondary text-start px-0" v-b-toggle.accordion-crmProducts>
                                                        <!-- <b-button class="bg-silver text-white border text-start px-0" v-b-toggle.accordion-crmProducts> -->
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <div class="ps-3">
                                                                    Produtos
                                                                </div>
                                                                <!-- <b-icon class="stroke-white" icon="chevron-down" scale="0.55" shift-h="0.5"/> -->
                                                                <b-icon class="stroke-black" icon="chevron-down" scale="0.5" shift-h="0.5"/>
                                                            </div>
                                                        </b-button>
                                                        <b-collapse id="accordion-crmProducts" role="tabpanel">
                                                            <div class="d-flex flex-column bg-silver-fade rounded">
                                                                <span v-if="clientSelected.crmData?.deal?.deal_products?.length">
                                                                    <div class="crmProductDiv" v-for="product in clientSelected.crmData.deal.deal_products" :key="product._id">
                                                                        <span class="d-flex flex-column gap-3 border-bottom-grey p-2">
                                                                            <!-- <b-form-group label="Produto:"> -->
                                                                            <b-form-group>
                                                                                <b-form-input class="border-grey" :value="product.name" disabled/>
                                                                            </b-form-group>
                                                                            <b-form-group label="Quantidade:">
                                                                                <b-form-input class="border-grey" type="number" v-model="product.amount" @input="rdUpdateProduct($event, product, clientSelected.crmData.deal._id)"/>
                                                                            </b-form-group>
                                                                            <b-form-group label="Preço unitário:">
                                                                                <b-form-input class="border-grey" v-model="product.price" @input="rdUpdateProduct($event, product, clientSelected.crmData.deal._id)"/>
                                                                            </b-form-group>
                                                                        </span>
                                                                    </div>
                                                                    <div class="p-2">
                                                                        Valor Total: {{ clientSelected.crmData.deal.deal_products.reduce((total, item) => item.price && total + (parseFloat(item.price.toString().replace(',','.')) * (item.amount || 1)), 0).toString().replace('.',',') }}
                                                                    </div>
                                                                </span>
                                                                <span class="text-center w-100 p-2" v-else>
                                                                    Nenhum produto associado!
                                                                </span>
                                                            </div>
                                                        </b-collapse>
                                                        <b-button class="border-grey bg-transparent text-secondary text-start" v-b-modal.rdAssociateProduct @click="rdGetProducts">
                                                            Associar Novo Produto
                                                        </b-button>
                                                    </div>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </b-collapse>
                            </span>
                            <span class="d-flex flex-column overflow-hidden" v-if="operator.channelConfig?.modules?.products">
                                <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-products  @click="getOperators" role="button">
                                    <b-icon class="me-2" icon="shop" font-scale="1.5"/>
                                    Catálogo de Produtos
                                </div>
                                <b-collapse id="collapse-products" class="flex-column overflow-hidden" style="font-size: 0.85em;">
                                    <div class="vstack gap-2 bg-light-purple p-4 flex-shrink-0">
                                        <b-form-group>
                                            <b-form-radio-group v-model="catalogFilters.type">
                                                <b-form-radio class="d-inline-flex me-3" value="keyword">
                                                    <div class="ms-2">Palavra Chave</div>
                                                </b-form-radio>
                                                <b-form-radio class="d-inline-flex" value="category">
                                                    <div class="ms-2">Categoria</div>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                        <!-- <b-form-group class="w-100" label="Filtrar por:">
                                            <b-form-select class="text-secondary w-100 rounded p-2 border" v-model="catalogFilters.type">
                                                <b-form-select-option value="keyword">Palavra Chave</b-form-select-option>
                                                <b-form-select-option value="category">Categoria</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group> -->
                                        <form @submit.prevent="getCatalogCategoriesSearch(catalogSearch)" v-if="catalogFilters.type == 'keyword'">
                                            <b-input-group class="d-flex align-items-center bg-white border rounded text-secondary">
                                                <template #append>
                                                    <b-icon class="bg-white me-2 ms-1" icon="search" role="button" @click="getCatalogCategoriesSearch(catalogSearch)"/>
                                                </template>
                                                <b-form-input type="search" class="border-0 shadow-none rounded pe-1" 
                                                    placeholder="Busca por Palavra Chave" 
                                                    style="font-size: 0.95em; height:2.65em;"
                                                    v-model="catalogSearch"
                                                />
                                            </b-input-group>
                                        </form>
                                        <div class="vstack gap-2" v-if="catalogFilters.type == 'category'">
                                            <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="catalogFilters.selectedCategoryId" @change="catalogFilters.selectedSubcategoryId = null">
                                                <b-form-select-option :value="null">
                                                    Categoria
                                                </b-form-select-option>
                                                <b-form-select-option value="all">
                                                    Todas
                                                </b-form-select-option>
                                                <b-form-select-option :value="category._id" v-for="category in catalogCategoriesAll.filter(el => !el.topCategoryId)" :key="category._id">
                                                    {{ category.name }}
                                                </b-form-select-option>
                                            </b-form-select>
                                            <b-form-select class="text-secondary w-100 rounded p-2 border-grey" :disabled="!catalogFilters.selectedCategoryId" v-model="catalogFilters.selectedSubcategoryId" v-if="!catalogCategoriesAll.filter(el => el.topCategoryId == catalogFilters.selectedCategoryId).length">
                                                <b-form-select-option :value="0">
                                                    Nenhuma subcategoria encontrada!
                                                </b-form-select-option>
                                            </b-form-select>
                                            <b-form-select class="text-secondary w-100 rounded p-2 border-grey" :disabled="!catalogFilters.selectedCategoryId" v-model="catalogFilters.selectedSubcategoryId" v-else>
                                                <b-form-select-option :value="null">
                                                    Subcategoria
                                                </b-form-select-option>
                                                <!-- <b-form-select-option :value="null">
                                                    Todas
                                                </b-form-select-option> -->
                                                <b-form-select-option :value="subcategory._id" v-for="subcategory in catalogCategoriesAll.filter(el => el.topCategoryId == catalogFilters.selectedCategoryId)" :key="subcategory._id">
                                                    {{ subcategory.name }}
                                                </b-form-select-option>
                                            </b-form-select>
                                            <b-button variant="green" class="text-white mt-1" @click="getProducts(1)">
                                                Buscar
                                            </b-button>
                                        </div>
                                    </div>
                                    <div class="vstack overflow-y-auto h-100" v-if="catalogFilters.type == 'keyword' && hasSearchedCatalogSearch">
                                        <div class="text-center p-3" v-if="loadingCategories">
                                            <b-spinner/>
                                        </div>
                                        <span v-else-if="catalogCategories?.length">
                                            <div v-for="category in catalogCategories" :key="category._id">
                                                <operator-catalog 
                                                    :subcategories="category.subcategories"
                                                    :name="category.name"
                                                    :_id="category._id"
                                                    :depth="0"
                                                    :products="category.products"
                                                />
                                            </div>
                                        </span>
                                        <div class="text-center text-secondary py-2" v-else>
                                            Nenhum produto encontrado!
                                        </div>
                                    </div>
                                    <div ref="catalogProductsRef" class="vstack overflow-y-auto h-100" v-if="catalogFilters.type == 'category' && hasSearchedCatalogCategory" @scroll="handleScrollCatalogProducts">
                                        <span v-if="catalogProducts?.length">
                                            <operator-products
                                            :products="catalogProducts"
                                            />
                                        </span>
                                        <div class="text-center text-secondary py-2" v-else-if="!loadingProducts">
                                            Nenhum produto encontrado!
                                        </div>
                                        <div class="text-center p-3" v-if="loadingProducts">
                                            <b-spinner/>
                                        </div>
                                    </div>
                                </b-collapse>
                            </span>
                            <span class="d-flex flex-column overflow-hidden" v-if="operator.channelConfig?.modules?.products && (operator.channelId === '6650c5456a5a4dca8eb3e535' || operator.channelId === '62d9432176c1d572f1f67079')">
                                <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-contact-order-data role="button">
                                    <b-icon class="me-2" icon="cart3" font-scale="1.5"/>
                                    Dados de Compra
                                </div>
                                <b-collapse id="collapse-contact-order-data" class="flex-column overflow-auto px-4 py-3 gap-2" style="font-size: 0.85em;">
                                    <div class="vstack gap-2 overflow-y-auto">
                                        <b-form-group label-class="text-purple" label="Tipo de Endereço:">
                                            <b-form-select class="p-2 rounded border-grey w-100" v-model="clientSelected.addressType" @change="updateContact(clientSelected.contactId, { addressType: $event })">
                                                <option value="Residential">Residencial</option>
                                                <option value="Commercial">Comercial</option>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-form-group label-class="text-purple" label="CEP:">
                                            <b-input class="p-1.5 rounded border-grey w-100" v-model="clientSelected.cep" @blur="updateContact(clientSelected.contactId, { cep: clientSelected.cep }); setContactAddressData(clientSelected.cep)" />
                                        </b-form-group>
                                        <b-form-group label-class="text-purple" label="Rua:">
                                            <b-input class="p-1.5 rounded border-grey w-100" v-model="clientSelected.address" @blur="updateContact(clientSelected.contactId, { address: clientSelected.address })" />
                                        </b-form-group>
                                        <b-form-group label-class="text-purple" label="Bairro:">
                                            <b-input class="p-1.5 rounded border-grey w-100" v-model="clientSelected.neighborhood" @blur="updateContact(clientSelected.contactId, { neighborhood: clientSelected.neighborhood })" />
                                        </b-form-group>
                                        <b-form-group class="position-relative" label-class="text-purple" label="Documento Complementar:" v-if="clientSelected.supplementaryDocument">
                                            <b-icon icon="plus-circle-fill" class="position-absolute top-0 end-0 text-purple darkenTextOnHover" role="button" @click="clientSelected.supplementaryDocument.push({ typeDocument: '', document: '' })" />
                                            <div class="vstack gap-2">
                                                <div class="vstack gap-2 border rounded p-2" v-for="(doc, index) in clientSelected.supplementaryDocument" :key="index">
                                                    <b-form-group class="position-relative" label-class="text-purple" label="Tipo do Documento:">
                                                        <b-icon icon="trash-fill" class="position-absolute top-0 end-0 text-danger darkenTextOnHover" role="button" @click="deleteSupplementaryDocument(index)" />
                                                        <b-form-select class="p-2 rounded border-grey w-100" v-model="doc.typeDocument" @change="updateContact(clientSelected.contactId, { supplementaryDocument: clientSelected.supplementaryDocument })">
                                                            <option value="cnpj">CNPJ</option>
                                                            <option value="cpf">CPF</option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                    <b-form-group label-class="text-purple" label="Documento:">
                                                        <b-input class="p-1.5 rounded border-grey w-100" v-model="doc.document" @blur="updateContact(clientSelected.contactId, { supplementaryDocument: clientSelected.supplementaryDocument })" />
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <!-- <b-input class="p-1.5 rounded border-grey w-100" v-model="clientSelected.supplementaryDocument" @blur="updateContact(clientSelected.contactId, { supplementaryDocument: $event })" /> -->
                                        </b-form-group>
                                        <b-form-group label-class="text-purple" label="País:">
                                            <v-select class="clientCountrySelect" v-model="clientSelected.country" :reduce="e => e?.code" :options="countryOptions" @input="updateContact(clientSelected.contactId, { country: $event })">
                                                <template slot="option" slot-scope="option">
                                                    <!-- <span :class="`flag-icon flag-icon-${option.value.toLowerCase()} flag-icon-squared me-2`"></span> -->
                                                    <span>{{ option.label }}</span>
                                                </template>
                                                <template #selected-option="value">
                                                    {{ setSelectedOption(countryOptions, value) }}
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                        <b-form-group label-class="text-purple" label="Complemento:">
                                            <b-input class="p-1.5 rounded border-grey w-100" v-model="clientSelected.complement" @blur="updateContact(clientSelected.contactId, { complement: clientSelected.complement })" />
                                        </b-form-group>
                                    </div>
                                </b-collapse>
                            </span>
                            <span class="d-flex flex-column overflow-hidden" v-if="operator.channelConfig?.modules?.products && (operator.channelId === '6650c5456a5a4dca8eb3e535' || operator.channelId === '62d9432176c1d572f1f67079') && clientSelected.orderId">
                                    <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-contact-order-freight role="button">
                                        <b-icon class="me-2" icon="box-seam" font-scale="1.5"/>
                                        Calcular Frete
                                    </div>
                                    <b-collapse id="collapse-contact-order-freight" class="flex-column overflow-auto px-4 py-3 gap-2" style="font-size: 0.85em;">
                                        <b-form class="vstack gap-3" @submit.prevent="calculateFreight($event.target[1].value)">
                                            <b-form-group label-class="text-purple" label="CEP:">
                                                <b-input class="p-1.5 rounded border-grey w-100" :value="clientSelected.cep" />
                                            </b-form-group>
                                            <b-button class="w-100" type="submit" variant="primary" :disabled="loadingFreight">
                                                <b-spinner small v-if="loadingFreight" />
                                                Calcular Frete
                                            </b-button>
                                            <div v-if="loadedFreight && !loadingFreight">
                                                <div v-if="clientSelected.freightOptions">
                                                    <b-form-group label-class="text-purple" label="Escolha uma opção:">
                                                        <b-form-radio-group class="vstack gap-3" v-model="clientSelected.selectedFreight" @change="setDiamantesFreight">
                                                            <div class="border rounded p-2" v-for="(freight, index) in clientSelected.freightOptions" :key="freight.cod_tabela">
                                                                <div class="d-flex gap-2">
                                                                    <b-form-radio :value="freight" />
                                                                    <div class="vstack gap-2">
                                                                        <div class="vstack">
                                                                            <div class="text-purple fw-bold fs-6" v-if="index === 0">
                                                                                Frete mais barato!
                                                                            </div>
                                                                            <div class="text-purple fw-semibold">
                                                                                Transportadora:
                                                                            </div>
                                                                            <div>
                                                                                {{ freight.nome_transportador }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="vstack">
                                                                            <div class="text-purple fw-semibold">
                                                                                Descrição:
                                                                            </div>
                                                                            <div>
                                                                                {{ freight.descricao }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="vstack">
                                                                            <div class="text-purple fw-semibold">
                                                                                Prazo:
                                                                            </div>
                                                                            <div>
                                                                                {{ freight.prazo_exibicao || freight.prazo }} dias
                                                                            </div>
                                                                        </div>
                                                                        <div class="vstack">
                                                                            <div class="text-purple fw-semibold">
                                                                                Valor:
                                                                            </div>
                                                                            <div>
                                                                                Frete: R$ {{ (freight.valor_frete_exibicao || freight.valor_frete)?.toString().replace('.', ',') | floatingPoint }}
                                                                            </div>
                                                                            <div>
                                                                                ICMS: R$ {{ freight.valor_icms?.toString().replace('.', ',') | floatingPoint  }}
                                                                            </div>
                                                                            <div>
                                                                                <span class="fw-semibold">Total</span>: R$ {{ ((freight.valor_frete_exibicao || freight.valor_frete) + freight.valor_icms)?.toString().replace('.', ',') | floatingPoint  }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-form-radio-group>
                                                    </b-form-group>
                                                </div>
                                                <div class="text-secondary text-center" v-else>
                                                    Nenhum frete encontrado
                                                </div>
                                            </div>
                                        </b-form>
                                    </b-collapse>
                            </span>
                            <span class="d-flex flex-column overflow-hidden" v-if="operator.channelConfig?.modules?.products && (operator.channelId === '6650c5456a5a4dca8eb3e535' || operator.channelId === '62d9432176c1d572f1f67079') && clientSelected.orderId">
                                <div class="d-flex align-items-center border-bottom text-purple darkenOnHover py-2 px-4" v-b-toggle.collapse-contact-order-payment role="button">
                                    <b-icon class="me-2" icon="currency-dollar" font-scale="1.5"/>
                                    Forma de Pagamento
                                </div>
                                <b-collapse id="collapse-contact-order-payment" class="overflow-auto px-4 py-3" style="font-size: 0.85em;">
                                    <div class="d-flex flex-column gap-3">
                                        <b-form-group label-class="text-purple" label="Forma de Pagamento:">
                                            <b-form-radio-group class="hstack gap-3" v-model="clientSelected.typePayment">
                                                <b-form-radio class="d-inline-flex gap-1" value="paymentCard">
                                                    Cartão de Crédito
                                                </b-form-radio>
                                                <b-form-radio class="d-inline-flex gap-1" value="pix">
                                                    PIX
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                        <b-form-group label-class="text-purple" label="Parcelas:" v-if="clientSelected.typePayment === 'paymentCard'">
                                            <b-form-select class="p-2 rounded border-grey w-100" v-model="clientSelected.numberInstallments" @change="updateContact(clientSelected.contactId, { supplementaryDocument: clientSelected.supplementaryDocument })">
                                                <option value="1">Á vista</option>
                                                <option value="2" v-if="!clientSelected.productsCart?.length || checkInstallment(2)">2 parcelas</option>
                                                <option value="3" v-if="!clientSelected.productsCart?.length || checkInstallment(3)">3 parcelas</option>
                                                <option value="4" v-if="!clientSelected.productsCart?.length || checkInstallment(4)">4 parcelas</option>
                                                <option value="5" v-if="!clientSelected.productsCart?.length || checkInstallment(5)">5 parcelas</option>
                                                <option value="6" v-if="!clientSelected.productsCart?.length || checkInstallment(6)">6 parcelas</option>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-button variant="success" @click="setDiamantesPayment()">Finalizar Pedido</b-button>
                                    </div>
                                </b-collapse>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="crmData" class="position-relative overflow-hidden d-flex flex-column" :style="`height: ${containerHeight}`" v-else>
                    <div class="position-absolute end-0 mt-2" role="button" @click="toggleCrmData">
                        <b-icon class="text-light bg-purple opacity-50 darkenOnHover shadow" icon="x" font-scale="2.5" scale="1.25"/>
                    </div>
                    <div class="hstack gap-3 p-4" v-if="loadingClientCRMData">
                        <b-skeleton-icon
                            class="bg-secondary rounded-circle p-2"
                            icon="person-fill"
                            :icon-props="{ fontScale: 3, variant: 'light' }"
                        />
                        <div class="vstack">
                            <b-skeleton animation="wave" width="85%"/>
                            <b-skeleton animation="wave" width="55%"/>
                            <b-skeleton animation="wave" width="70%"/>
                        </div>
                    </div>
                    <div class="vstack h-100 overflow-auto" v-else>
                        <div class="hstack gap-3 p-4">
                            <b-avatar class="bg-secondary text-white" size="5em" :src="verifyPhotoURL(clientSelected.photoURL)"/>
                            <div class="vstack gap-1 justify-content-center">
                                <h5 class="m-0">
                                    {{ clientSelected.clientName }}
                                </h5>
                                <div class="smaller-text text-secondary">
                                    {{ verifyPrivacy(formatNumber(clientSelected.clientNumber)) }}
                                </div>
                                <Steps
                                    class="text-white"
                                    variant="pink"
                                    :steps="clientSelected.crmDealStages?.map(item => ({ _id: item._id, tooltipText: `Etapa ${this.$options.filters.pad(item.order, 2)} ${item.name}` }))"
                                    :currentStepIndex="clientSelected.crmDealStages?.find(el => el._id == clientSelected.crmData?.deal?.deal_stage?._id)?.order || 0"
                                    @click="rdUpdateStage2(clientSelected.crmData, $event)"
                                />
                                <b-form-rating class="bg-transparent px-0" variant="warning" no-border inline v-model="clientSelected.crmData.deal.rating" @change="rdUpdateDeal()" v-if="clientSelected.crmData?.deal"/>
                            </div>
                        </div>
                        <div class="bg-white h-100">
                            <div>
                                <div class="border-bottom py-3 px-4 hstack justify-content-between" v-b-toggle.collapse-crmOrganization>
                                    <div class="text-secondary">
                                        Cliente: {{ clientSelected.crmData?.organization?.name || '_ _ _ _' }}
                                    </div>
                                    <div v-b-tooltip.left.hover="{ customClass: 'top-0 me-2 mb-2', boundary: 'document', variant: 'green' }" title="Editar">
                                        <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                            <b-icon stacked icon="circle-fill" variant="green"/>
                                            <b-icon stacked class="text-white" icon="pencil-fill" scale=".5"/>
                                        </b-iconstack>
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmOrganization" ref="collapse-crmOrganization" class="border-bottom py-3 px-4 vstack gap-3" v-if="clientSelected.crmData">
                                    <b-form-group label-class="lh-1" label="Buscar Cliente:">
                                        <!-- <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.organization_id" @change="rdUpdateContact(clientSelected.crmData)">
                                            <b-form-select-option disabled :value="null || undefined">
                                                Selecionar Cliente
                                            </b-form-select-option>
                                            <b-form-select-option v-for="org in clientSelected.crmOrganizations" :key="org._id" :value="org._id">
                                                {{ org.name }}
                                            </b-form-select-option>
                                        </b-form-select> -->
                                        <v-select class="searchSelect" :options="clientSelected.crmOrganizations" :filter="(options, search) => { return options.filter(el => el && !el.hidden) }" :loading="loadingCRMOrganizations" :reduce="name => name?._id" label="name" v-model="clientSelected.crmData.organization_id" @input="rdOrgChanged" @search="rdSearchOrganizations">
                                            <div slot="no-options">Por favor, informe o nome do cliente!</div>
                                        </v-select>
                                    </b-form-group>
                                    <b-form @submit.prevent="rdCreateOrganization(clientSelected.crmData)">
                                        <div class="d-flex flex-column gap-3 border border-grey rounded p-3">
                                            <b-form-group label="Cadastrar Cliente:">
                                                <b-form-input class="border-grey" placeholder="Nome do Cliente" v-model="newRDOrganization.name" required/>
                                            </b-form-group>
                                            <b-form-group label="Segmento de atuação:">
                                                <b-form-tags
                                                    class="inputKeywords border-grey"
                                                    add-button-text="Adicionar"
                                                    add-button-variant="primary"
                                                    duplicate-tag-text="Segmento duplicado"
                                                    tag-remove-label="Remover Segmento"
                                                    tag-removed-label="Segmento removido"
                                                    invalid-tag-text="Segmento Inválido"
                                                    :tag-validator="orgSegmentValidator"
                                                    placeholder="Adicionar Segmento"
                                                    v-model="newRDOrganization.organization_segments"
                                                />
                                            </b-form-group>
                                            <div class="hstack gap-2">
                                                <b-button class="fw-semibold px-3 bg-green3 border-green3 py-1" type="submit" :disabled="rdCreatingOrganization || !newRDOrganization.name || !newRDOrganization.organization_segments?.length" v-b-toggle.collapse-crmOrganization>
                                                    <b-spinner class="me-2" small v-if="rdCreatingOrganization"/>
                                                    Cadastrar
                                                </b-button>
                                                <b-button class="bg-white border-grey text-secondary fw-semibold py-1" v-b-toggle.collapse-crmOrganization>Cancelar</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </b-collapse>
                            </div>
                            <div>
                                <div class="bg-white border-bottom py-3 px-4 hstack justify-content-between" v-b-toggle.collapse-crmDeal>
                                    <div class="text-secondary">
                                        Negócio: {{ clientSelected.crmData?.deal?.name || '_ _ _ _' }}
                                    </div>
                                    <div v-b-tooltip.left.hover="{ customClass: 'top-0 me-2 mb-2', boundary: 'document', variant: 'green' }" title="Editar">
                                        <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                            <b-icon stacked icon="circle-fill" variant="green"/>
                                            <b-icon stacked class="text-white" icon="pencil-fill" scale=".5"/>
                                        </b-iconstack>
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmDeal" class="bg-white border-bottom py-3 px-4 vstack gap-3" v-if="clientSelected.crmData">
                                    <b-form @submit.prevent="rdCreateDeal(clientSelected.crmData)">
                                        <div class="d-flex flex-column gap-3 border border-grey rounded p-3">
                                            <b-form-group label="Cadastrar Negócio:">
                                                <b-form-input placeholder="Nome do negócio" v-model="newRDDeal.deal.name" required/>
                                            </b-form-group>
                                            <div class="hstack gap-2">
                                                <b-button class="fw-semibold px-3 bg-green3 border-green3 py-1" type="submit" :disabled="rdCreatingDeal">
                                                    <b-spinner class="me-2" small v-if="rdCreatingDeal"/>
                                                    Cadastrar
                                                </b-button>
                                                <!-- <b-button class="bg-white border-grey text-secondary fw-semibold py-1" v-b-toggle.collapse-crmDeal>Cancelar</b-button> -->
                                            </div>
                                        </div>
                                    </b-form>
                                    <div v-if="clientSelected.crmData?.selectedDeal">
                                        <b-form-group label-class="lh-1" label="Buscar Negócio:">
                                            <!-- <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.deal" @change="rdPickedDeal">
                                                <b-form-select-option disabled :value="null || undefined">
                                                    Selecionar Negócio
                                                </b-form-select-option>
                                                <b-form-select-option v-for="deal in clientSelected.crmDeals" :key="deal._id" :value="deal">
                                                    {{ deal.name }}
                                                </b-form-select-option>
                                            </b-form-select> -->
                                            <v-select class="searchSelect" :options="clientSelected.crmDeals"  :loading="loadingCRMDeals" :reduce="name => name" label="name" v-model="clientSelected.crmData.selectedDeal" @search="rdSearchDeals">
                                                <div slot="no-options">Por favor, informe o nome do negócio!</div>
                                            </v-select>
                                        </b-form-group>
                                    </div>
                                    <div v-if="clientSelected.crmData?.selectedDeal">
                                        <b-form-group label-class="lh-1" label="Buscar Funil:">
                                            <v-select class="searchSelect" :options="clientSelected.crmDealPipelines" :loading="loadingCRMDealPipelines" :reduce="name => name.id" label="name" v-model="clientSelected.crmData.selectedDeal.deal_stage.deal_pipeline_id" @search="rdGetDealPipelines" @input="rdPickedDealPipeline">
                                                <div slot="no-options">Nenhum funil encontrado!</div>
                                            </v-select>
                                        </b-form-group>
                                    </div>
                                    <div v-if="clientSelected.crmData?.selectedDeal">
                                        <b-form-group label-class="lh-1" label="Buscar Campanha:">
                                            <v-select class="searchSelect" :options="rdCampaigns"  :loading="rdLoadingCampaigns" :reduce="name => name.id" label="name" v-model="clientSelected.crmData.selectedDeal.campaign_id" @search="rdGetCampaigns">
                                                <div slot="no-options">Nenhuma campanha encontrada!</div>
                                            </v-select>
                                        </b-form-group>
                                    </div>
                                    <div v-if="clientSelected.crmData?.selectedDeal">
                                        <b-form-group label-class="lh-1" label="Buscar Fonte:">
                                            <v-select class="searchSelect" :options="rdDealSources"  :loading="rdLoadingDealSources" :reduce="name => name.id" label="name" v-model="clientSelected.crmData.selectedDeal.deal_source_id" @search="rdGetDealSources">
                                                <div slot="no-options">Nenhuma fonte encontrada!</div>
                                            </v-select>
                                        </b-form-group>
                                    </div>
                                    <div class="hstack gap-2">
                                        <b-button class="fw-semibold px-4 bg-green3 border-green3 py-1" @click="rdSaveDeal" v-b-toggle.collapse-crmDeal>
                                            <b-spinner class="me-2" small v-if="rdSavingDealPipeline"/>
                                            Salvar
                                        </b-button>
                                        <b-button class="bg-white border-grey text-secondary fw-semibold py-1" @click="clientSelected.crmData.deal.deal_stage.selectedDeal_pipeline_id = null" v-b-toggle.collapse-crmDeal>Cancelar</b-button>
                                    </div>
                                </b-collapse>
                            </div>
                            <div v-if="clientSelected.crmData.deal">
                                <div class="bg-white border-bottom py-3 px-4 hstack justify-content-between" v-b-toggle.collapse-crmResponsible>
                                    <div class="text-secondary">
                                        Responsável: {{ clientSelected.crmData.deal.user?.name || '_ _ _ _' }}
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmResponsible" class="border-bottom py-3 px-4 bg-white">
                                    <div class="vstack gap-3">
                                        <div class="border rounded p-2" v-if="!rdUsers.length">
                                            {{ clientSelected.crmData.deal.user?.name }}
                                        </div>
                                        <div class="vstack gap-3" v-else>
                                            <v-select class="searchSelect" :options="rdUsers" :reduce="name => name?._id" label="name" v-model="clientSelected.crmData.deal.user_id" @input="refreshClientSelected">
                                                <div slot="no-options">Por favor, informe o nome do usuário!</div>
                                            </v-select>
                                            <!-- <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.deal.user_id">
                                                <b-form-select-option disabled :value="null || undefined">
                                                    Selecionar responsável
                                                </b-form-select-option>
                                                <b-form-select-option v-for="user in rdUsers" :key="user._id" :value="user._id">
                                                    {{ user.name }}
                                                </b-form-select-option>
                                            </b-form-select> -->
                                            <div class="hstack gap-2">
                                                <b-button class="fw-semibold px-4 bg-green3 border-green3 py-1" @click="rdSaveUser" v-b-toggle.collapse-crmResponsible>
                                                    <b-spinner class="me-2" small v-if="rdSavingUser"/>
                                                    Salvar
                                                </b-button>
                                                <b-button class="bg-white border-grey text-secondary fw-semibold py-1" v-b-toggle.collapse-crmResponsible>Cancelar</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <div v-if="clientSelected.crmData.deal">
                                <div class="bg-white border-bottom py-3 px-4 hstack justify-content-between" v-b-toggle.collapse-crmProducts>
                                    <div class="text-secondary">
                                        Produtos
                                    </div>
                                    <div class="invisible">
                                        <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                            <b-icon stacked icon="circle-fill" variant="green"/>
                                            <b-icon stacked class="text-white" icon="pencil-fill" scale=".5"/>
                                        </b-iconstack>
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmProducts" class="bg-white border-bottom py-3 px-4 vstack gap-3">
                                    <b-button class="border-grey bg-transparent text-secondary text-start" v-b-modal.rdAssociateProduct @click="rdGetProducts">
                                        Associar Novo Produto
                                    </b-button>
                                    <div class="d-flex flex-column border rounded">
                                        <span v-if="clientSelected.crmData.deal?.deal_products?.length">
                                            <div class="crmProductDiv" v-for="product in clientSelected.crmData.deal.deal_products" :key="product._id">
                                                <span class="d-flex flex-column gap-3 p-3">
                                                    <!-- <b-form-group label="Produto:"> -->
                                                    <b-form-group>
                                                        <b-form-input class="border-grey" :value="product.name" disabled/>
                                                    </b-form-group>
                                                    <b-form-group label="Quantidade:">
                                                        <b-form-input class="border-grey" type="number" v-model="product.amount"/>
                                                    </b-form-group>
                                                    <b-form-group label="Preço unitário:">
                                                        <b-form-input class="border-grey" v-model="product.price"/>
                                                    </b-form-group>
                                                    <button class="bg-success rounded border-0 text-white py-2" @click="rdUpdateProduct($event, product, clientSelected.crmData.deal._id)">
                                                        Salvar
                                                    </button>
                                                </span>
                                            </div>
                                        </span>
                                        <span class="text-center w-100 p-2" v-else>
                                            Nenhum produto associado!
                                        </span>
                                    </div>
                                </b-collapse>
                            </div>
                            <div v-if="clientSelected.crmData.deal">
                                <div class="bg-white border-bottom py-3 px-4 hstack justify-content-between" v-b-toggle.collapse-crmTasks>
                                    <div class="text-secondary">
                                        Tarefas
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmTasks" class="border-bottom py-3 px-4 bg-white">
                                    <div class="vstack gap-3">
                                        <b-button variant="outline-primary" v-b-modal.rdCreateTask>Cadastrar Tarefa</b-button>
                                        <b-button variant="outline-primary" v-b-modal.rdViewTasks @click="rdGetTasks(1)">Visualizar Tarefas</b-button>
                                        <b-button variant="outline-primary" v-b-modal.rdViewDoneTasks @click="rdGetTasks(1, 'true')">Visualizar Tarefas Concluídas</b-button>
                                    </div>
                                </b-collapse>
                            </div>
                            <div v-if="clientSelected.crmData.deal">
                                <div class="border-bottom py-3 px-4 hstack justify-content-between" v-b-toggle.collapse-crmAnnotations>
                                    <div class="text-secondary">
                                        Anotações
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmAnnotations" class="border-bottom py-3 px-4 bg-white">
                                    <div class="vstack gap-3">
                                        <div class="text-body-tertiary small-text">
                                            Adicione uma anotação ou selecione mensagens para salvar como anotação do Negócio
                                        </div>
                                        <div class="vstack gap-2">
                                            <b-button variant="outline-primary" v-b-modal.rdCreateAnnotation>Adicionar Anotação</b-button>
                                            <b-button variant="outline-primary" v-b-modal.rdSelectMsgsAnnotation>Selecionar Mensagens</b-button>
                                            <b-button variant="outline-primary" v-b-modal.rdHistoryAnnotations @click="rdGetAnnotations(1)">Histórico</b-button>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                            <div class="border-bottom py-3 px-4 hstack justify-content-between bg-green text-white fw-semibold" v-if="clientSelected.crmData.deal?.deal_products?.length">
                                <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Valor do Negócio">
                                    R$ {{ clientSelected.crmData.deal?.deal_products.reduce((total, item) => item.price && total + (parseFloat(item.price.toString().replace(',','.')) * (item.amount || 1)), 0).toString().replace('.',',') | floatingPoint }}
                                </span>
                                <div class="invisible">
                                    <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                        <b-icon stacked icon="circle-fill" variant="green"/>
                                        <b-icon stacked class="text-white" icon="pencil-fill" scale=".5"/>
                                    </b-iconstack>
                                </div>
                            </div>
                            <div v-if="clientSelected.crmData.deal">
                                <div class="bg-white border-bottom py-3 px-4 hstack justify-content-between bg-grey" v-b-toggle.collapse-crmWin>
                                    <div class="text-secondary fw-semibold">
                                        Finalização da Venda
                                        <b-icon :class="clientSelected.crmData.deal.win ? 'text-success' : 'text-danger'" :icon="clientSelected.crmData.deal.win ? 'hand-thumbs-up-fill' : 'hand-thumbs-down-fill'"/>
                                    </div>
                                    <div class="invisible">
                                        <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                            <b-icon stacked icon="circle-fill" variant="green"/>
                                            <b-icon stacked class="text-white" icon="pencil-fill" scale=".5"/>
                                        </b-iconstack>
                                    </div>
                                </div>
                                <b-collapse id="collapse-crmWin" class="border-bottom py-3 px-4 bg-white">
                                    <b-form class="vstack gap-3" @submit.prevent="rdUpdateWin">
                                        <b-form-radio-group
                                            class="hstack gap-3"
                                            :checked="clientSelected.crmData.deal.win"
                                        >
                                            <b-form-radio class="hstack gap-2 text-green input-scale-1-3 fw-semibold" :value="true" @change="rdWinChanged($event, true)">Venda Efetivada</b-form-radio>
                                            <b-form-radio class="hstack gap-2 text-red input-scale-1-3 fw-semibold" :value="false" @change="rdWinChanged($event, false)">Venda Não Efetivada</b-form-radio>
                                        </b-form-radio-group>
                                        <div class="hstack gap-2">
                                            <b-button class="fw-semibold px-4 bg-green3 border-green3 py-1" type="submit" :disabled="rdCreatingDeal" v-b-toggle.collapse-crmWin>
                                                <b-spinner class="me-2" small v-if="rdCreatingDeal"/>
                                                Salvar
                                            </b-button>
                                            <b-button class="bg-white border-grey text-secondary fw-semibold py-1" v-b-toggle.collapse-crmWin @click="rdWinCanceled">Cancelar</b-button>
                                        </div>
                                    </b-form>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal id="modal-receivedmedia" ref="modal-receivedmedia" size="xl" header-class="d-flex justify-content-end py-0" footer-class="modalreceivedmediafooter px-4" hide-footer>
            <template #modal-header>
                <div class="modalreceivedmediaheadericons d-flex align-items-center p-2">
                    <!-- <b-icon scale="1.25" icon="chat-square-text" role="button" v-b-tooltip.hover="{ customClass: 'top-0', boundary: 'document' }" title="Ir para mensagem"/> -->
                    <div class="text-dark" @click="downloadMedia(selectedMedia)"><b-icon scale="1.25" icon="download" role="button" v-b-tooltip.hover="{ customClass: 'top-0', boundary: 'document' }" title="Baixar Arquivo"/></div>
                    <b-icon scale="2.25" icon="x" role="button" @click="$bvModal.hide('modal-receivedmedia')"/>
                </div>
            </template>     
            <div> 
                <div class="d-flex align-items-center justify-content-between px-3">
                    <b-icon class="modalreceivedmediaarrow rounded-circle invisible" icon="chevron-left" role="button"/>
                    <div class="d-flex justify-content-center" style="flex-wrap:wrap; cursor:default" v-b-modal.modal-receivedmedia>
                        <a :href="selectedMedia" target="_blank"><b-img class="receivedmediaimg modalreceivedmediaimg" :src="selectedMedia"/></a>   
                    </div>
                    <b-icon class="modalreceivedmediaarrow rounded-circle invisible" icon="chevron-right" role="button"/>
                </div> 
            </div>    
            <template #modal-footer>
                <div class="d-flex" style="cursor:default; overflow-x: auto" v-b-modal.modal-receivedmedia>
                    <b-img class="m-1 receivedmediaimg modalreceivedmediaimg" :src="selectedMedia"/>
                </div>
            </template>    
        </b-modal>
        <b-modal id="modal-fwdprotocol" ref="modal-fwdprotocol" title="Encaminhar Atendimento" header-class="py-0" hide-footer>
            <b-form-group class="mt-3" label="Encaminhar para:">
                <b-form-select class="border-grey w-100 p-2" v-model="forward.type" @select="getForwardList()">
                    <option>Departamento</option>
                    <option>Operador</option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-3" label="Departamento" v-if="forward.type=='Departamento'">
                <b-form-select class="border-grey w-100 p-2" v-model="forward.department">
                    <option v-for="item in departments" :key="item.id" :value="item">{{item.name}}</option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-3" label="Operador" v-else-if="forward.type=='Operador'">
                <b-form-select class="border-grey w-100 p-2" v-model="forward.operator">
                    <option v-for="item in notThisOperator(onlineOperators)" :key="item.id" :value="item">{{item.name}}</option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3 mb-3">
                <b-button class="btn-success me-2" @click="forwardTo()">Encaminhar</b-button>
                <b-button class="btn-danger" @click="$bvModal.hide('modal-fwdprotocol')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-clientProtocols" ref="modal-clientProtocols" title="Protocolos Anteriores" header-class="py-0" title-class="" hide-footer>
            <div class="d-flex justify-content-between mb-2" v-for="item in clientProtocols" :key="item.id">
                <div class="bg-primary text-white rounded-pill d-flex align-items-center justify-content-center px-2" style="width:175px">{{item.protocol}}</div>
                <div class="d-flex align-items-center ">{{formatDate(item.createdAt)}}</div>
                <b-button class="btn-success" @click="loadProtocolMessages(item)">Carregar</b-button>
            </div>
            <!-- <div class="mt-3 mb-3">
                <b-button class="btn-success me-2" @click="loadProtocolMessages()">Carregar Mensagens</b-button>
                <b-button class="btn-danger" @click="$bvModal.hide('modal-clientProtocols')">Cancelar</b-button>
            </div> -->
        </b-modal>
        <b-modal id="modal-workTimeOver" ref="modal-workTimeOver" title="Horário finalizado" header-class="py-0" hide-footer>
            <div class="text-center mb-3">
                <b-iconstack font-scale="5">
                    <b-icon stacked icon="circle-fill" variant="secondary"/>
                    <b-icon stacked icon="person-fill" scale="0.7" variant="white"/>
                    <b-icon stacked icon="x-circle" variant="danger" font-scale=".75"/>
                </b-iconstack>
            </div>
            <div class="text-center text-secondary h6 m-0">
                Seu horário de atendimento foi finalizado! <br>
                Você não receberá novos atendimentos!
            </div>
            <!-- <div class="mt-3 d-flex align-items-center justify-content-center">
                <b-button class="btn-secondary rounded-pill px-3" @click="$bvModal.hide('modal-workTimeOver')">Ok</b-button>
            </div> -->
        </b-modal>
        <b-modal id="modal-tabulations" ref="modal-tabulations" header-class="py-0" hide-header hide-footer v-if="clientSelected">
            <div class="text-secondary h6 m-0 mb-3">
                Tabulação:
            </div>
            <b-form-select class="w-100 p-1 rounded border-grey" v-model="clientSelected.completionReason">
                <option :value="item.text" v-for="item in tabulations" :key="item.id">{{ item.text }}</option>
            </b-form-select>
            <div class="mt-3">
                <b-button class="btn-success me-2" @click="finishAttendance(clientSelected,1)">Finalizar Atendimento</b-button>
                <b-button class="btn-danger" @click="$bvModal.hide('modal-tabulations')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-activecomm" ref="modal-activecomm" title="Comunicação Ativa" header-class="text-secondary py-0"  content-class="text-secondary" title-class="text-secondary" hide-footer v-if="templates">
            <div v-if="!workTimeOver">
                <b-row class="m-0 pt-2 pb-4 border-bottom">
                    <b-col class="d-flex align-items-center justify-content-center" cols="2">
                        <b-icon icon="exclamation-triangle-fill" scale="4" variant="danger"/>
                    </b-col>
                    <b-col class="align-items-center">
                        Ao solicitar o convite, verifique se este contato possui <b>WhatsApp</b>!
                    </b-col>
                </b-row>
                <b-row class="m-0">
                    <div class="vstack border-bottom py-3 gap-3">
                        <div class="ps-0">
                            <b-form-group class="h6 m-0" label="Nome Completo: ">
                                <b-form-input class="w-100" placeholder="Escreva o Nome do Contato..." v-model="selectedTemplate.contactName"/>
                            </b-form-group>
                        </div>
                        <div class="hstack align-items-end gap-3 w-100">
                            <b-form-group class="w-100" label-class="h6 m-0" label="País:">
                                <v-select class="countrySelect" v-model="selectedTemplate.ddi" :options="countryOptions" :reduce="label => label?.value" label="label" @input="inputTemplateCountry"></v-select>
                            </b-form-group>
                            <b-form-group class="h6 m-0 w-100" label="Número do Telefone:">
                                <b-form-input class="w-100" :class="{ 'border border-danger': selectedTemplate.error && (!selectedTemplate.contactNumber || selectedTemplate.contactNumber.length < 5) }" @input="handleSelectTemplateNumberInput" v-maska="selectedTemplate.ddi ? (selectedTemplate.ddi == '55' ? [`55(##)####-####`,`55(##)#####-####`] : `${selectedTemplate.ddi?.value || selectedTemplate.ddi}#*`) : '#*'" v-model="selectedTemplate.contactNumber" type="tel" required/>
                            </b-form-group>
                        </div>
                    </div>
                    <b-row class="m-0 mt-2 p-0 w-100">
                        <b-form-group class="h6 m-0 p-0 w-100"  label="Mensagem de Chamada:">
                            <div class="d-flex flex-column gap-3">
                                <b-form-select class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="selectedTemplate.template" v-if="isHSM()">
                                    <option :value="item" v-for="item in templates" :key="item.id" >{{ item.name }}</option>
                                </b-form-select>
                                <b-form-select class="w-100 rounded text-secondary p-2" :class="{ 'border border-danger': selectedTemplate.error && !selectedTemplate.bodyId }" style="border: 1px solid #ced4da" v-model="selectedTemplate.bodyId" v-else>
                                    <option :value="{body: item.conteudo, id: item.id}" v-for="item in templates" :key="item.id" >{{ item.nome }}</option>
                                </b-form-select>
                                <div class="d-flex flex-column gap-3" v-if="(operator.channel?.apiType == 'cloud' || operator.channel?.apiType == 'gupshup') && selectedTemplate.template">
                                    <Whatsapp :messages="[{ type: 'template', body: selectedTemplate.template, fromMe: true }]" :height="'30vh'" :mediaURL="mediaURL" />
                                    <div class="d-flex flex-column gap-2 border rounded p-3" v-if="selectedTemplate.template?.dynamicVariables?.length">
                                        <div class="text-secondary fw-semibold">
                                            Variáveis dinâmicas:
                                        </div>
                                        <div class="d-flex flex-column gap-3">
                                            <div v-for="item in selectedTemplate.template.dynamicVariables" :key="item._id">
                                                <b-form-group class="h6 m-0 p-0 w-100" label-class="text-secondary fw-normal" :label="`Insira o valor de ${item.number} (${item.name}):`">
                                                    <b-form-input class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="item.value" required />
                                                </b-form-group>
                                                <div class="text-secondary fw-normal" v-if="item.value?.length">
                                                    {{ item.value?.length }} caracteres
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <b-form-textarea class="mt-1 w-100" :class="{ 'border border-danger': selectedTemplate.error && !selectedTemplate.bodyId?.body?.length }" v-model="selectedTemplate.bodyId.body" placeholder="Escreva aqui sua mensagem de Chamada..." rows="5" max-rows="5" no-resize required v-else-if="selectedTemplate?.bodyId?.body || selectedTemplate?.bodyId?.body==''"/>
                            </div>
                        </b-form-group>
                    </b-row>
                </b-row>
                <b-row class="m-0 mt-3" v-if="operator.channel?.apiType == 'business'">
                    <div class="text-danger p-0 smaller-text"><b>Obs.:</b> Ao enviar esta mensagem, certifique-se que o cliente deseja receber seu conteúdo. Caso sua abordagem seja denunciada pelo cliente ou não adeque-se as políticas do WhatsApp, seu número poderá ser bloqueado!</div>
                </b-row>
                <div class="mt-3 pt-3 px-3 border-top" style="margin: 0 -1em">
                    <b-button class="btn-success d-inline-flex align-items-center" @click="sendNotification()" :disabled="loadingSN || activeCommunicationTimeLeft > 1000">
                        <b-spinner class="me-2" small v-if="loadingSN"/>
                        <div class="d-flex">
                            <div>
                                Enviar
                            </div>
                            <div class="ms-2" v-if="activeCommunicationTimeLeft > 1000">
                                {{ formatTime(activeCommunicationTimeLeft) }}
                            </div>
                        </div>
                    </b-button>
                    <b-button class="btn-danger ms-2" @click="$bvModal.hide('modal-activecomm')">Cancelar</b-button>
                </div>
            </div>
            <div v-else>
                <div>
                    <div class="small-text fw-bold">
                        Seu horário de atendimento foi finalizado!
                    </div>
                    <div class="small-text">
                        Você não pode enviar comunicação ativa ou iniciar novos atendimentos!
                    </div>
                </div>
                <div class="mt-3">
                    <b-button variant="success" @click="$bvModal.hide('modal-activecomm')">Ok</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modalRetrieveContact" ref="modalRetrieveContact" title="Contatos" header-class="py-0" body-class="p-0" hide-footer size="lg">
            <div class="d-flex align-items-center p-3 bg-light-purple">
                <b-form class="w-100" @submit.prevent="getRetrieveContacts(retrieveContactsSearch)">
                    <b-input-group class="d-flex align-items-center bg-white rounded-pill text-secondary">
                        <template #prepend>
                            <b-icon class="bg-white ms-3" icon="search" role="button" @click="getRetrieveContacts(retrieveContactsSearch)"/>
                        </template>
                        <b-form-input type="search" class="border-0 shadow-none rounded-pill" 
                            placeholder="Procurar Contato" 
                            style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                            v-model="retrieveContactsSearch"
                        />
                    </b-input-group>
                </b-form>
            </div>
            <div class="mt-3">
                <b-tabs id="retrieveContactsTab" align="center" active-nav-item-class="darkenTextNavActive" v-model="retrieveTabIndex" @input="retrieveTabChange">
                    <b-tab title="Recuperar Atendimento" title-link-class="text-secondary"/>
                    <b-tab title="Últimos Atendimentos Finalizados" title-link-class="text-secondary"/>
                    <b-tab title="Carteira de Clientes" :title-link-class="`text-secondary ${!operator.channelConfig?.enableCustomerPortfolio && 'd-none'}`" :disabled="!operator.channelConfig?.enableCustomerPortfolio"/>
                    <b-tab title="" title-link-class="p-0 border-0"/>
                </b-tabs>
            </div>
            <div id="retrieveContactsWrapper" style="max-height: 68vh; overflow-y: auto" @scroll="handleScrollRetrieveContacts" v-if="retrieveContacts?.length">
                <div v-for="item in retrieveContacts" :key="item?._id" @click="startAttendance(item)">
                    <b-row id="retrieveContactsRow" class="clientsDiv m-0 p-3 border-bottom d-flex rounded-bottom" v-if="item">
                        <b-col cols="2" class="p-0 w-auto d-flex align-items-center flex-basis-content">
                            <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(item.photoURL)"/>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center overflow-hidden">
                            <div class="d-flex flex-column ps-2 w-100">
                                <div class="font-weight-bold text-secondary position-relative text-truncate">
                                    {{ item.name || verifyPrivacy(item.number) }}
                                </div>
                                <div class="text-secondary text-truncate small-text lh-085" v-if="item.clientCompany">
                                    {{ item.clientCompany }}
                                </div>
                                <div class="text-secondary text-truncate small-text" v-if="item.name != item.number">
                                    {{ verifyPrivacy(formatNumber(item.number)) }}
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="text-center p-2 text-secondary" v-else-if="!loadingRetrieveContacts">
                Nenhum contato encontrado!
            </div>
            <div class="text-center p-3" v-if="loadingRetrieveContacts">
                <b-spinner/>
            </div>
        </b-modal>
        <b-modal id="modalRetrievePickTemplate" ref="modalRetrievePickTemplate" title="Modelo HSM" body-class="d-flex flex-column gap-3" hide-footer header-class="py-0" v-if="operator.channel?.apiType == 'cloud' || operator.channel?.apiType == 'gupshup'">
            <b-form-select class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="selectedTemplate.template">
                <option :value="item" v-for="item in templates" :key="item._id" >{{ item.name }}</option>
            </b-form-select>
            <Whatsapp :messages="[{ type: 'template', body: selectedTemplate.template, fromMe: true }]" :height="'30vh'" :mediaURL="mediaURL" v-if="selectedTemplate.template" />
            <div class="d-flex flex-column gap-2 border rounded p-3" v-if="selectedTemplate.template?.dynamicVariables?.length">
                <div class="text-secondary fw-semibold">
                    Variáveis dinâmicas:
                </div>
                <div class="d-flex flex-column gap-3">
                    <div v-for="item in selectedTemplate.template.dynamicVariables" :key="item._id">
                        <b-form-group class="h6 m-0 p-0 w-100" label-class="text-secondary fw-normal" :label="`Insira o valor de ${item.number} (${item.name}):`">
                            <b-form-input class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="item.value" />
                        </b-form-group>
                        <div class="text-secondary" v-if="item.value?.length">
                            {{ item.value?.length }} caracteres
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-3 px-3 border-top" style="margin: 0 -1em">
                <b-button class="btn-success d-inline-flex align-items-center" @click="startAttendance(null,true)">
                    <div>Enviar</div>
                </b-button>
                <b-button class="btn-danger ms-2" @click="$bvModal.hide('modalRetrievePickTemplate')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalPickDepartmentRetrieve" ref="modalPickDepartmentRetrieve" title="Departamento de envio" hide-footer header-class="py-0" v-if="operator.department?.length > 1">
            <b-form-group class="h6 m-0 p-0 w-100" label-class="text-secondary" label="Escolha o Departamento:">
                <b-form-select class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="departmentSelected">
                    <option :value="item._id" v-for="item in departments.filter(el => operator.department.includes(el._id))" :key="item?._id" >{{ item.name }}</option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3 pt-3 px-3 border-top" style="margin: 0 -1em">
                <b-button class="btn-success d-inline-flex align-items-center" @click="startAttendance(null,false,true)">
                    <div>Avançar</div>
                </b-button>
                <b-button class="btn-danger ms-2" @click="$bvModal.hide('modalPickDepartmentRetrieve')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalPickChannelRetrieve" ref="modalPickChannelRetrieve" title="Número de envio" hide-footer header-class="py-0">
            <b-form-group class="h6 m-0 p-0 w-100" label-class="text-secondary" label="Escolha o Número:">
                <b-form-select class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="channelSelected">
                    <option :value="item._id" v-for="item in operator.channel ? [operator.channel, ...secondaryChannels] : secondaryChannels" :key="item?._id" >{{ item.channelNumber }}</option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3 pt-3 px-3 border-top" style="margin: 0 -1em">
                <b-button class="btn-success d-inline-flex align-items-center" @click="startAttendance(null,false,true)">
                    <div>Avançar</div>
                </b-button>
                <b-button class="btn-danger ms-2" @click="$bvModal.hide('modalPickChannelRetrieve')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalPickDepartmentNotification" ref="modalPickDepartmentNotification" title="Departamento de envio" hide-footer header-class="py-0" v-if="operator.department?.length > 1">
            <b-form-group class="h6 m-0 p-0 w-100" label-class="text-secondary" label="Escolha o Departamento:">
                <b-form-select class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="departmentSelected">
                    <option :value="item._id" v-for="item in departments.filter(el => operator.department.includes(el._id))" :key="item._id" >{{ item.name }}</option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3 pt-3 px-3 border-top" style="margin: 0 -1em">
                <b-button class="btn-success d-inline-flex align-items-center" @click="getHSMModels(); checkActiveCommunicationInterval()" v-b-modal.modal-activecomm>
                    <div>Avançar</div>
                </b-button>
                <b-button class="btn-danger ms-2" @click="$bvModal.hide('modalPickDepartmentNotification')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalPickChannelNotification" ref="modalPickChannelNotification" title="Número de envio" hide-footer header-class="py-0">
            <b-form-group class="h6 m-0 p-0 w-100" label-class="text-secondary" label="Escolha o Número:">
                <b-form-select class="w-100 rounded text-secondary p-2" style="border: 1px solid #ced4da" v-model="channelSelected">
                    <option :value="item._id" v-for="item in operator.channel ? [operator.channel, ...secondaryChannels] : secondaryChannels" :key="item?._id" >{{ item.channelNumber }}</option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3 pt-3 px-3 border-top" style="margin: 0 -1em">
                <b-button class="btn-success d-inline-flex align-items-center" @click="getHSMModels(); checkActiveCommunicationInterval()" v-b-modal.modal-activecomm>
                    <div>Avançar</div>
                </b-button>
                <b-button class="btn-danger ms-2" @click="$bvModal.hide('modalPickChannelNotification')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="productsList" ref="productsList" title="Lista de Produtos" hide-footer header-class="py-0" v-if="clientSelected">
            <div class="d-flex flex-column align-items-center gap-2" v-if="clientSelected.productsList?.length">
                <span>
                    <div class="productFromList" v-for="product in clientSelected.productsList" :key="product._id">
                        <b-row class="m-0 gap-2 border-bottom">
                            <b-col class="p-0 d-flex align-items-center" cols="4">
                                <b-img class="w-100" :src="product.imageLink" v-if="product.imageLink"/>
                                <b-skeleton-img class="pe-none" no-aspect animation="" v-else/>
                            </b-col>
                            <b-col class="p-2 d-flex flex-column justify-content-center">
                                <div class="fs-5 mb-1 fw-semibold">
                                    {{ product.displayTitle }}
                                </div>
                                <div class="text-secondary box-clamp-1">
                                    {{ product.description }}
                                </div>
                                <div class="d-flex justify-content-between align-items-center text-purple">
                                    <span class="fs-4 fw-bold">
                                        R$ {{ product.salePrice || product.price }}
                                    </span>
                                    <!-- <span class="smaller-text">
                                        com pix
                                    </span> -->
                                    <div v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Remover">
                                        <b-iconstack class="darkenTextOnHover" font-scale="1.5" role="button" @click="removeProductFromList(product)"> 
                                            <b-icon class="text-danger" stacked icon="circle-fill"/>
                                            <b-icon stacked class="text-white" icon="trash" scale="0.5"/>
                                        </b-iconstack>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </span>
                <b-button variant="success" @click="sendProductsList()" :disabled="sendingProductsCart">
                    Enviar Lista
                </b-button>
            </div>
            <div class="text-center text-secondary" v-else>
                Nenhum produto na lista!
            </div>
        </b-modal>
        <b-modal id="productsCart" ref="productsCart" title="Carrinho de Produtos" hide-footer header-class="py-0" v-if="clientSelected">
            <div class="d-flex flex-column align-items-center gap-2" v-if="clientSelected.productsCart?.length">
                <span>
                    <div class="productFromList" v-for="product in clientSelected.productsCart" :key="product._id">
                        <b-row class="m-0 gap-2 border-bottom">
                            <b-col class="p-0 d-flex align-items-center" cols="4">
                                <b-img class="w-100" :src="product.imageLink" v-if="product.imageLink"/>
                                <b-skeleton-img class="pe-none" no-aspect animation="" v-else/>
                            </b-col>
                            <b-col class="p-2 d-flex flex-column justify-content-center">
                                <div class="fs-5 mb-1 fw-semibold">
                                    {{ product.displayTitle }}
                                </div>
                                <div class="text-secondary box-clamp-1">
                                    {{ product.description }}
                                </div>
                                <div class="text-purple d-flex align-items-start gap-3">
                                    <span class="fs-4 fw-bold">
                                        R$ {{ product.salePrice || product.price }}
                                    </span>
                                    <!-- <span class="smaller-text">
                                        com pix
                                    </span> -->
                                    <div>
                                        <div class="d-flex align-items-center border border-dark-subtle rounded w-fit h-fit px-2">
                                            <span class="darkenTextOnHover" @click="productQuantity(product, false)" role="button">
                                                -
                                            </span>
                                            <b-form-input class="border-0 mx-1 min-w-ch6 max-w-min py-0 text-center" ref="cartInput" no-wheel type="number" min="1" max="100000" @input="checkProductQuantity(product)" v-model="product.quantity"/>
                                            <span class="darkenTextOnHover" @click="productQuantity(product, true)" role="button">
                                                +
                                            </span>
                                        </div>
                                        <div class="text-center smaller-text darkenTextOnHover" @click="removeProductFromCart(product)" role="button">
                                            Remover
                                        </div>
                                    </div>
                                </div>
                                <div class="hstack gap-1 smaller-text text-secondary" v-if="product.checkingAvailability">
                                    <b-spinner small />
                                    Verificando disponibilidade...
                                </div>
                                <div class="text-danger" v-else-if="product.unavailable">
                                    PRODUTO INDISPONÍVEL
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </span>
                <b-button variant="success" @click="sendProductsCart()" :disabled="sendingProductsCart || checkingCartAvailability">
                    Enviar Pedido
                </b-button>
            </div>
            <div class="text-center text-secondary" v-else>
                Nenhum produto no carrinho!
            </div>
        </b-modal>
        <b-modal id="forwardMessagePickContacts" ref="forwardMessagePickContacts" title="Encaminhar Mensagem" hide-footer header-class="py-0" body-class="p-0">
            <div class="smaller-text text-secondary py-2 px-3">
                Selecione os contatos para recuperar o atendimento<br/>e encaminhar a mensagem:
            </div>
            <div class="d-flex align-items-center p-3 bg-light-purple">
                <b-form class="w-100" @submit.prevent="getRetrieveContacts(retrieveContactsSearch)">
                    <b-input-group class="d-flex align-items-center bg-white rounded-pill text-secondary">
                        <template #prepend>
                            <b-icon class="bg-white ms-3" icon="search" role="button" @click="getRetrieveContacts(retrieveContactsSearch)"/>
                        </template>
                        <b-form-input type="search" class="border-0 shadow-none rounded-pill" 
                            placeholder="Procurar Contato" 
                            style="font-size: 0.95em; height:2.65em; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;" 
                            v-model="retrieveContactsSearch"
                        />
                    </b-input-group>
                </b-form>
            </div>
            <div style="max-height: 52vh; overflow-y: auto" v-if="retrieveContacts?.length">
                <div v-for="item in contactsToForward" :key="item?._id" @click="uncheckContactToForward(item)">
                    <b-row class="clientsDiv m-0 p-3 border-top d-flex">
                        <b-col cols="2" class="p-0 w-auto d-flex align-items-center flex-basis-content">
                            <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(item.photoURL)"/>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-between overflow-hidden">
                            <div class="d-flex flex-column ps-2 w-100">
                                <div class="font-weight-bold text-secondary position-relative text-truncate">
                                    {{ item.name || verifyPrivacy(item.number) }}
                                </div>
                                <div class="text-secondary text-truncate small-text lh-085" v-if="item.clientCompany">
                                    {{ item.clientCompany }}
                                </div>
                                <div class="text-secondary text-truncate small-text" v-if="item.name != item.number">
                                    {{ verifyPrivacy(formatNumber(item.number)) }}
                                </div>
                            </div>
                            <div>
                                <input :checked="true" type="checkbox" />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <!-- eslint-disable-next-line vue/brace-style -->
                <div v-for="item in retrieveContacts.filter(e => { return !contactsToForward.find(el => el._id == e._id) })" :key="item._id" @click="checkContactToForward(item)">
                    <b-row class="clientsDiv m-0 p-3 border-top d-flex">
                        <b-col cols="2" class="p-0 w-auto d-flex align-items-center flex-basis-content">
                            <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(item.photoURL)"/>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-between overflow-hidden">
                            <div class="d-flex flex-column ps-2 w-100">
                                <div class="font-weight-bold text-secondary position-relative text-truncate">
                                    {{ item.name || verifyPrivacy(item.number) }}
                                </div>
                                <div class="text-secondary text-truncate small-text lh-085" v-if="item.clientCompany">
                                    {{ item.clientCompany }}
                                </div>
                                <div class="text-secondary text-truncate small-text" v-if="item.name != item.number">
                                    {{ verifyPrivacy(formatNumber(item.number)) }}
                                </div>
                            </div>
                            <div>
                                <input :checked="false" type="checkbox" />
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="text-center p-2 text-secondary" v-else>
                Nenhum contato encontrado!
            </div>
            <div class="text-center p-3 border-top" v-if="contactsToForward.length">
                <b-button class="" variant="success" @click="forwardMessageToContacts">
                    Encaminhar para Selecionados
                </b-button>
            </div>
        </b-modal>
        <b-modal id="viewProductsList" ref="viewProductsList" :title="selectedProductList.header?.text || 'Lista'" hide-footer header-class="py-0" v-if="selectedProductList">
            <div class="d-flex flex-column align-items-center gap-2">
                <span>
                    <div class="productFromList" v-for="product in selectedProductList.productList" :key="product._id">
                        <b-row class="m-0 gap-2 border-bottom">
                            <b-col class="p-0 d-flex align-items-center" cols="4">
                                <b-img class="w-100" :src="product.imageLink" v-if="product.imageLink"/>
                                <b-skeleton-img class="pe-none" no-aspect animation="" v-else/>
                            </b-col>
                            <b-col class="p-2 d-flex flex-column justify-content-center">
                                <div class="fs-5 mb-1 fw-semibold">
                                    {{ product.displayTitle }}
                                </div>
                                <div class="text-secondary box-clamp-1">
                                    {{ product.description }}
                                </div>
                                <div class="text-purple">
                                    <span class="fs-4 fw-bold">
                                        R$ {{ product.salePrice || product.price }}
                                    </span>
                                    <!-- <span class="smaller-text">
                                        com pix
                                    </span> -->
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </span>
                <b-button variant="success" @click="$bvModal.hide('viewProductsList')">
                    Enviar
                </b-button>
            </div>
        </b-modal>
        <b-modal id="viewProductsCart" ref="viewProductsCart" title="Carrinho" hide-footer header-class="py-0" v-if="selectedProductCart">
            <div class="d-flex flex-column align-items-center gap-2">
                <span>
                    <div class="productFromCart" v-for="product in selectedProductCart" :key="product._id">
                        <b-row class="m-0 gap-2 border-bottom">
                            <b-col class="p-0 d-flex align-items-center" cols="4">
                                <b-img class="w-100" :src="product.image" v-if="product.image"/>
                                <b-skeleton-img class="pe-none" no-aspect animation="" v-else/>
                            </b-col>
                            <b-col class="p-2 d-flex flex-column justify-content-center">
                                <div class="fs-5 mb-1 fw-semibold">
                                    {{ product.title }}
                                </div>
                                <div class="text-secondary box-clamp-1">
                                    {{ product.description }}
                                </div>
                                <div class="text-purple">
                                    Quantidade: {{ product.quantity }}
                                </div>
                                <div class="text-purple">
                                    <span class="fs-4 fw-bold">
                                        R$ {{ String(product.amount) | floatingPoint }}
                                    </span>
                                    <!-- <span class="smaller-text">
                                        com pix
                                    </span> -->
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </span>
                <b-button variant="success" @click="$bvModal.hide('viewProductsCart')">
                    Enviar
                </b-button>
            </div>
        </b-modal>
        <b-modal id="rdCreateOrganization" ref="rdCreateOrganization" title="Cadastrar Cliente" hide-footer header-class="py-0">
            <b-form @submit.prevent="rdCreateOrganization(clientSelected.crmData)">
                <div class="d-flex flex-column gap-3">
                    <b-form-group label="Nome do cliente:">
                        <b-form-input v-model="newRDOrganization.name" required/>
                    </b-form-group>
                    <b-form-group label="Segmento de atuação:">
                        <b-form-tags
                            class="inputKeywords"
                            add-button-text="Adicionar"
                            add-button-variant="primary"
                            duplicate-tag-text="Segmento duplicado"
                            tag-remove-label="Remover Segmento"
                            tag-removed-label="Segmento removido"
                            invalid-tag-text="Segmento Inválido"
                            :tag-validator="orgSegmentValidator"
                            placeholder="Adicionar Segmento"
                            v-model="newRDOrganization.organization_segments"
                        />
                    </b-form-group>
                    <div class="d-flex gap-2">
                        <b-button type="submit" variant="success" :disabled="rdCreatingOrganization">
                            <b-spinner class="me-2" small v-if="rdCreatingOrganization"/>
                            Cadastrar
                        </b-button>
                        <b-button variant="danger" @click="$bvModal.hide('rdCreateOrganization')">
                            Cancelar
                        </b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="rdCreateDeal" ref="rdCreateDeal" title="Cadastrar Negócio" hide-footer header-class="py-0">
            <b-form @submit.prevent="rdCreateDeal(clientSelected.crmData)">
                <div class="d-flex flex-column gap-3">
                    <b-form-group label="Nome do negócio:">
                        <b-form-input v-model="newRDDeal.deal.name" required/>
                    </b-form-group>
                    <div class="d-flex gap-2">
                        <b-button type="submit" variant="success" :disabled="rdCreatingDeal">
                            <b-spinner class="me-2" small v-if="rdCreatingDeal"/>
                            Cadastrar
                        </b-button>
                        <b-button variant="danger" @click="$bvModal.hide('rdCreateDeal')">
                            Cancelar
                        </b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="rdAssociateProduct" ref="rdAssociateProduct" title="Associar Produto" hide-footer header-class="py-0" v-if="clientSelected.crmData?.deal">
            <b-form @submit.prevent="rdAssociateProduct(clientSelected.crmData)">
                <div class="d-flex flex-column gap-3">
                    <b-form-group label="Produto:">
                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="clientSelected.crmData.deal.product">
                            <b-form-select-option disabled :value="null || undefined">
                                Selecionar produto
                            </b-form-select-option>
                            <b-form-select-option v-for="product in clientSelected.crmProducts" :key="product._id" :value="product">
                                {{ product.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <span class="d-flex flex-column gap-3" v-if="clientSelected.crmData.deal.product">
                        <b-form-group label="Quantidade:">
                            <b-form-input type="number" v-model="clientSelected.crmData.deal.product.amount" min="1"/>
                        </b-form-group>
                        <b-form-group label="Preço unitário:">
                            <b-form-input v-model="clientSelected.crmData.deal.product.price"/>
                        </b-form-group>
                    </span>
                    <div class="d-flex gap-2">
                        <b-button type="submit" variant="success" :disabled="rdCreatingDeal">
                            <b-spinner class="me-2" small v-if="rdCreatingDeal"/>
                            Cadastrar
                        </b-button>
                        <b-button variant="danger" @click="$bvModal.hide('rdAssociateProduct')">
                            Cancelar
                        </b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="rdCreateAnnotation" ref="rdCreateAnnotation" title="Adicionar Anotação" header-class="py-1" hide-footer v-if="clientSelected?.crmData?.deal">
            <b-form class="vstack gap-3" @submit.prevent="rdCreateAnnotation">
                <b-form-textarea 
                    class="w-100"
                    placeholder="Escreva aqui sua anotação..."
                    rows="5"
                    max-rows="5"
                    no-resize
                    v-model="newRDAnnotation.text"
                />
                <div class="d-flex gap-2">
                    <b-button type="submit" variant="success" :disabled="rdCreatingAnnotation">
                        <b-spinner class="me-2" small v-if="rdCreatingAnnotation"/>
                        Salvar
                    </b-button>
                    <b-button variant="danger" @click="$bvModal.hide('rdCreateAnnotation')">
                        Cancelar
                    </b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="rdSelectMsgsAnnotation" ref="rdSelectMsgsAnnotation" title="Adicionar Anotação" body-class="vstack gap-3" header-class="py-1" hide-footer v-if="clientSelected?.crmData?.deal">
            <div class="text-secondary text-center" v-if="!clientSelected.msgs?.length">
                Nenhuma mensagem para selecionar!
            </div>
            <b-form class="vstack gap-3" v-else>
                <div class="hstack gap-2 align-self-center">
                    <b-button class="small-text py-1 px-2" variant="outline-info" @click="rdCheckAllMessagesToAnnotate">
                        Selecionar tudo
                    </b-button>
                    <b-button class="small-text py-1 px-2" variant="outline-info" @click="rdUncheckAllMessagesToAnnotate">
                        Apagar Seleção
                    </b-button>
                </div>
                <div class="overflow-y-auto bg-grey rounded p-2" style="max-height: 65vh">
                    <b-form-checkbox-group class="vstack gap-1" v-model="clientSelected.crmData.deal.msgsToAnnotate">
                        <div v-for="msg in clientSelected.msgs" :key="msg._id">
                            <div class="d-flex align-items-center gap-2" v-if="msg.type == 'chat' || msg.type == 'text'">
                                <b-form-checkbox 
                                    :value="`[${$options.filters.date(handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] ${msg.fromMe ? operator.name : clientSelected.clientName}: ${msg.body}`"
                                />
                                <div class="rounded px-2" :class="{ 'ms-auto bg-fromMe': msg.fromMe, 'bg-notFromMe': !msg.fromMe }">
                                    {{  msg.body }}
                                </div>
                            </div>
                            <div class="d-flex align-items-center gap-2" v-if="msg.type == 'image'">
                                <b-form-checkbox 
                                    :value="`[${$options.filters.date(handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] ${msg.fromMe ? operator.name : clientSelected.clientName}: ${hasFullPath(msg.mediaURL)}${msg.caption ? ` ${msg.caption}` : ''}`"
                                />
                                <div class="rounded p-1" :class="{ 'ms-auto bg-fromMe': msg.fromMe, 'bg-notFromMe': !msg.fromMe }">
                                    <img class="rounded" :src="hasFullPath(msg.mediaURL)" style="max-width:100%; height: auto;"/>
                                    <div style="white-space:break-spaces" v-if="msg.caption">{{msg.caption}}</div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center gap-2" v-if="msg.type == 'video'">
                                <b-form-checkbox 
                                    :value="`[${$options.filters.date(handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] ${msg.fromMe ? operator.name : clientSelected.clientName}: ${hasFullPath(msg.mediaURL)}${msg.caption ? ` ${msg.caption}` : ''}`"
                                />
                                <div class="rounded p-1" :class="{ 'ms-auto bg-fromMe': msg.fromMe, 'bg-notFromMe': !msg.fromMe }">
                                    <video class="rounded" controls style="max-width:100%">
                                        <source :src="hasFullPath(msg.mediaURL)" type="video/mp4">
                                    </video>
                                    <div style="white-space:break-spaces" v-if="msg.caption">{{msg.caption}}</div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center gap-2" v-if="msg.type == 'ptt' || msg.type == 'audio'">
                                <b-form-checkbox 
                                    :value="`[${$options.filters.date(handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] ${msg.fromMe ? operator.name : clientSelected.clientName}: ${hasFullPath(msg.mediaURL)}${msg.caption ? ` ${msg.caption}` : ''}`"
                                />
                                <div class="rounded p-1" :class="{ 'ms-auto bg-fromMe': msg.fromMe, 'bg-notFromMe': !msg.fromMe }">
                                    <video class="rounded audioplayer" controls style="height:3em; width:18em">
                                        <source :src="hasFullPath(msg.mediaURL)" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                            <div class="d-flex align-items-center gap-2" v-if="msg.type == 'file' || msg.type == 'document' || msg.type == 'application'">
                                <b-form-checkbox 
                                    :value="`[${$options.filters.date(handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] ${msg.fromMe ? operator.name : clientSelected.clientName}: ${hasFullPath(msg.mediaURL)}${msg.caption ? ` ${msg.caption}` : ''}`"
                                />
                                <div class="rounded p-1" :class="{ 'ms-auto bg-fromMe': msg.fromMe, 'bg-notFromMe': !msg.fromMe }">
                                    <div class="downloadmediadiv rounded text-decoration-none">
                                        <div v-if="msg.filename" class="text-white">{{msg.filename}}</div>
                                        <b-icon 
                                            :icon="(fileExt(msg.caption)=='csv' || fileExt(msg.caption)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                            :class="{ 'text-success': (fileExt(msg.caption)=='csv' || fileExt(msg.caption)=='xlsx'), 'text-danger': fileExt(msg.caption)=='pdf'}" 
                                            font-scale="4"
                                        />
                                    </div>
                                    <div style="white-space:break-spaces" v-if="msg.caption">{{msg.caption}}</div>
                                </div>
                            </div>
                        </div>
                    </b-form-checkbox-group>
                </div>
            </b-form>
            <div class="vstack gap-2">
                <b-form-checkbox class="hstack gap-2 text-secondary" v-model="clientSelected.crmData.deal.contactInfoPermission">
                    O contato deu consentimento sobre a informação salva
                </b-form-checkbox>
                <div class="d-flex gap-2">
                    <b-button class="text-white" variant="success" :disabled="!clientSelected.crmData.deal.contactInfoPermission" @click="rdCreateAnnotationFromMessages">
                        <b-spinner class="me-2" small v-if="rdCreatingAnnotation"/>
                        Salvar como anotação
                    </b-button>
                    <b-button class="text-white" variant="danger" @click="clientSelected.crmData.deal.contactInfoPermission = false; clientSelected.crmData.deal.msgsToAnnotate = []; $bvModal.hide('rdSelectMsgsAnnotation')">
                        Cancelar
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="rdHistoryAnnotations" ref="rdHistoryAnnotations" title="Histórico de Anotações" body-class="p-0" header-class="py-1" hide-footer v-if="clientSelected?.crmData?.deal">
            <div>
                <div class="p-3" v-if="rdLoadingAnnotations">
                    <b-skeleton width="85%"/>
                    <b-skeleton width="55%"/>
                    <b-skeleton width="70%"/>
                </div>
                <span v-else>
                    <div class="crmAnnotationsDiv vstack gap-2 border-bottom py-2 px-3" v-for="annotation in clientSelected.crmData.deal.annotations" :key="annotation._id">
                        <div class="vstack">
                            <b>Data:</b>
                            <div class="text-secondary">
                                {{ annotation.date | date('DD/MM/YYYY HH:mm:ss') }}
                            </div>
                        </div>
                        <div class="vstack">
                            <b>Anotação:</b>
                            <div class="text-secondary" style="white-space: pre-line; word-wrap: break-word" v-html="linkedText(annotation.text)"></div>
                        </div>
                    </div>
                </span>
                <div class="d-flex justify-content-between align-items-center px-2 py-3" v-if="clientSelected.crmData.deal.totalAnnotations > 10">
                    <b-pagination class="m-0"
                        :total-rows="clientSelected.crmData.deal.totalAnnotations"
                        v-model="rdCurrentAnnotationsPage"
                        :per-page="10"
                        @change="rdAnnotationsPageChange"
                    />
                    <span class="me-1">
                        <div class="text-secondary">{{ ( rdCurrentAnnotationsPage > 1 ) ? ((rdCurrentAnnotationsPage) * 10) - 10 + 1 : rdCurrentAnnotationsPage }} - {{ (rdCurrentAnnotationsPage * 10 > clientSelected.crmData.deal.totalAnnotations) ? clientSelected.crmData.deal.totalAnnotations : rdCurrentAnnotationsPage * 10 }} de {{ clientSelected.crmData.deal.totalAnnotations }} anotações</div>
                    </span>
                </div>
            </div>
        </b-modal>
        <b-modal id="rdCreateTask" ref="rdCreateTask" title="Cadastrar Nova Tarefa" body-class="p-0" header-class="py-1" hide-footer v-if="clientSelected?.crmData?.deal">
            <div class="vstack gap-3 p-3">
                <b-form-group label="Descrição:">
                    <b-form-input class="border-grey" v-model="rdNewTask.subject" required/>
                </b-form-group>
                <b-form-group label="Tipo:">
                    <b-form-select class="w-100 rounded p-2 border-grey" v-model="rdNewTask.type">
                        <b-form-select-option disabled :value="null || undefined">
                            Selecionar tipo
                        </b-form-select-option>
                        <b-form-select-option value="task">
                            Tarefa
                        </b-form-select-option>
                        <b-form-select-option value="lunch" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Almoço
                        </b-form-select-option>
                        <b-form-select-option value="email" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Email
                        </b-form-select-option>
                        <b-form-select-option value="call" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Ligação
                        </b-form-select-option>
                        <b-form-select-option value="meeting" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Reunião
                        </b-form-select-option>
                        <b-form-select-option value="visit" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Visita
                        </b-form-select-option>
                        <b-form-select-option value="whatsapp" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Whatsapp
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Data:">
                    <b-form-input type="date" v-model="rdNewTask.date" required/>
                </b-form-group>
                <b-form-group label="Hora:">
                    <b-form-input type="time" v-model="rdNewTask.hour" required/>
                </b-form-group>
                <div class="hstack gap-3">
                    <b-button variant="success" :disabled="rdCreatingTask" @click="rdCreateTask">Salvar</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('rdCreateTask')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="rdUpdateTask" ref="rdUpdateTask" title="Editar Tarefa" body-class="p-0" header-class="py-1" hide-footer v-if="rdSelectedTask">
            <div class="vstack gap-3 p-3">
                <b-form-group label="Descrição:">
                    <b-form-input class="border-grey" v-model="rdSelectedTask.subject" required/>
                </b-form-group>
                <b-form-group label="Tipo:">
                    <b-form-select class="w-100 rounded p-2 border-grey" v-model="rdSelectedTask.type">
                        <b-form-select-option disabled :value="null || undefined">
                            Selecionar tipo
                        </b-form-select-option>
                        <b-form-select-option value="task">
                            Tarefa
                        </b-form-select-option>
                        <b-form-select-option value="lunch" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Almoço
                        </b-form-select-option>
                        <b-form-select-option value="email" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Email
                        </b-form-select-option>
                        <b-form-select-option value="call" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Ligação
                        </b-form-select-option>
                        <b-form-select-option value="meeting" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Reunião
                        </b-form-select-option>
                        <b-form-select-option value="visit" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Visita
                        </b-form-select-option>
                        <b-form-select-option value="whatsapp" v-if="operator.channelConfig?.rdPlan && (operator.channelConfig.rdPlan == 'basic' || operator.channelConfig.rdPlan == 'pro')">
                            Whatsapp
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Data:">
                    <b-form-input type="date" v-model="rdSelectedTask.date" required/>
                </b-form-group>
                <b-form-group label="Hora:">
                    <b-form-input type="time" v-model="rdSelectedTask.hour" required/>
                </b-form-group>
                <div class="hstack gap-3">
                    <b-button variant="success" @click="rdUpdateTask(rdSelectedTask)">Salvar</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('rdUpdateTask')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="rdViewTasks" ref="rdViewTasks" title="Visualizar Tarefas" size="sm" body-class="p-0" header-class="py-1" hide-footer v-if="clientSelected?.crmData?.deal">
            <div class="vstack gap-3">
                <div class="text-secondary text-center p-3" v-if="rdLoadingTasks">
                    <b-spinner/>
                </div>
                <div v-else-if="rdTasks?.length">
                    <div class="vstack gap-2 p-3 border-bottom" v-for="task in rdTasks" :key="task._id">
                        <div class="hstack justify-content-between">
                            <div class="fw-bold">
                                {{ task.subject }}
                            </div>
                            <div class="bg-primary rounded" v-if="task.pending">
                                <b-badge>Pendente</b-badge>
                            </div>
                        </div>
                        <div class="hstack justify-content-between">
                            <div>Data</div>
                            <div>
                                {{ task.date | date("DD/MM/YYYY", true)}}
                            </div>
                        </div>
                        <div class="hstack justify-content-between">
                            <div>Hora</div>
                            <div>
                                {{ task.hour }}
                            </div>
                        </div>
                        <div class="hstack gap-2 justify-content-end">
                            <b-button variant="success" v-b-modal.rdUpdateTask @click="rdSelectTask(task)">Editar</b-button>
                            <b-button variant="primary" @click="rdSetTagAsDone(task)">Concluir</b-button>
                        </div>
                    </div>
                    <div class="hstack gap-2 p-3">
                        <b-button variant="danger" @click="$bvModal.hide('rdViewTasks')">Cancelar</b-button>
                    </div>
                </div>
                <div class="text-secondary text-center p-3" v-else>
                    Nenhuma tarefa encontrada!
                </div>
            </div>
        </b-modal>
        <b-modal id="rdViewDoneTasks" ref="rdViewDoneTasks" title="Tarefas Concluídas" size="sm" body-class="p-0" header-class="py-1" hide-footer v-if="clientSelected?.crmData?.deal">
            <div class="vstack gap-3">
                <div class="text-secondary text-center p-3" v-if="rdLoadingTasks">
                    <b-spinner/>
                </div>
                <div v-else-if="rdTasks?.length">
                    <div class="vstack gap-2 p-3 border-bottom" v-for="task in rdTasks" :key="task._id">
                        <div class="fw-bold">
                            {{ task.subject }}
                        </div>
                        <div class="hstack justify-content-between">
                            <div>Data</div>
                            <div>
                                {{ task.date | date("DD/MM/YYYY", true)}}
                            </div>
                        </div>
                        <div class="hstack justify-content-between">
                            <div>Hora</div>
                            <div>
                                {{ task.hour }}
                            </div>
                        </div>
                        <div class="hstack gap-2 justify-content-end">
                            <b-button variant="success" v-b-modal.rdUpdateTask @click="rdSelectTask(task)">Editar</b-button>
                        </div>
                    </div>
                    <div class="hstack gap-2 p-3">
                        <b-button variant="danger" @click="$bvModal.hide('rdViewDoneTasks')">Cancelar</b-button>
                    </div>
                </div>
                <div class="text-secondary text-center p-3" v-else>
                    Nenhuma tarefa encontrada!
                </div>
            </div>
        </b-modal>
        <b-modal id="newScheduling" ref="newScheduling" body-class="p-0" header-class="px-4 border-grey" v-model="createCalendar" size="lg" hide-footer>
            <template #modal-title>
                <div class="hstack gap-2">
                    <b-img  class="darkenTextOnHover" style="width: 1.5rem; height: 1.5rem;" :src="require('../assets/images/scheduleIcons/notebook.png')"/>
                    <span>Novo Agendamento</span>
                </div>
            </template>
            <div>
                <div class="py-3 px-4">
                    <div class="vstack gap-2" v-if="clientSelected">
                        <b-form class="w-100" @submit.prevent="getRetrieveContacts(retrieveContactsSearch)">
                            <b-form-group label-class="text-purple" label="Agendar Atendimento:">
                                <div class="hstack gap-3">
                                    <b-form-input type="search" class="border-0 shadow-none rounded-pill px-4 bg-light-grey" 
                                        placeholder="Procurar Contato" 
                                        v-model="retrieveContactsSearch"
                                    />
                                    <b-icon class="bg-green3 text-white p-2 rounded-circle darkenOnHover" font-size="2rem" icon="search" role="button" @click="getRetrieveContacts(retrieveContactsSearch)" />
                                </div>
                            </b-form-group>
                        </b-form>
                        <div id="calendarContactsWrapper" style="max-height: 25vh; overflow-y: auto" @scroll="handleScrollCalendarContacts" v-if="retrieveContacts?.length">
                            <div v-for="item in retrieveContacts" :key="item?._id" @click="selectClient(item, true); retrieveContacts = []">
                                <b-row id="calendarContactsRow" class="clientsDiv m-0 p-3 border-bottom-grey d-flex" v-if="item">
                                    <b-col cols="2" class="p-0 w-auto d-flex align-items-center flex-basis-content">
                                        <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(item.photoURL)"/>
                                    </b-col>
                                    <b-col class="p-0 d-flex align-items-center overflow-hidden">
                                        <div class="d-flex flex-column ps-2 w-100">
                                            <div class="font-weight-bold text-secondary position-relative text-truncate">
                                                {{ item.name || verifyPrivacy(item.number) }}
                                            </div>
                                            <div class="text-secondary text-truncate small-text lh-085" v-if="item.clientCompany">
                                                {{ item.clientCompany }}
                                            </div>
                                            <div class="text-secondary text-truncate small-text" v-if="item.name != item.number">
                                                {{ verifyPrivacy(formatNumber(item.number)) }}
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <!-- <div class="text-center p-2 text-secondary" v-else-if="!loadingRetrieveContacts">
                            Nenhum contato encontrado!
                        </div> -->
                        <div class="text-center p-3" v-else-if="loadingRetrieveContacts">
                            <b-spinner/>
                        </div>
                    </div>
                </div>
                <b-row class="m-0 px-4 py-3 border-bottom-grey border-top-grey">
                    <b-col cols="2" class="p-0 d-flex align-items-center flex-basis-content" >
                        <b-avatar class="bg-secondary text-white" size="3.75rem" :src="verifyPhotoURL(clientSelected.photoURL)"/>
                    </b-col>
                    <b-col class="d-flex align-items-center">
                        <div class="fw-semibold text-secondary text-truncate position-relative w-min mw-100">
                            <span>
                                {{ (clientSelected.clientName || verifyPrivacy(clientSelected.clientNumber)) || clientSelected.name }}
                                <span v-if="clientSelected.state">
                                    {{ " " }} de {{ clientSelected.state }}
                                </span>
                                &#8205; &#8205; &#8205; &#8205;
                            </span>                            
                        </div>
                    </b-col>
                </b-row>
                <b-row class="m-0 gap-3 py-3 px-4">                   
                        <b-col class="p-0">
                            <b-form-group label-class="fs-6 text-secondary" label="Descrição:">
                                <b-form-textarea
                                    class="border-grey rounded"
                                    v-model="saveCalendar.description"
                                    placeholder="Descrição"
                                    rows="9"
                                    no-resize
                                />
                            </b-form-group>
                        </b-col>
                        <b-col class="p-0 d-flex flex-column gap-2">
                            <b-row class="m-0 gap-3">
                                <b-col class="p-0 d-flex flex-column gap-2">
                                    <b-form-group label-class="fs-6 text-secondary" label="Data:">
                                        <b-form-input class="border-grey rounded" style="height: 2rem" type="date" size="sm" v-model="saveCalendar.date" locale="pt"/>
                                    </b-form-group>
                                    <b-form-group label-class="fs-6 text-secondary" label="Hora:">
                                        <b-form-input class="border-grey rounded" style="height: 2rem" type="time" placeholder="HH:mm" value="00:00" size="sm" v-model="saveCalendar.time" locale="pt"/>                                        
                                    </b-form-group>
                                </b-col>
                                <b-col class="p-0 d-flex flex-column gap-2">
                                    <b-form-group label-class="fs-6 text-secondary" label="Minutos antes:">
                                        <b-form-spinbutton class="border-grey rounded" style="height: 2rem" v-model="saveCalendar.timeToNotify" min="0" max="60"/>
                                    </b-form-group>
                                    <!-- <b-form-timepicker id="minCalendar" size="sm" v-model="saveCalendar.timeToNotify" button-only format="mm:ss" locale="pt"/> -->
                                    <b-form-group label-class="fs-6 text-secondary" label="Etiqueta:">
                                        <b-form-input class="border-grey rounded" style="height: 2rem" type="color" size="sm" v-model="saveCalendar.color" />                                        
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="hstack gap-2">
                                <label id="calendarNotifyCheckboxLabel" for="calendarNotifyCheckbox" class="border border-grey" style="cursor: pointer; height: .89rem; width: .89rem; border-radius: 3px" v-if="!saveCalendar.notify"></label>
                                <b-form-checkbox
                                    id="calendarNotifyCheckbox"
                                    class="hstack gap-2 text-secondary small-text"
                                    v-model="saveCalendar.notify"
                                > 
                                    Notificar Contato?
                                </b-form-checkbox>
                            </div>
                            <b-form-group label-class="fs-6 text-secondary m-0" v-if="saveCalendar.notify && clientSelected.channelType === 'business'">
                                <b-form-textarea
                                    class="border-grey"
                                    v-model="saveCalendar.reminderMessage"
                                    placeholder="Mensagem"
                                    rows="3"
                                    no-resize
                                />
                            </b-form-group>
                            <b-form-group label-class="fs-6 text-secondary m-0 w-100" label="Template HSM:" v-else-if="saveCalendar.notify">
                                <b-form-select class="w-100 rounded text-secondary p-2 border-grey" v-model="saveCalendar.reminderMessage">
                                    <option :value="item" v-for="item in templates" :key="item.id" >{{ item.name }}</option>
                                </b-form-select>
                                <!-- <Whatsapp class="mt-3" :messages="[{ type: 'template', body: saveCalendar.reminderMessage, fromMe: true }]" :height="'50vh'" :mediaURL="mediaURL" v-if="selectedTemplate.template" /> -->
                            </b-form-group>
                        </b-col>                   
                </b-row>
                <div class="hstack gap-3 pb-3 px-4">
                    <b-button class="bg-green3 border-green3 px-4 d-inline-flex align-items-center" @click="createCalendarOP()">
                        Salvar
                    </b-button>
                    <b-button class="bg-transparent border-grey text-secondary px-3" @click="createCalendar=!createCalendar">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="notificationConfig" ref="notificationConfig" title="Configuração de Notificação" body-class="p-0" header-class="py-1" hide-footer>
            <div class="vstack gap-3 p-3">
                <div class="vstack gap-3">
                    <b-form-checkbox class="hstack gap-2" v-model="notificationConfigModal.contacts">
                        Ativar notificação de mensagens de contatos
                    </b-form-checkbox>
                    <b-form-checkbox class="hstack gap-2" v-model="notificationConfigModal.groups">
                        Ativar notificação de mensagens de grupos
                    </b-form-checkbox>
                </div>
                <div class="hstack gap-3">
                    <b-button variant="success" @click="updateOperatorNotificationConfig()">Salvar</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('notificationConfig')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="calendarEvent" ref="calendarEvent" body-class="p-4 text-bg-warning rounded position-relative" centered hide-header hide-footer>
            <b-icon class="position-absolute end-0 top-0 mt-3 me-3" font-scale="2em" @click="$bvModal.hide('calendarEvent')" icon="x" role="button"/>
            <div class="vstack align-items-center text-center gap-3" v-if="calendarEvent">
                <b-row class="m-0 p-3 d-flex w-100 bg-white rounded mt-4 shadow-sm" v-if="calendarEvent.contact">
                    <b-col cols="2" class="p-0 d-flex align-items-center flex-basis-content me-2">
                        <b-avatar class="bg-secondary text-white" size="3rem" :src="verifyPhotoURL(calendarEvent.contact.photoURL)"/>
                    </b-col>
                    <b-col class="p-0 d-flex align-items-center overflow-hidden">
                        <div class="d-flex flex-column ps-2 mw-100">
                            <div class="font-weight-bold text-secondary text-truncate position-relative w-min mw-100">
                                <span>
                                    {{ calendarEvent.contact.name || verifyPrivacy(calendarEvent.contact.number) }}
                                    &#8205; &#8205; &#8205; &#8205;
                                </span>
                            </div>
                            <div class="text-secondary text-truncate small-text lh-085" v-if="calendarEvent.contact.name != calendarEvent.contact.number">
                                {{ verifyPrivacy(formatNumber(calendarEvent.contact.number)) }}
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-icon font-size="5em" icon="bell" />
                <div class="vstack gap-3">
                    <div class="vstack gap-1">
                        <div class="fw-semibold">Descrição:</div>
                        {{ calendarEvent.description }}
                    </div>
                    <div class="vstack gap-1" v-if="calendarEvent.reminderMessage">
                        <div class="fw-semibold">Notificação:</div>
                        <span v-if="typeof calendarEvent.reminderMessage === 'string'">
                            {{ calendarEvent.reminderMessage }}
                        </span>
                        <Whatsapp :messages="[{ type: 'template', body: calendarEvent.reminderMessage, fromMe: true }]" :height="'10em'" :mediaURL="mediaURL" v-else />
                    </div>
                </div>
                <b-button class="bg-green3 border-green3 text-white" @click="goToAttendanceByContactId(calendarEvent.contactId)" :disabled="calendarEvent.timeToNotify" v-if="calendarEvent.notify">Ir para Atendimento</b-button>
                <b-button class="bg-green3 border-green3 text-white" @click="startAttendanceByContactId(calendarEvent.contactId)" v-else>Abrir Atendimento</b-button>
            </div>
        </b-modal>
        <b-modal id="createQuickAnswer" ref="createQuickAnswer" header-class="py-1" title="Criar Resposta Rápida" centered size="xl" hide-footer>
            <b-form class="text-purple" @submit.prevent="saveAnswer">
                <b-row class="m-0">
                    <b-col class="p-0 me-3">
                        <b-form-group label="Nome:" label-class="small-text">
                            <b-form-input v-model="quickAnswer.name" class="w-100 border-grey py-1" required/>
                        </b-form-group>
                    </b-col>
                    <b-col class="p-0">
                        <b-form-group label="Departamentos:" label-class="small-text">
                            <div class="position-relative">
                                <div class="border rounded w-100 text-secondary py-1 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseDepSelect>
                                    <span v-if="quickAnswer.departments && quickAnswer.departments.length">
                                        <span v-for="(dep,i) in quickAnswer.departments" :key="dep.id">
                                            <span>
                                                {{ depName(dep) }}<span v-if="i+1<quickAnswer.departments.length">,</span> 
                                            </span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        &nbsp;
                                    </span>
                                    <span>
                                        <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"/>
                                    </span>
                                </div>
                                <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class="mt-2 position-absolute bg-white border w-100 rounded" style="z-index: 1">
                                    <b-form-checkbox-group
                                        v-model="quickAnswer.departments"
                                        style="z-index: 1"
                                    >
                                        <div v-for="item in operator.department" :key="item">
                                            <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item"> 
                                                <div class="ms-1" role="button">{{ depName(item) }}</div>
                                            </b-form-checkbox>
                                        </div>
                                    </b-form-checkbox-group>
                                    <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect></div>
                                </b-collapse>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="m-0 mt-3">
                    <b-col class="p-0">
                        <b-form-group label="Tags:">
                            <b-form-tags 
                                class="inputKeywords border-grey mt-3" 
                                add-button-text="Adicionar"
                                add-button-variant="primary"
                                duplicate-tag-text="Tag duplicada"
                                limit-tags-text="Limite atingido: 5"
                                :limit="5"
                                tag-remove-label="Remover Tag"
                                tag-removed-label="Tag removida"
                                invalid-tag-text="Tag Inválida"
                                :tag-validator="tagValidator"
                                placeholder="Adicionar Tag"
                                v-model="quickAnswer.shortcuts"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="m-0 mt-3">
                    <b-col class="p-0">
                        <b-form-group class="position-relative" label="Conteúdo:">
                            <b-form-textarea
                                v-model="quickAnswer.content"
                                class="px-4 border-grey"
                                id="textarea"
                                rows="4"
                                no-resize
                                required
                            />
                            <b-button class="toggle-emoji" @click="toggleQuickAnswerDialogEmoji">
                                <b-icon class="toggle-emoji-icon" icon="emoji-smile" />
                            </b-button>
                            <VEmojiPicker class="quickAnswerEmojiPicker" @select="selectQuickAnswerEmoji" lang="pt-BR" v-show="showQuickAnswerDialog" :i18n="i18n" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="mt-4">
                    <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                    <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('createQuickAnswer')">Cancelar</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="modalContactInSurvey" ref="modalContactInSurvey" title="Contato em Pesquisa de Satisfação" body-class="p-0" header-class="py-1" hide-footer>
            <div class="vstack gap-3 p-3">
                <div class="vstack text-secondary">
                    <div class="fw-bold">
                        Este contato está em pesquisa de satisfação.
                    </div>
                    <div>
                        Deseja continuar e cancelar a pesquisa?
                    </div>
                </div>
                <div class="hstack gap-3">
                    <b-button variant="success" @click="startAttendance(startAttendanceParams.contact, startAttendanceParams.fromModal, startAttendanceParams.fromPickDepartment, true)" :disabled="startingAttendance">Continuar</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('modalContactInSurvey')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<!-- <script type="application/javascript" src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js"></script>
<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js"></script> -->

<script type="application/javascript" :src="`${window.location.protocol}//${window.location.host}/OpusMediaRecorder.umd.js`" />
<script type="application/javascript" :src="`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`" />

<script>
// import Map from './map.vue'
import Calendar from './calendar2.vue'
import { VEmojiPicker } from 'v-emoji-picker'
import Msgdt from './msgdt.vue'
import Msgforwarded from './msgforwarded.vue'
import Msgfromgroup from './msgfromgroup.vue'
import MsgReplied from './msgReplied.vue'
import MsgOptions from './msgOptions.vue'
import MsgCtwa from './msgCtwa.vue'
import axios from 'axios'
import { getToken, getTokenTimestamp, logout } from '../services/userService.js'
import api from '../services/apiService.js'
import utils from '../utils/utils'
import Whatsapp from './whatsapp.vue'
import OperatorCatalog from './operatorCatalog.vue'
import OperatorProducts from './operatorProducts.vue'
import countriesOptionsJSON from '../utils/countriesOptions.json'
import Steps from './steps.vue'
import pdf from 'vue-pdf'
import KanbanCards from './kanbanCards.vue'
import InternalChats from './internalChats.vue'
import InternalChat from './internalChat.vue'

// Choose desired format like audio/webm. Default is audio/ogg
// Web worker and .wasm configuration. Note: This is NOT a part of W3C standard.
const workerOptions = {
    encoderWorkerFactory: function () {
        return new Worker(`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`)
    },
    OggOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/OggOpusEncoder.wasm`,
    WebMOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/WebMOpusEncoder.wasm`
}

export default {
    components: {
        VEmojiPicker,
        // Map,
        Msgdt,
        MsgCtwa,
        Calendar,
        Msgforwarded,
        Msgfromgroup,
        MsgReplied,
        Whatsapp,
        MsgOptions,
        OperatorCatalog,
        OperatorProducts,
        Steps,
        pdf,
        KanbanCards,
        InternalChats,
        InternalChat
    },
    props: [
        'socket', 'operator', 'user', 'i18n' , 'timestampDiff', 'mediaURL'
    ],
    created() {
        const scripts = [
            `${window.location.protocol}//${window.location.host}/OpusMediaRecorder.umd.js`,
            `${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`
        ]
        scripts.forEach(script => {
            const tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        })
    },
    mounted: function() {
        this.$nextTick(function() {
            this.init()

            if(this.$route.query && this.$route.query.t) {
                const query = Object.assign({}, this.$route.query);
                delete query.t;
                this.$router.replace({ query });
            }
            const timestampInterval = setInterval(() => {
                if(this.attendances) {
                    this.formatTimestamp()
                }
                if(!this.operator.channelConfig?.modules?.operatorAttendanceTimer)
                    clearInterval(timestampInterval)
            },1000)

            const msgsDiv = document.querySelector('.msgsdiv')
            if(msgsDiv)
                msgsDiv.onscroll = () => {this.scrollFunction()};
            // this.sticky(scrollButton)

            

            this.verifyOperatorHasGroups()
            this.windowEvents()
            this.socketOnEvents()
        })

        this.rootOnEvents()
    },
    unmounted() {
        document.removeEventListener("visibilitychange")
        window.removeEventListener("resize", () => {
            this.calculateScreenHeightAttendancesCount()
        })
    },
    watch: {
        "attendances": {
            handler(newAttendances) {
                if(this.attendanceType === "contacts") {
                    const atts = [...this.attendances]
                    const sortedAttendances = atts.sort((a, b) => b.lastMessage - a.lastMessage)
                    const unique = [
                        ...new Map(sortedAttendances.map((item) => [item.clientNumber, item])).values(),
                    ]
                    if(unique?.length != newAttendances?.length) {
                        this.attendances = unique
                    }
                }
            }
        },
        "socket": {
            handler() {
                this.socketOnEvents()
            }
        },
        "attendancesLength": {
            handler(length) {
                this.$root.$emit('attendances_count', length)
            }
        },
        "attendancesUnreadCount": {
            handler(count) {
                document.title = `(${count + this.groupsUnreadCount}) ${this.documentTitle}`
            }
        },
        "groupsUnreadCount": {
            handler(count) {
                document.title = `(${count + this.attendancesUnreadCount}) ${this.documentTitle}`
            }
        },
    },
    computed: {
        containerHeight() {
            const dvhSupported = window.CSS && window.CSS.supports('height', '100dvh');
            return dvhSupported
            ? `calc(100dvh - ${this.headerHeight}px)`
            : `calc(100vh - ${this.headerHeight}px)`;
        },
        searchPlaceholder() {
            if (this.attendanceType === "contacts") {
                return "Buscar Contato ou Número"
            } else if (this.attendanceType === "groups") {
                return "Buscar Grupo"
            } else {
                return "Buscar Operador"
            }
        }
    },
    methods: {
        async init() {
            await this.getConfigFile()
            //this.getHSMModels()
            this.getHeaderHeight()
            // this.getAttendances()
            this.getAttendances2(1)
            this.getDepartments()
            this.getAttendancesStatus()
            this.setBrowser()
            this.onCloseModal()
            this.getAttendanceQuantity()
            this.getOperatorGroupsQuantity()
            this.getAllTags()
            this.getQuickAnswers()
            this.channelHasSecondaryChannels()
            this.setCountryOptions()
        },
        openCreateCalendar(data) {
            this.createCalendar=!this.createCalendar,this.activeCalendar=!this.activeCalendar
            data.date.setHours(data.date.getHours() - 3)
            this.saveCalendar.date = data.date.toISOString().split('T')[0]
            this.saveCalendar.time = data.date.toISOString().split('T')[1].split('-')[0].split('.')[0].slice(0, -3)
        },
        async emitMSG(success=false,text="Verifique os campos digitados e tente novamente!") {
            this.$emit('msg',{success,text})
        },
        async createCalendarOP() {
            if(this.saveCalendar.notify) {
                this.saveCalendar.notify = true
            } else {
                this.saveCalendar.notify = false
            }
            const data = {
                channelId: this.operator.channel._id,
                contactId: this.clientSelected.contactId||this.clientSelected._id,
                operatorId: this.operator._id,
                notify:this.saveCalendar.notify,
                color:this.saveCalendar.color,
                schedulingDate:this.saveCalendar.date+'T'+this.saveCalendar.time,
                reminderMessage:this.saveCalendar.reminderMessage,
                description:this.saveCalendar.description,
                apiType:this.operator.channel.apiType,
                timeToNotify:this.saveCalendar.timeToNotify
            }
            const token = await getToken()
            try {
                const createResp = await api.createCalendar(data,token)
                this.$emit("msg",{text:"Agendamento criado com sucesso",success:true})
                this.saveCalendar = { color: "#000", timeToNotify: 0 }
                this.clientSelected = { country: "" }
                this.createCalendar = !this.createCalendar
            } catch(error) {
                console.error(error)
                this.$emit("msg", {
                    text: "Verifique os campos e tente novamente!",
                    success: false
                })
            }
            //this.saveCalendar = {}
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                this.forward = { type: '' }
                this.templates = []
                this.retrieveContactsSearch = ''
                this.retrieveContacts = []
                this.retrieveContactsPage = 0
                
                this.selectedProductList = null
                this.selectedProductCart = null
                this.retrieveTabIndex = 0
                
                this.selectedTemplate = {}

                if(modalId === "modalRetrievePickTemplate") {
                    if(this.$refs["modalRetrieveContact"])
                        this.$refs["modalRetrieveContact"].hide()
                }

                if(modalId !== "modalPickDepartmentRetrieve") {
                    this.departmentSelected = null
                }

                if(modalId !== "modalPickChannelRetrieve") {
                    this.channelSelected = null
                }

                if(modalId !== "modalPickDepartmentRetrieve" && modalId !== "modalPickChannelRetrieve" && modalId !== "modalRetrievePickTemplate") {
                    this.forwardMessage = null
                    this.contactsToForward = []
                }

                if(modalId === "rdCreateOrganization") {
                    this.newRDOrganization = {}
                }

                if(modalId === "rdCreateAnnotation") {
                    this.newRDAnnotation = {}
                }

                if(modalId === "rdCreateDeal") {
                    this.newRDDeal = {
                        deal: {},
                        organization: {},
                    }
                }

                if(modalId === "rdCreateTask") {
                    this.rdNewTask = {
                        type: 'task'
                    }
                }

                if(modalId === "rdUpdateTask") {
                    this.rdSelectedTask = null
                }

                if(modalId === "calendarEvent") {
                    this.calendarEvent = null
                }

                if(modalId !== "calendarEvent" && modalId !== "forwardMessagePickContacts") {
                    this.contactSelected = null
                }

                if(modalId === "newScheduling") {
                    this.clientSelected = { msgs: [] }
                    this.saveCalendar = { color: "#000", timeToNotify: 0 }
                }
            })
        },
        sticky(_el) {
            _el.parentElement.addEventListener("scroll", function() {
                _el.style.transform = "translateY("+this.scrollTop+"px)"
            })
        },
        scrollFunction() { // hides button when div scroll is more than 75% of the div
            const button = document.querySelector(".msgsScrollButton")
            const msgsDiv = document.querySelector('.msgsdiv')
            const height = msgsDiv.scrollHeight / 2
            if (msgsDiv.scrollTop > (1 * height)) {
                if(button)
                    button.style.display = "none"
            } else {
                if(button)
                    button.style.display = "flex"
            }
        },
        scrollBottomMsgsDiv() {
            const msgsdiv = document.querySelector('.msgsdiv')
            if(msgsdiv) {
                msgsdiv.scrollTo(0, msgsdiv.scrollHeight)
            }
        },
        scrollTopMsgsDiv() {
            const msgsdiv = document.querySelector('.msgsdiv')
            if (msgsdiv) {
                msgsdiv.scrollTo(0, 0)
            }
        },
        async recAudio() {
            if(this.clientSelected._id && this.attendanceWindowIsOpened) {
                // if(this.browser!='Firefox') {
                //     return this.$emit("msg", {
                //         text: "Este recurso só está disponível no navegador Firefox!",
                //         success: false
                //     })
                // }
                window.MediaRecorder = OpusMediaRecorder
                
                navigator.mediaDevices.getUserMedia({ audio:true }).then(stream => {
                    this.recorder = true
                    let totalSeconds = 0
                    let seconds = 0
                    let minutes = 0

                    const pad = val => (val + "").padStart(2, "0")

                    this.recordInterval = setInterval(() => {
                        ++totalSeconds;
                        seconds = pad(totalSeconds % 60)
                        minutes = pad(parseInt(totalSeconds / 60))
                        this.recordingTime = minutes+':'+seconds
                    }, 1000)

                    this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/ogg;codecs=opus' }, workerOptions)
                    this.chunks = []
                    this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data)
                    this.mediaRecorder.onerror = console.error
                    this.mediaRecorder.start(100)
                })
                .catch((error) => {
                    console.log('record', error)
                    switch (error.name) {
                        case 'NotFoundError':
                            this.$emit('msg', {
                                text: 'Nenhum microfone encontrado',
                                success: false
                            })
                            break
                        case 'NotAllowedError':
                        case 'SecurityError':
                            this.$emit('msg', {
                                text: 'Acesso ao microfone negado.\nPor favor, verifique as permissões do navegador',
                                success: false
                            })
                            break
                    }
                })
            }
        },
        confirmAudio() {
            clearInterval(this.recordInterval)
            this.recordingTime = ''

            this.mediaRecorder.stop()
            this.mediaRecorder.stream.getTracks().forEach(i => i.stop())

            this.recorder = false

            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.chunks, { 'type': this.mediaRecorder.mimeType || this.mediaRecorder._mimeType })
                const file = new File([blob], `audio.${this.audioExtension}`,{ type: blob.type })
                this.recordedMedia.file = file

                this.recordedMedia.url = URL.createObjectURL(blob)
            }
        },
        playAudio(url) {
            const audio = new Audio(url)
            audio.play()
        },
        stopAudio() {
            this.recordedMedia = { url:null, file:null }
        },
        async sendAudio() {
            if(this.sendingAudio) return
            if(this.attendanceType == 'contacts') {
                if(!this.clientSelected || !this.clientSelected._id || this.clientSelected.status=='finished' || this.clientSelected.status=='abandoned' || this.clientSelected.operatorId != this.operator._id || !this.attendanceWindowIsOpened)
                    return
            } else if(this.attendanceType == 'groups') {
                if(!this.clientSelected || !this.clientSelected.from)
                    return
            }
            this.sendingAudio = true

            try {
                const formData = new FormData()
                this.recordedMedia.file.agent = window.navigator.vendor
                console.log('file',this.recordedMedia.file)
                formData.append('file', this.recordedMedia.file);
                const token = getToken()
                const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile':'audio', 'clientNumber':this.clientSelected.clientNumber, 'authorization':token, 'from':'enviadas'}
                const uploadResp = await api.upload(formData,headers)
                // console.log('uploadResp',uploadResp)
                // const filepath = resp.data.files['audio'].filepath.split('upload-files/')[1]
                const url = uploadResp.file.uploadDir
                const obj = {
                    attendanceId: this.clientSelected._id, 
                    url, 
                    type: 'audio',
                    caption: '', 
                    filename: `audio${Date.now()}`,
                    clientNumber: this.clientSelected.clientNumber,
                    channelId: this.clientSelected.channel || this.clientSelected.channelId,
                    groupId: this.clientSelected._id,
                    operatorId: this.operator._id,
                    name: this.operator.name
                }
                // if(this.socket.active) {
                    if(this.attendanceType == 'contacts')
                        this.socket.emit('send_message',obj)
                    else if(this.attendanceType == 'groups') {
                        obj.isGroup = true
                        this.socket.emit('send_message_group',obj)
                    }
                // } 
                // else {
                    // const a = []
                    // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                    //     a = JSON.parse(localStorage.getItem('msgs'));
                    // a.push(obj)
                    // localStorage.setItem('msgs', JSON.stringify(a));
                // }
                const msg = {
                    body: '',
                    fromMe: true,
                    timestamp: Date.now()/1000,
                    type: 'audio',
                    mediaURL: this.recordedMedia.url
                }
                this.clientSelected.msgs.push(msg)
                this.recordedMedia = {
                    url: null,
                    file: null
                }

                const index = this.attendances.findIndex(({ _id }) => _id === this.clientSelected._id)
                if (index !== -1) {
                    this.attendances.splice(index, 1)
                    this.attendances.unshift({...this.clientSelected})
                }

                // this.refreshClientSelected()
                this.scrollBottomMsgsDiv()
                this.sendingAudio = false
            } catch(error) {
                console.error(error)
                this.$emit('msg', { text: 'Ocorreu um erro ao enviar o audio. Tente novamente.', success: false })
                this.sendingAudio = false
            }
        },
        async sendMSG(text = null) {
            if(this.attendanceType == 'contacts') {
                if(!this.clientSelected || !this.clientSelected._id || this.clientSelected.status=='finished' || this.clientSelected.status=='abandoned' || this.clientSelected.operatorId != this.operator._id || !this.attendanceWindowIsOpened)
                    return
            } else if(this.attendanceType == 'groups') {
                if(!this.clientSelected || !this.clientSelected.from)
                    return
            }

            if(!text) {
                if (this.inputSend.startsWith('/')) {
                    if (this.quickAnswers?.length) {
                        const quickAnswer = this.quickAnswers.find(el => el.shortcuts.includes(this.inputSend.substring(1)))
                        if (quickAnswer)
                            text = quickAnswer.content
                        else
                            text = this.inputSend
                    } else
                        text = this.inputSend
                } else
                    text = this.inputSend

                if (!text) this.editMessage = {}
            }

            if(text?.length > 0) {
                if (this.editMessage.body) {
                    this.socket.emit('updating_message', { 
                        attendanceId: this.clientSelected._id,
                        channelId: this.clientSelected.channel || this.clientSelected.channelId,
                        operatorId: this.clientSelected.operatorId,
                        phone: this.editMessage.phone, 
                        body: text, 
                        key: this.editMessage.key
                    })

                    this.editMessage = {}
                    this.inputSend = ''
                    return
                }
                
                const quotedMsg = Object.keys(this.replyMessage).length === 0 ? undefined : this.replyMessage
                const obj = {
                    attendanceId: this.clientSelected._id,
                    msg: text,
                    type: 'text',
                    clientNumber: this.clientSelected.clientNumber,
                    channelId: this.clientSelected.channel || this.clientSelected.channelId,
                    groupId: this.clientSelected._id,
                    operatorId: this.operator._id,
                    quotedMsg,
                    name: this.operator.name // Nome do operador caso seja grupo
                }
                // if(this.socket.active)
                    if(this.attendanceType == 'contacts') {
                        obj.groupId = null
                        this.socket.emit('send_message',obj)
                    } else if(this.attendanceType == 'groups') {
                        obj.isGroup = true
                        this.socket.emit('send_message_group',obj)
                    }
                // else {
                    // let a = []
                    // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                    //     a = JSON.parse(localStorage.getItem('msgs'));
                    // a.push(obj)
                    // localStorage.setItem('msgs', JSON.stringify(a));
                // }
                // console.log(JSON.parse(localStorage.getItem('msgs')))

                const aux = new Date()
                const date = aux.toLocaleDateString('en-US')
                
                const timestamp = Math.floor(aux.getTime() / 1000) + Math.floor(aux.getTimezoneOffset() ? aux.getTimezoneOffset() / 12 : 0)

                if(!this.clientSelected.msgs.find(el => el.date === date)) {
                    this.clientSelected.msgs.push({ type: 'date', timestamp: timestamp - 1, date })
                }

                const msg = {
                    body: text,
                    fromMe: true,
                    timestamp,
                    statusAck: 0,
                    type: 'text',
                    quotedMsg
                }
                this.clientSelected.msgs.push(msg)

                const chat = this.attendances.find(e => e._id === this.clientSelected._id)
                if (chat?.msgs) {
                    chat.lastMsg = msg
                }

                setTimeout(() => {
                    if (this.clientSelected.msgs.slice(-5).find(el => !el.statusAck))
                        this.loadMessages()
                }, this.resentTime * 1000)

                if(this.replyMessage)
                    this.replyMessage = {}
                // console.log('msg',msg)
                this.inputSend = ''

                const index = this.attendances.findIndex(({ _id }) => _id === this.clientSelected._id)
                if (index !== -1) {
                    this.attendances.splice(index, 1)
                    this.attendances.unshift({...this.clientSelected})
                }

                this.clientSelected.newMsgs = 0
                setTimeout(() => {
                    this.scrollBottomMsgsDiv()
                }, 500)
            }
            this.hideQuickAnswersDiv()
        },
        async sendMsg2(attendance, message) {
            if(attendance) {
                // console.log({ attendance,message })

                const operatorNickname = `*${this.operator.nickname || this.operator.name}*: `
                if(message.body?.startsWith(operatorNickname)) {
                    message.body = message.body.replace(operatorNickname,'')
                }

                let filename

                if (message.type !== 'chat' && message.type !== 'text') {
                    filename = message.mediaURL.split('/').pop().split('=').at(-1)
                }

                message.quotedMsg = null
                const obj = {
                    attendanceId: attendance._id,
                    msg: message.body,
                    clientNumber: attendance.clientNumber,
                    channelId: attendance.channel || attendance.channelId,
                    groupId: attendance._id,
                    operatorId: this.operator._id,
                    name: this.operator.name, // Nome do operador caso seja grupo
                    url: message.mediaURL,
                    caption: message.body,
                    filename,
                    ...message
                }
    
                this.socket.emit('send_message',obj)
            } else {
                console.error("attendance is missing!")
            }
        },
        setInputMedia(arrayMedia) {
            if(arrayMedia?.length) {
                arrayMedia.forEach(e => {
                    console.log('file',e)
                    
                    e = new File([e], utils.replaceDiacritics(decodeURIComponent(`${e.name.replace(/\.jfif$/, '.jpeg')}`)), {
                        type: e.type 
                    })

                    if (e.size > 15000000) {
                        const input = document.querySelector('.inputFileForm')

                        if (input)
                            input.reset()

                        return this.$emit("msg", {
                            text: "O tamanho máximo permitido para envios é de 15 MB",
                            success: false
                        })
                    }
                    if(e.type == 'video/x-matroska' || e.type == 'image/webp' || !e.type) {
                        const input = document.querySelector('.inputFileForm')

                        if (input)
                            input.reset()

                        return this.$emit("msg", {
                            text: 'Extensão não suportada!',
                            success: false
                        })
                    }               

                    const ext = e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    if(this.containsUppercase(ext)) {
                        e = new File([e],e.name.replace(ext,ext.toLowerCase()), { type: e.type })
                    }

                    this.inputSend = ''
                    this.currentMedia = e
                    // this.file = []
                    this.file.push(e)
                    const reader = new FileReader()
                    reader.readAsDataURL(e)
                    reader.onload = () => {
                        const dataURL = reader.result
                        // this.mediaToSend = []
                        let type = e['type'].split('/')[0]
                        if(type == "text") {
                            type = "document"
                        }
                        this.mediaToSend.push({url:dataURL.replace('base64','charset=utf-8;base64'),type,name:e['name'].replace('.jfif','.jpeg')})
                    }
                })
            }
        },
        containsUppercase(str) {
            return /^[A-Z]+$/.test(str)
        },
        setCaptionMedia(e) {
            this.currentMedia.caption = e
        },
        async sendInputMedia() {
            if(this.attendanceType == 'contacts') {
                if(!this.clientSelected || !this.clientSelected._id || this.clientSelected.status=='finished' || this.clientSelected.status=='abandoned' || this.clientSelected.operatorId != this.operator._id)
                    return
            } else if(this.attendanceType == 'groups') {
                if(!this.clientSelected || !this.clientSelected.from)
                    return
            }
            if(this.file?.length > 0) {
                let count = 0
                this.inputMediaOverlay = true
                await this.file.forEach(async (file) => {
                    const formData = new FormData();
                    const message = { body:'', mediaUrl:'' }
                    if(file.caption)
                        message.body = file.caption
                    let typefile = file['type'].split('/')[0]
                    if(typefile == 'text')
                        typefile = 'file'
                    // if(type=='application') {
                    //     type = 'document'
                    // }
                    const media = this.mediaToSend.find(el => el['name'] == file.name)
                    const ext = file.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    const msg = {
                        body: file.caption,
                        fromMe: true,
                        timestamp: Date.now()/1000,
                        type: media.type,
                        mediaURL: media.url,
                        caption: message.body,
                        filename: file.name,
                        ext
                    }
                    file.filename = utils.replaceDiacritics(decodeURIComponent(file.name))
                    console.log({file})
                    formData.append('file',file)
                    const headers = { 'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'clientNumber': this.clientSelected.clientNumber }
                    const uploadResp = await api.upload(formData,headers)
                    console.log('uploadResp',uploadResp)
                    
                    if(uploadResp.statusCode != 200) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false

                        await this.toggleInsertFile()

                        return this.$emit("msg", {
                            text: "Ocorreu um erro! Tente novamente mais tarde ou relogue!",
                            success: false,
                            countdown: 8
                        })
                    }

                    msg.mediaURL = uploadResp.file.uploadDir

                    this.clientSelected.msgs.push(msg)

                    const index = this.attendances.findIndex(({ _id }) => _id === this.clientSelected._id)
                    if (index !== -1) {
                        this.attendances.splice(index, 1)
                        this.attendances.unshift({...this.clientSelected})
                    }

                    const url = uploadResp.file.uploadDir
                    const obj = {
                        attendanceId: this.clientSelected._id, 
                        url, 
                        type: typefile, 
                        caption: message.body, 
                        filename: file['name'].split('.')[0],
                        clientNumber: this.clientSelected.clientNumber,
                        channelId: this.clientSelected.channel || this.clientSelected.channelId,
                        operatorId: this.operator._id,
                        groupId: this.clientSelected._id,
                        name: this.operator.name
                    }
                    // if(this.socket.active) {
                        if(this.attendanceType == 'contacts')
                            this.socket.emit('send_message',obj)
                        else if(this.attendanceType == 'groups') {
                            obj.isGroup = true
                            this.socket.emit('send_message_group',obj)
                        }
                    // } else {
                        // let a = []
                        // if(JSON.parse(localStorage.getItem('msgs')) && JSON.parse(localStorage.getItem('msgs')).length)
                        //     a = JSON.parse(localStorage.getItem('msgs'));
                        // a.push(obj)
                        // localStorage.setItem('msgs', JSON.stringify(a));
                    // }
                    count++
                    if(count == this.file.length) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false
                        await this.toggleInsertFile()
                        this.scrollBottomMsgsDiv()
                    }
                })
            }
        },
        async loadMessages(client, dontScrollBottom = false) {
            if (!client) client = this.clientSelected
            if(client.from) return

            const resp = await api.loadMessages({
                attendance: {
                    _id: client._id,
                    clientNumber: client.clientNumber,
                    channelId: client.channel,
                },
                operatorId: this.operator._id,
            })

            if (resp.statusCode !== 200) {
                this.$emit('msg', {
                    text: 'Ocorreu um erro ao carregar as mensagens!',
                    success: false,
                })
                return
            }

            const {
                messages: { messages, _id },
                attendance
            } = resp

            client.status = attendance.status
            client.operatorId = attendance.operatorId
            client.operatorName = attendance.operatorName
            client.photoURL = attendance.photoURL

            const msgs = []
            let dateAux = new Date(1).toLocaleDateString('en-US')

            for (const el of messages) {
                if (this.timestampDiff > 100) el.timestamp -= this.timestampDiff

                if (el.timestamp > 9999999999) el.timestamp /= 1000

                const date = new Date(el.timestamp * 1000).toLocaleDateString('en-US')

                if (new Date(date) > new Date(dateAux)) {
                    const d = { type: 'date', timestamp: el.timestamp - 1, date }
                    msgs.push(d)
                    dateAux = date
                }

                if (el.type === 'vcard') {
                    if (!el.vcardName)
                        el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                } else   if (el.type === 'vcardArray') {
                    el.contacts = []
                    for (const index in el.body) {
                        const obj = {
                            vcardName: el.body[index].split('FN:')[1].split('\n')[0],
                            vcardNumber: el.body[index].split('waid=')[1].split(':')[0]
                        }
                        el.contacts[index] = obj
                    }
                } else if(el.type === 'product_list') {
                    const product = await this.getProductByProductId(el.body?.productList?.at(0)?.productId)
                    if(product) {
                        el.body.imageURL = product.imageLink
                    }
                }

                if(el.mediaURL) {
                    try {
                        el.mediaURL = await this.getMediaURL(this.hasFullPath(el.mediaURL))
                    } catch(error) {
                        console.error(error)
                    }
                }

                if(el.quotedMsg?.mediaURL) {
                    try {
                        el.quotedMsg.mediaURL = await this.getMediaURL(this.hasFullPath(el.quotedMsg.mediaURL))
                    } catch(error) {
                        console.error(error)
                    }
                }

                msgs.push(el)
            }
            
            if(!this.attendanceWindowIsOpened) {
                msgs.push({
                    type: "closedWindow",
                    text: "Aguarde a resposta do contato!"
                })
            }
            
            this.$set(client, 'msgs', msgs)

            client.modelId = _id;

            this.refreshClient(client)

            if (!dontScrollBottom)
                this.scrollBottomMsgsDiv();
        },
        async loadGroupMessages(group) {
            if(!group) group = this.clientSelected
            // const resp = await api.loadGroupMessages({
            //     groupId: group._id,
            // })
            const resp = await api.loadGroupMessagesWeek({
                groupId: group._id,
            })
            // console.log('group messages', resp)
            if(resp.statusCode != 200) {
                console.error('load_messages_group err',resp)
                this.$emit("msg", {
                    text: 'Ocorreu um erro ao carregar as mensagens!',
                    success: false
                })
                return
            }

            const msgs = []

            let dateAux = new Date(1).toLocaleDateString('en-US')
            const messages = resp.messages?.messages || resp.messages

            for (const el of messages) {
                if(this.timestampDiff > 100)
                    el.timestamp -= this.timestampDiff
                const date = new Date(el.timestamp*1000).toLocaleDateString('en-US')
                if(new Date(date) > new Date(dateAux)) {
                    const d = { type: 'date', timestamp: el.timestamp-1, date }
                    msgs.push(d)
                    dateAux = date
                }

                if(el.type === "vcard") {
                    if(!el.vcardName)
                        el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                } else   if (el.type === 'vcardArray') {
                    el.contacts = []
                    for (const index in el.body) {
                        const obj = {
                            vcardName: el.body[index].split('FN:')[1].split('\n')[0],
                            vcardNumber: el.body[index].split('waid=')[1].split(':')[0]
                        }
                        el.contacts[index] = obj
                    }
                }

                if(el.mediaURL) {
                    try {
                        el.mediaURL = await this.getMediaURL(this.hasFullPath(el.mediaURL))
                    } catch(error) {
                        console.error(error)
                    }
                }

                msgs.push(el)
            }

            this.$set(group, 'msgs', msgs)

            group.modelId = resp.messages._id

            group.newMsgs = 1
            group.newMsgs = 0

            this.scrollBottomMsgsDiv()
        },
        async getMediaURL(url) {
            if(url) {
                if(url.includes('medias2/dev'))
                    url = url.replace('medias2/dev', 'medias2/api4')
                const f = await fetch(url).then(() => {
                    return url
                }).catch(async () => {
                    if(this.mediaURL != "https://storage.gotalk.digital/") {
                        // Try the other URL
                        url = url.replace(this.mediaURL,"https://storage.gotalk.digital/");
                        const f = await fetch(url)
                        .then(() => {
                            return url
                        })
                        return f
                    } else
                        return url
                });
                return f
            }
            return url
        },
        async selectClient(client, fromCalendar = false) {
            this.attendanceWindowIsOpened = await this.checkAttendanceWindowIsOpened(client)

            if (client.status === 'offline_operators' && !this.attendanceWindowIsOpened) {
                this.$emit('msg', {
                    text: 'A janela de atendimento está fechada.',
                    success: false,
                })

                client.completionReason = 'Atendimento finalizado por inatividade.'
                client.status = 'abandoned'
                return this.finishAttendance(client, true)
            }

            const previousClient = Object.assign({}, this.clientSelected)

            if (!fromCalendar) {            
                if(this.inputSend && previousClient?._id) {
                    sessionStorage.setItem(`inputSend${previousClient.clientNumber}`, this.inputSend)
                    this.clientSelected.draft = this.inputSend
                }
    
                if(client.status == 'validate') {
                    return this.$emit('msg', {
                        text: 'Este contato não possui atendimentos!',
                        success: false,
                    })
                }
    
                if(this.showCrmData)
                    this.toggleCrmData()
            }

            
            this.replyMessage = {}
            this.recordedMedia = { url: null, file: null }
            this.clientSelected = client
            const state = client.state
            const cardKanbanId = client.cardKanbanId
            const kanbanSteps = client.kanbanSteps
            const kanbanStepId = client.kanbanStepId
            const kanbanCard = client.kanbanCard

            this.protocolsCount = 1
            
            this.clientData = false

            if (!this.operator._id) {
                console.error('load_messages err', this.operator)
            }

            this.setWindowRemainingTime()

            if (!fromCalendar) {
                this.loadingMessages = true
    
                if (this.attendanceType === 'contacts') {
                    const channelConfig = this.operator.channelConfig
                    if (channelConfig?.modules?.sharedQueue && (client.status === 'waiting' || client.status === 'offline_operators') && !client.operatorId) {
                        this.socket.emit('attendance_picked', {
                            attendanceId: client._id,
                            operatorId: this.operator._id,
                            channelId: this.operator.channelId
                        })
                    }
    
                    if (client.newMsgs) {
                        this.attendancesUnreadCount -= client.newMsgs
                        client.newMsgs = 0
                    }
    
                    await this.loadMessages(client)
                } else if (this.attendanceType === 'groups') {
                    if (client.newMsgs) 
                        this.groupsUnreadCount -= client.newMsgs
    
                    await this.loadGroupMessages(client)
                }
                console.log('selectClient msgs', this.clientSelected.msgs)
                this.scrollBottomMsgsDiv()

                this.clientSelected = { ...this.clientSelected }
                
                this.loadingMessages = false
                
                this.recorder = false
                this.insertFile = false
                this.mediaToSend = []
                this.currentMedia = null
                this.file = []
                this.protocolsCount = 1

                const inputSendStorage = sessionStorage.getItem(`inputSend${this.clientSelected.clientNumber}`)
                if(inputSendStorage) {
                    this.inputSend = inputSendStorage
                    sessionStorage.removeItem(`inputSend${this.clientSelected.clientNumber}`)
                } else {
                    this.inputSend = ''
                }
            }

            const channelType = await this.getChannelType(this.clientSelected.channel || this.clientSelected.channelId)
            this.$set(this.clientSelected, 'channelType', channelType)
            this.refreshClientSelected()

            this.getClientData()
            this.getAllTags()
            this.getAttendanceQuantity()
            this.getAttendancesStatus()

            this.clientSelected.state = state
            this.clientSelected.cardKanbanId = cardKanbanId
            this.clientSelected.kanbanSteps = kanbanSteps
            this.clientSelected.kanbanStepId = kanbanStepId
            this.clientSelected.kanbanCard = kanbanCard
            
            if(window.innerWidth <= 425) {
                const col1 = document.getElementById('col1')
                if(col1)
                    col1.style.flex = '0'

                const col2 = document.getElementById('col2')
                if(col2)
                    col2.style.flex = '1'

                const mbButtonCol2 = document.getElementById('mobile-back-button')
                if(mbButtonCol2)
                    mbButtonCol2.style.display = 'inline-block'
            }
        },
        hideMessages() {
            if(window.innerWidth <= 425) {
                const col1 = document.getElementById('col1')
                if(col1)
                    col1.style.flex = '1'

                const col2 = document.getElementById('col2')
                if(col2)
                    col2.style.flex = '0'
            }
            this.clientSelected = { country: "" }
        },
        hideClientData() {
            if(window.innerWidth <= 425) {
                //this.hideMessages()
                const col1 = document.getElementById('col1')
                if(col1)
                    col1.style.flex = '0'  

                const col2 = document.getElementById('col2')
                if(col2)
                    col2.style.flex = '1'

                this.clientData = !this.clientData

                const col3 = document.getElementById('col3')
                if(col3) {
                    col3.style.flex = '0'
                    col3.style.display = 'none'
                }
            }
        },
        selectMedia(media) {
            this.selectedMedia = media;
        },
        toggleAttSearch() {
            this.attsearch = !this.attsearch
        },
        toggleSearchInAtt() {
            this.searchinatt = !this.searchinatt
        },
        toggleInsertFile(param=null) {
            this.insertFile = !this.insertFile
            this.mediaToSend = []
            this.file = []
            this.inputSend = ''
            this.currentMedia = null
            if(param)
                this.fileParam = param
        },
        toggleDialogEmoji() {
            if(this.attendanceWindowIsOpened)
                this.showEmojiDialog = !this.showEmojiDialog;
        },
        toggleDialogReactEmoji(item) {
            if(this.attendanceWindowIsOpened) {
                if(!item.showReactEmojiDialog) item.showReactEmojiDialog = true
                else item.showReactEmojiDialog = false
                this.$set(item,"body",item.body+"a")
                this.$set(item,"body",item.body.slice(0,-1))
                this.selectedItemEmoji = item
                const msgs = document.querySelector('.msgsScroll')

                if (msgs) {
                    this.msgsHeight = `${msgs.clientHeight}px`
                }

                setTimeout(() => {
                    const el = document.querySelector(".inputReactEmojiPicker")
                    if(el)
                        el.scrollIntoView()
                }, 200);
            }
        },
        selectEmoji(emoji) {
            this.inputSend += emoji.data
            if(this.currentMedia)
                this.currentMedia.caption += emoji.data
            this.toggleDialogEmoji()
        },
        async selectReactEmoji(emoji) {
            const msg = this.selectedItemEmoji
            this.toggleDialogReactEmoji(this.selectedItemEmoji)
            const channelResp = await api.getChannel(this.operator.channelId)
            if(channelResp.statusCode == 200) {
                const obj = {
                    modelId: this.clientSelected.modelId,
                    idWpp: msg.idWpp,
                    fromMe: msg.fromMe,
                    from: channelResp.channel.channel.channelNumber,
                    emoji: emoji.data,
                    to: this.clientSelected.clientNumber || this.clientSelected.from,
                    isGroup: this.clientSelected.from ? true : false,
                    body: msg.body,
                    name: this.operator.name,
                }
                // console.log('obj',obj)
                this.socket.emit('send_message_reaction', obj)
            }
        },
        toggleQuickAnswerDialogEmoji() {
            if(this.attendanceWindowIsOpened)
                this.showQuickAnswerDialog = !this.showQuickAnswerDialog
        },
        selectQuickAnswerEmoji(emoji) {
            if(!this.quickAnswer.content)
                this.quickAnswer.content = ""
            this.quickAnswer.content += emoji.data
            this.toggleQuickAnswerDialogEmoji()
        },
        scrollRight() {
            document.querySelector('.navwrapper').scrollLeft += 226;
        },
        scrollLeft() {
            document.querySelector('.navwrapper').scrollLeft -= 226;
        },
        scrollInputMediaRight() {
            document.querySelector('.inputMediaArray').scrollLeft += 226;
        },
        scrollInputMediaLeft() {
            document.querySelector('.inputMediaArray').scrollLeft -= 226;
        },
        appendToAttendances(item) {
            const attendance = { 
                msgs: []
            }
            attendance.clientName = item.vcardName?.trim()
            // let found = this.attendances.find(att => att.clientNumber == attendance.clientNumber)
            // if(!found)
                this.attendances.unshift(attendance)
        },
        getDate(t,args) {
            if(args)
                return new Date(t*1000).toLocaleDateString("pt-BR").split('/').reverse().join('-')
            return new Date(t).toLocaleDateString("pt-BR").split('/').reverse().join('-')
        },
        hasFullPath(url) {
            if(url && (url.startsWith('http') || url.startsWith('data:') || url.startsWith('blob:'))) return url
            else {
                const split = url.split('/')
                if(split?.at(2)?.length == 3) // verify if the position 2 (usually it is the channel id) has length of 3, then it means the media comes from Notifique
                    return this.mediaURL+url
            }
            return this.mediaURL+url
        },
        async getTabulations() {
            const resp = await api.getTabulations(this.operator.channelId)
            // console.log('resp tabulations', resp)
            if(resp.statusCode == 200)
                this.tabulations = resp.tabulations.sort((a, b) => a.text.localeCompare(b.text))
            // else {
                // this.$emit("msg", {
                //     text: 'Ocorreu um erro ao carregar Tabulações!',
                //     success: false
                // })
            // }
        },
        async finishAttendance(attendance,fromModal=false) {
            if(!fromModal) {
                await this.getTabulations()
                if(this.tabulations?.length > 0)
                    return this.$bvModal.show('modal-tabulations')
                this.socket.emit('finish_attendance',{ attendance, reason: attendance.completionReason })
                this.attendances = this.attendances.filter(function(el) { return el._id != attendance._id; });
                this.clientSelected = {}
                this.clientData = false

                this.finishedAttendanceCount++
                this.getAttendanceQuantity()
                this.getAttendancesStatus()
                
            } else {
                if(!attendance.completionReason) {
                    return this.$emit("msg", {
                        text: 'Tabulação não selecionada!',
                        success: false
                    })
                }
                // console.log('attendance',attendance)
                this.$refs['modal-tabulations'].hide()
                this.attendances = this.attendances.filter(function(el) { return el._id != attendance._id; });
                this.clientSelected = {}
                this.clientData = false
                this.finishedAttendanceCount++
                this.getAttendanceQuantity()
                this.hideMessages()
                this.socket.emit('finish_attendance',{ attendance, reason:attendance.completionReason })
            }
        },
        searchClient(item) {
            if(item.clientNumber.toLowerCase().includes(this.clientSearch.value.toLowerCase()) || item.clientName.toLowerCase().includes(this.clientSearch.value.toLowerCase()) || item.protocol.toLowerCase().includes(this.clientSearch.value.toLowerCase()))
                return true
            return false
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.operator.channelId)
            // console.log('respDepartments',resp)
            if(resp.statusCode != 200)
                return this.departments = []
            this.departments = resp.departments
            // this.departments = resp.departments.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        async getAttendances2(page = 1) { 
            this.loadingAttendances = true;
            if(!this.socket.connected) 
                return setTimeout(() => {
                    this.getAttendances2(page)
                }, 1000);

            let resp

            if(this.statusFilter) {
                resp = await api.getAttendancesPaginationByStatus(this.operator._id, page, this.attendancesPerPage, this.statusFilter)
            } else {
                resp = await api.getAttendancesPagination(this.operator._id, page, this.attendancesPerPage);
            }

            console.log({resp})
            this.loadingAttendances = false;

            if (page === 1) {
                const respUnreadCount = await api.getOperatorUnreadCount(this.operator._id);
                if (respUnreadCount.statusCode === 200) {
                    this.attendancesUnreadCount = respUnreadCount.countAttendances;
                }
                this.attendances = []
            }

            if (resp.statusCode === 200) {
                // const attendances = [];
                // await Promise.all(resp.attendances.map(async (att) => {
                //     if (att) {
                //         att.lastMsg = await this.getAttendanceLastMessage(att);
                //         att.newMsgs = await this.getAttendanceUnreadCount(att);
                //         attendances.push(att);
                //     }
                // }));
                this.attendances.push(...resp.attendances.sort((a, b) => (a.lastMessage > b.lastMessage) ? -1 : ((b.lastMessage > a.lastMessage) ? 1 : 0)));
                this.totalAttendances = resp.count
            } else {
                this.attendancesUnreadCount = 0;
                this.attendances = [];
            }

            this.getAttendancesLastMessage(this.attendances.slice((page - 1) * this.attendancesPerPage, page * this.attendancesPerPage))
            this.getAttendancesLastMessage(this.attendances.slice((page - 1) * this.attendancesPerPage, page * this.attendancesPerPage))
            this.getAttendancesUnreadCount(this.attendances.slice((page - 1) * this.attendancesPerPage, page * this.attendancesPerPage))
            this.getAttendancesState(this.attendances.slice((page - 1) * this.attendancesPerPage, page * this.attendancesPerPage))

            if (this.operator.channelConfig?.modules?.kanban) {
                this.getAttendancesKanbanData(this.attendances.slice((page - 1) * this.attendancesPerPage, page * this.attendancesPerPage))
            }

            if(this.currentAttendancesPage != page)
                this.currentAttendancesPage = page

            if(page === 1) {
                setTimeout(() => {
                    this.calculateScreenHeightAttendancesCount()
                }, 1000)
            }
        },
        async getAttendances() {
            this.loadingAttendances = true
            const resp = await api.getAttendances(this.operator._id)
            this.loadingAttendances = false
            if(resp.statusCode != 200)
                return this.attendances = []
            this.attendances = resp.attendances.sort((a,b) => (a.lastMessage > b.lastMessage) ? -1 : ((b.lastMessage > a.lastMessage) ? 1 : 0))
            this.getAttendancesLastMessage(this.attendances)
            this.getAttendancesUnreadCount(this.attendances)

            if (this.operator.channelConfig?.modules?.kanban) {
                this.getAttendancesKanbanData(this.attendances)
            }
        },
        async getAttendancesLastMessage(attendances) {
            if (attendances?.length) {
                await Promise.all(attendances.map(async (el) => {
                    if (el) {
                        const inputSendStorage = sessionStorage.getItem(`inputSend${el.clientNumber}`)
                        if(inputSendStorage) el.draft = inputSendStorage

                        const lastMessage = await this.getAttendanceLastMessage(el)
                        el.lastMsg = lastMessage
                        el.name = (el.name?.trimEnd() ?? '').concat(' ')

                        if (!el.msgs)
                            el.msgs = []

                        return el
                    }
                }))
            }
        },
        async getAttendanceLastMessage(attendance) {
            if (attendance) {
                const resp = await api.getAttendanceLastMessage(attendance._id)
                if (resp.statusCode === 200) {
                    return resp.lastMessage
                }
            }
            return null
        },
        async getAttendancesUnreadCount(attendances, groups = false) {
            let attsUnreadCount = 0;
            if (attendances?.length) {
                await Promise.all(attendances.map(async (el) => {
                    const unreadCount = await this.getAttendanceUnreadCount(el);
                    el.newMsgs = unreadCount
                    attsUnreadCount += unreadCount
                    el.name = el.name?.concat(' ').trimEnd()
                    return el
                }))

                if (groups) {
                    this.groupsUnreadCount = attsUnreadCount
                } else {
                    this.attendancesUnreadCount = attsUnreadCount
                }
            }
        },
        async getAttendancesState(attendances) {
            if (attendances?.length) {
                await Promise.all(attendances.map(async (el) => {
                    if (el) {
                        const contactResp = await api.getContact(el.contactId)
                        if(contactResp.statusCode == 200) {
                            el.state = contactResp.contact.state
                            el.name = (el.name?.trimEnd() ?? '').concat(' ')
                        }
                        return el
                    }
                }));
            }
        },
        async getAttendancesKanbanData(attendances) {
            if (attendances?.length) {
                attendances = await Promise.all(attendances.map(async (el) => {
                    if (el) {
                        return await this.getAttendanceKanbanData(el)
                    }
                }))

                this.attendances.push({})
                this.attendances.pop()
            }
        },
        async getAttendanceKanbanData(attendance) {
            try {
                if (attendance.cardKanbanId) {
                    const cardResp = await api.getCardById(attendance.cardKanbanId)
                    if(cardResp.statusCode === 200 || cardResp.statusCode === 201) {
                        attendance.kanbanCard = cardResp.card
                        attendance.kanbanStepId = cardResp.card.stepId
                        
                        const stepResp = await api.getStepById(cardResp.card.stepId)
                        if(stepResp.statusCode === 200) {
                            const kanbanResp = await api.getKanbanById(stepResp.step.idKanban)
                            if(kanbanResp.statusCode === 200) {
                                const stepsResp = await api.getKanbanSteps(kanbanResp.kanban._id)
                                if(stepsResp.statusCode === 200) {
                                    attendance.kanbanSteps = stepsResp.steps
                                }
                            }
                        }
                    }
                }
            } catch(error) {
                console.error(error)
            }

            return attendance
        },
        async getAttendanceState(attendance) {
            const contactResp = await api.getContact(attendance.contactId)
            console.log('contactResp', contactResp)
            if(contactResp.statusCode == 200) {
                attendance.state = contactResp.contact.state
                if(attendance.name)
                    attendance.name = (attendance.name?.trimEnd() ?? '').concat(' ')
                else
                    attendance.clientName = (attendance.clientName?.trimEnd() ?? '').concat(' ')
            }
        },
        async getAttendanceUnreadCount(attendance) {
            if (!attendance)
                return 0

            const resp = await api.getAttendanceUnreadCount(attendance._id)
            // console.log('resp unread count',resp);
            if (resp.statusCode === 200) {
                return resp.unreadCount
            }
        },
        async getGroups() {
            this.loadingAttendances = true
            // const resp = await api.getGroups(this.operator.channelId)
            // if(resp.statusCode != 200)
            //     return this.attendances = []
            // this.attendances = resp.groups.sort((a,b) => (a.lastMessage > b.lastMessage) ? -1 : ((b.lastMessage > a.lastMessage) ? 1 : 0))
            this.socket.emit('get_groups_operator',this.operator._id)
        },
        async getOperators() {
            const resp = await api.getOperators(this.operator.channelId)
            // console.log('operators resp',resp)
            if(resp.statusCode!=200)
                return this.operators = []
            this.operators = resp.operators
        },
        async getOnlineOperators() {
            const resp = await api.getOnlineOperators(this.operator.channelId)
            console.log('onlineOp resp',resp)
            if(resp.statusCode!=200)
                return this.onlineOperators = []
            this.onlineOperators = resp.operators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        getForwardList() {
            this.getOnlineOperators()
            this.getDepartments()
        },
        forwardTo() {
            if(this.forward.operator || this.forward.department) {
                const client = this.clientSelected
                client.msgs = []
                this.clientData = false
                
                if(this.forward.type == 'Operador') {
                    this.forward.operator.id = this.forward.operator._id
                    this.socket.emit('forward_to_operator', { attendance: client, operator: this.forward.operator })
                    // this.attendances = this.attendances.filter((el) => { return el._id != this.clientSelected._id; })
                    // this.clientSelected = {}
                } else if(this.forward.type == 'Departamento')
                    this.socket.emit('forward_to_department',{ attendance: client, department: this.forward.department })
                this.forward = { type:'' }
                this.$refs['modal-fwdprotocol'].hide()
            }
        },
        lastMsg(msgs) {
            if(msgs) {
                let last = msgs.at(-1)
                
                if(last?.type === 'closedWindow')
                    last = msgs.at(-2)
            
                if(last) {
                    if(last.type === 'protocolStart')
                        return
                    if(last.type === 'button_reply') {
                        last.show = last.body.button_reply?.title || last.body.title
                        return last
                    } else if(last.type === 'list_reply') {
                        last.show = last.body.list_reply?.title || last.body.title
                        return last
                    } else if(last.type === 'interactive') {
                        if(last.interactive?.type === 'list' || last.body?.interactive?.type === 'list')
                            last.show = 'Lista Interativa'
                        else if(last.interactive?.type === 'button' || last.body?.interactive?.type === 'button')
                            last.show = 'Botão Interativo'
                        return last
                    } else if(last.type === 'quick_reply') {
                        last.show = last.body?.text || last.body
                        return last
                    } else if(last.type === 'quote_product') {
                        last.show = 'Resposta à Produto'
                    } else if(last.type !== 'text' && last.type !== 'chat') {
                        last.show = `type=${last.type}`
                        return last
                    }
                    last.show = last.body
                    return last
                }
            }
            return null
        },
        notThisOperator(operators) {
            return operators.filter(el=>el._id!=this.operator._id)
        },
        async loadProtocols() {
            this.loadingProtocol = true;

            const resp = await api.getContactHistory(this.operator.channelId,this.clientSelected.clientNumber)
            // console.log('respHistory',resp)
            if(resp.statusCode == 200) {
                const data = resp.result.attendances
                this.clientProtocols = data.sort((a,b) => (a.attendanceStartTime < b.attendanceStartTime) ? -1 : ((b.attendanceStartTime < a.attendanceStartTime) ? 1 : 0))
                // console.log('this.clientProtocols',this.clientProtocols)
                const currentProtocol = this.clientProtocols.findIndex(el => el.protocol == this.clientSelected.protocol)
                if(currentProtocol > -1) {
                    this.clientProtocols.splice(currentProtocol,1)
                }
                // console.log("this.protocolsCount",this.protocolsCount)
                const protocol = this.clientProtocols[this.clientProtocols.length - this.protocolsCount]
                const timestamp = (new Date(this.clientSelected.attendanceStartTime).getTime() / 1000) -7
                const createdAt = new Date(this.clientSelected.attendanceStartTime)
                const currentProtocolStart = { type: 'protocolStart', createdAt, timestamp, protocol: this.clientSelected.protocol }

                if(protocol?.protocol) {
                    if(this.protocolsCount == 1)
                        this.clientSelected.msgs.unshift(currentProtocolStart)
                    const respProtocolMessages = await api.getProtocolMessages(protocol._id)
                    // console.log('respProtocolMessages',respProtocolMessages)
                    if(respProtocolMessages.statusCode == 200) {
                        const timestamp = (new Date(protocol.attendanceStartTime).getTime() / 1000) -7
                        const createdAt = new Date(protocol.attendanceStartTime)
                        
                        const protocolStart = { type: 'protocolStart', createdAt, timestamp, protocol: protocol.protocol }

                        let dateAux = null

                        respProtocolMessages.messages.messages.reverse().forEach(el => {
                            if(this.timestampDiff > 100)
                                el.timestamp -= this.timestampDiff

                            if(el.timestamp > 9999999999)
                                el.timestamp /= 1000

                            if(dateAux == null) {
                                dateAux = new Date(el.timestamp*1000).toLocaleDateString('en-US')
                            }

                            const date = new Date(el.timestamp*1000).toLocaleDateString('en-US')
                            if(new Date(date) < new Date(dateAux)) {
                                const d = { type: 'date', timestamp: el.timestamp-1, date: dateAux } // timestamp here is wrong, as the dateAux comes from the previous message. if necessary, a fix would be to set a timestampAux to keep the timestamp from previous message
                                this.clientSelected.msgs.unshift(d)
                                dateAux = date
                            }

                            if(el.type=="vcard") {
                                try {
                                    if(!el.vcardName)
                                        el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                                        if(!el.vcardNumber)
                                        el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                                } catch(err) {
                                    console.error('vcard err',err)
                                }
                            } else if (el.type === 'vcardArray') {
                                try {
                                    el.contacts = []
                                    for (const index in el.body) {
                                        const obj = {
                                            vcardName: el.body[index].split('FN:')[1].split('\n')[0],
                                            vcardNumber: el.body[index].split('waid=')[1].split(':')[0]
                                        }
                                        el.contacts[index] = obj
                                    }
                                } catch(err) {
                                    console.error('vcardArray err',err)
                                }
                            }

                            this.clientSelected.msgs.unshift(el)
                        })
                        this.clientSelected.msgs.unshift(protocolStart)
                        this.scrollTopMsgsDiv()
                    }
                    this.protocolsCount++
                } else {
                    this.$emit("msg", {
                        text: "Não foi encontrado um protocolo anterior!",
                        success: false
                    })
                }
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao carregar protocolos!",
                    success: false
                })
            }
            
            this.loadingProtocol = false
        },
        loadProtocolMessages(protocol) {
            if(!protocol.active) {
                this.socket.emit('load_protocol_messages',{attendanceId:protocol._id,operatorId:this.operator._id})
                protocol.active = true
            }
        },
        async loadGroupPreviousMessages() {
            this.loadingPreviousGroupMessages = true

            if(!this.clientSelected.previousMessagesCount)
                this.clientSelected.previousMessagesCount = 1
            else
                this.clientSelected.previousMessagesCount++

            const resp = await api.loadGroupPreviousMessages({ groupId : this.clientSelected._id, count: this.clientSelected.previousMessagesCount })
            
            const msgs = []
            if(resp.statusCode == 200) {
                if(resp.messages?.length) {
                    const messages = resp.messages.reverse()
                    let dateAux
                    for (const el of messages) {
                        if(this.timestampDiff > 100)
                            el.timestamp -= this.timestampDiff
    
                        if(el.timestamp > 9999999999)
                            el.timestamp /= 1000
    
                        if(!dateAux) {
                            dateAux = new Date(el.timestamp*1000).toLocaleDateString('en-US')
                        }
    
                        const date = new Date(el.timestamp*1000).toLocaleDateString('en-US')
                        if(new Date(date) < new Date(dateAux)) {
                            const d = { type: 'date', timestamp: el.timestamp-1, date: dateAux } // timestamp here is wrong, as the dateAux comes from the previous message. if necessary, a fix would be to set a timestampAux to keep the timestamp from previous message
                            msgs.unshift(d)
                            dateAux = date
                        }
    
                        if(el.type == "vcard") {
                            try {
                                if(!el.vcardName)
                                    el.vcardName = el.body.split('FN:')[1].split('\n')[0]

                                if(!el.vcardNumber)
                                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                            } catch(err) {
                                console.error('vcard err',err)
                            }
                        }
                        msgs.unshift(el)
                    }
                }
            }

            this.$set(this.clientSelected, 'msgs', [ ...msgs, ...this.clientSelected.msgs ])

            this.clientSelected = { ...this.clientSelected }

            this.loadingPreviousGroupMessages = false
        },
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        notBase64(string) {
            // if(string[0]=='/' && string[string.length-1]=='=') {
            if(typeof string == 'string') {
                if(string.startsWith('/9'))
                    return false
                return true
            } else {
                // console.log(typeof string, string)
            }
        },
        downloadMedia(url, ext = null) {
            url = utils.replaceDiacritics(decodeURIComponent(url))
            let filename = url.replace('download=false', '').split('/').pop().split('=').at(-1)

            if(!ext)
                ext = url.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            
            filename = filename.replace(`.${ext}`, '')

            const link = document.createElement('a')

            if(url.includes('download=false')) {
                link.href = url.replace('download=false', '')
                link.setAttribute('download','')
                link.setAttribute('target', '_blank')
                link.click()
            } else {
                axios({
                    url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    link.href = url
                    link.setAttribute('download',`${filename}.${ext}`)
                    link.setAttribute('target', '_blank')
                    link.click()
                }).catch(() => {
                    link.href = url
                    link.setAttribute('download',`${filename}.${ext}`)
                    link.setAttribute('target', '_blank')
                    link.click()
                })
            }
        },
        removeMediaToSend(item) {
            const input = document.querySelector('.inputFileForm')
            this.mediaToSend = this.mediaToSend.filter(el=>el!=item)
            const file = this.file.find(el=>el.name==item.name)
            this.file = this.file.filter(el=>el!=file)
            input.reset()
            const addMediaWrapper = document.querySelector('.addMediaWrapper')
            if(addMediaWrapper)
                addMediaWrapper.classList.remove("invisible")
        },
        async searchOldAttendances() {
            if(this.attendanceType == 'contacts') {
                this.loadingAttendances = true
                if(this.clientSearch.value) {
                    let search = this.clientSearch.value
                    if (!isNaN(this.clientSearch.value.charAt(0)))
                        search = search.replace(/\s/g, '');
                    const resp = await api.getOldAttendances(this.operator.channelId, { operatorId: this.operator._id, search })

                    // const filters = this.clientSearch
                    // if(!filters.filter)
                    //     filters.filter = 1
                    // const resp = await api.searchAttendances(this.operator.channelId, { operatorId: this.operator._id, filters })
                    
                    this.clientSearch.search = true
                    if(resp.statusCode === 200) {
                        if(!resp.attendances?.length) {
                            // search contacts without attendances
                            const respContacts = await api.getContactsWithoutAttendance(this.operator.channelId, { search, operatorId: this.operator._id})
                            this.loadingAttendances = false
                            if(respContacts.statusCode === 200) {
                                this.oldAttendances = respContacts.contacts
                            } else {
                                this.oldAttendances = []
                            }
                            return
                        }
                        this.loadingAttendances = false

                        resp.attendances = resp.attendances.sort((a,b) => (a.attendanceStartTime < b.attendanceStartTime) ? -1 : ((b.attendanceStartTime < a.attendanceStartTime) ? 1 : 0))
                        this.oldAttendances = resp.attendances.reverse().reduce((att, current) => {
                            const number = current.clientNumber
                            const numberWithoutNine =
                                number?.length === 13 ? number?.slice(0, 4) + number?.slice(5) : number
                            const numberWithNine =
                                number?.length === 12
                                ? number?.slice(0, 4) + '9' + number?.slice(4)
                                : number

                            const numbers = [numberWithoutNine, numberWithNine]
                            if (!numbers.includes(number)) numbers.push(number)

                            // const index = att.findIndex(item => item.clientNumber === current.clientNumber)
                            const index = att.findIndex(item => numbers.includes(item.clientNumber))
                            if(index === -1)
                                return att.concat([current])

                            if(current.status !== 'finished' && current.status !== 'abandoned') {
                                return att.concat([current])
                            }

                            return att
                        }, [])
                        this.oldAttendances = [
                            ...new Map(
                                this.oldAttendances
                                .sort((a, b) => new Date(a.lastMessage) - new Date(b.lastMessage))
                                .map((item) => [item.contactId || item.clientNumber, item])
                            ).values()
                        ].sort((a, b) => new Date(b.lastMessage) - new Date(a.lastMessage))
                        this.getAttendancesState(this.oldAttendances)
                    } else {
                        return this.oldAttendances = []
                    }
                } else {
                    this.clientSearch.search = false
                    this.oldAttendances = []
                }
                this.loadingAttendances = false
            } else if (this.attendanceType === 'groups') {
                this.getGroups()
            } else { // internalChat
                this.internalSearch = this.clientSearch.value
            }
        },
        formatTimestamp() {
            this.attendances.forEach((el) => {
                if (el) {
                    const attStart = Date.parse(el.attendanceStartTime)
                    const attWaiting = el.endWaitingTime ? Date.parse(el.endWaitingTime) : 0
                    const now = el.attendanceEndTime ? Date.parse(el.attendanceEndTime) : Date.now() + 3000
                    const time = this.formatTime2(now - (attWaiting || attStart))
                    this.$set(el, "timer", time)
                }
            })
        },
        fileExt(filename) {
            if(filename?.split('').reverse().join('')) {
                return filename.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            }
            return ''
        },
        async getClientData(showClientTab = false) {
            if (this.clientSelected.from) return
            if(showClientTab) {
                if(this.showCrmData)
                    this.toggleCrmData()
                this.clientData = !this.clientData
            }

            if(this.clientData && this.clientSelected.contactId) {
                if(this.operator.channel?.apiType === 'gupshup')
                    this.getCatalogCategoriesAll()
                const contactResp = await api.getContact(this.clientSelected.contactId)
                if(contactResp.statusCode === 200) {
                    this.clientSelected.genre = contactResp.contact.genre
                    this.clientSelected.state = contactResp.contact.state
                    this.clientSelected.email = contactResp.contact.email
                    this.clientSelected.clientId = contactResp.contact.clientId
                    this.clientSelected.document = contactResp.contact.document
                    this.clientSelected.responsibleOperatorId = contactResp.contact.responsibleOperatorId
                    this.clientSelected.responsibleOperatorIdAux = contactResp.contact.responsibleOperatorId
                    this.clientSelected.rdStationId = contactResp.contact.rdStationId
                    this.clientSelected.addressType = contactResp.contact.addressType
                    this.clientSelected.cep = contactResp.contact.cep
                    this.clientSelected.neighborhood = contactResp.contact.neighborhood
                    this.clientSelected.country = contactResp.contact.country
                    this.clientSelected.supplementaryDocument = contactResp.contact.supplementaryDocument || []
                    this.clientSelected.complement = contactResp.contact.complement
                    this.clientSelected.address = contactResp.contact.address
                    this.$set(this.clientSelected, "tag", contactResp.contact.tag)
                }
            }
           
            if(showClientTab && window.innerWidth <= 425) {
                const col2 = document.getElementById('col2')
                if(col2)
                    col2.style.flex = '0'

                const col3 = document.getElementById('col3')
                if(col3) {
                    col3.style.display = 'block'
                    col3.style.flex = '1'
                }
            }
        },
        formatCpfCnpj(str) {
            if(str) {
                if (str?.length>11)
                    return str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                else
                    return str.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } 
        },
        showAtts() {
            if(this.clientSearch.search)
                return this.oldAttendances
            return this.attendances
        },
        verifyPhotoURL(url) {
            if(url) {
                if(!url.includes('http'))
                    return `${this.mediaURL}${url}`
                return url
            }
        },
        setBrowser() {
            try {
                this.browser = navigator.userAgent.split(' ')[navigator.userAgent.split(' ').length - 1].split('/')[0];
            } catch(err) { console.error('setBrowser', err) }
        },
        async getChannel() {
            const channel = await api.getChannel(this.operator.channelId)
            if(channel.statusCode == 200)
                this.operator.channel = channel.channel?.channel
            return channel.channel?.channel || null
        },
        // async sendNotification() {
        async sendNotification() {
            if(!this.loadingSN) {
                if(!this.selectedTemplate.contactNumber || this.selectedTemplate.contactNumber.length < 5) {
                    this.$set(this.selectedTemplate, "error", true)
                    return this.$emit('msg', {
                        text: "Número Inválido!",
                        sucess: true
                    })
                }

                if(!this.isHSM() && !this.selectedTemplate.bodyId?.body) {
                    this.$set(this.selectedTemplate, "error", true)
                    return this.$emit('msg', {
                        text: "Preencha o campo requerido!",
                        sucess: true
                    })
                }

                if(this.selectedTemplate.template?.dynamicVariables?.some(v => !v.value)) {
                    return this.$emit('msg', {
                        text: "Preencha todas as variáveis dinâmicas!",
                    })
                }

                this.selectedTemplate.error = false

                // const fixed = [ '2', '3', '4', '5' ]

                let phone
                
                if(this.selectedTemplate.contactNumber)
                    phone = this.selectedTemplate.contactNumber.replace(/[().+\s-]+/g, '')

                this.loadingSN = true
                await this.checkActiveCommunicationInterval()
                if(this.activeCommunicationTimeLeft > 1000) {
                    this.loadingSN = false
                    return
                }
                if(this.selectedTemplate) {
                    if(!this.selectedTemplate.ddi) {
                        this.loadingSN = false
                        return this.$emit("msg", {
                            text: "Campo País vazio!",
                            success: false
                        })
                    }
    
                    const channel = this.operator.channel || await this.getChannel()
    
                    let sendChannel = null
                    if(this.departmentSelected || this.operator.department?.length == 1) {
                        let depId = null
                        if(this.departmentSelected)
                            depId = this.departmentSelected
                        else
                            depId = this.operator.department[0]
                        sendChannel = this.secondaryChannels.find(el => 
                            el.channelConfig?.operationalChannel.id == depId || 
                            el.channelConfig?.operationalChannel.id == this.operator._id
                        )
                    } 
                    
                    if(this.channelSelected) {
                        sendChannel = this.secondaryChannels.find((channel) =>
                            channel._id === this.channelSelected
                        )

                        this.departmentSelected = sendChannel.channelConfig?.operationalChannel.action === 'redirectDepartment' ? sendChannel.channelConfig?.operationalChannel.id : null
                    }

                    if(!this.departmentSelected)
                        this.departmentSelected = this.operator.department?.[0]

                    if(sendChannel ? (sendChannel.apiType == 'cloud' || sendChannel.apiType == 'gupshup') : (channel?.apiType == 'cloud' || channel?.apiType == 'gupshup')) {
                        if(this.selectedTemplate.template)
                            this.socket.emit('send_notification', {
                                channelId: this.operator.channelId,
                                phone,
                                ddi: this.selectedTemplate.ddi,
                                template: this.selectedTemplate.template,
                                tag: {
                                    clientName: this.selectedTemplate.contactName, 
                                    clientNumber: phone,
                                    clientEmail: this.selectedTemplate.contact?.email,
                                    clientCode: this.selectedTemplate.contact?.clientId,
                                    operatorName: this.operator.name,
                                    nickname: this.operator.nickname,
                                    departmentName: this.getDepartmentName(this.operator.department[0] || this.departmentSelected),
                                    headerUrl: null
                                },
                                operatorId: this.operator._id,
                                departmentId: this.departmentSelected,
                                sendChannelId: this.channelSelected,
                                apiType: sendChannel?.apiType || channel?.apiType
                            })
                    } else {
                        this.selectedTemplate.body = this.selectedTemplate.bodyId.body
                        this.selectedTemplate.id = this.selectedTemplate.bodyId.id

                        const obj = {
                            channelId: this.operator.channelId,
                            phone,
                            ddi: this.selectedTemplate.ddi,
                            body: this.selectedTemplate.body,
                            contactName: this.selectedTemplate.contactName,
                            operatorId: this.operator._id,
                            notificationId: this.selectedTemplate.id,
                            departmentId: this.departmentSelected,
                            apiType: sendChannel?.apiType || this.operator.channel?.apiType
                        }

                        this.socket.emit('send_notification', obj)
                    }
                    // this.selectedTemplate.bodyId = undefined
                    // console.log(this.selectedTemplate)
                }
            }
        },
        verifyPrivacy(number) {
            if(number && this.operator.privacy)
                return `${number.slice(0,-4)}****`
            return number
        },
        async saveClientName() {
            const resp = await api.updateContactName(this.clientSelected.clientNumber, { operatorId:this.operator._id, contactName:this.clientSelected.clientName, attendanceId:this.clientSelected._id, channelId: this.operator.channelId })
            // console.log('resp updateContactName',resp)
            this.editClientName = false
        },
        async saveClientEmail() {
            const resp = await api.updateContactEmail(this.clientSelected.clientNumber, this.operator.channelId, this.clientSelected.email)
            // console.log('resp updateContactEmail',resp)
            this.editClientEmail = false
        },
        async saveClientId() {
            const resp = await api.updateContactCode(this.clientSelected.clientNumber, this.operator.channelId, this.clientSelected.clientId)
            // console.log('resp updateContactCode',resp)
            this.editClientId = false
        },
        async saveClientGenre() {
            const resp = await api.updateContact({ _id: this.clientSelected.contactId, genre: this.clientSelected.genre })

            if(resp.statusCode == 200) {
                this.$emit("msg", {
                    text: "Gênero alterado com sucesso!",
                    success: true
                })
                this.editClientGenre = false
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao alterar gênero!",
                    success: false
                })
            }
        },
        async saveClientDocument() {
            const resp = await api.updateContact({ _id: this.clientSelected.contactId, document: this.clientSelected.document })

            if(resp.statusCode == 200) {
                this.$emit("msg", {
                    text: "Documento alterado com sucesso!",
                    success: true
                })
                this.editClientDocument = false
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao alterar documento!",
                    success: false
                })
            }
        },
        async saveClientTag() {
            const resp = await api.updateContactTag(this.clientSelected.clientNumber, this.operator.channelId, this.clientSelected.tag)
            // console.log('resp updateContactTag',resp)
            if(resp.statusCode == 200) {
                this.$emit("msg", {
                    text: "Etiqueta salva com sucesso!",
                    success: true
                })
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao salvar etiqueta!",
                    success: false
                })
            }
        },
        async saveClientResponsibleOperator() {
            if(!this.clientSelected.responsibleOperatorIdAux) {
                return this.$emit("msg", {
                    text: 'Campo Operador vazio!',
                    success: false
                })
            }

            const resp = await api.updateContactResponsibleOperator(this.clientSelected.contactId, this.clientSelected.responsibleOperatorIdAux)
            // console.log('resp updateContactResponsibleOperator',resp)
            
            if(resp.statusCode == 200) {
                this.$set(this.clientSelected,'responsibleOperatorId',this.clientSelected.responsibleOperatorIdAux)
                this.clientSelected.newMsgs++
                this.clientSelected.newMsgs--
                this.$emit("msg", {
                    text: "Contato vinculado com sucesso",
                    success: true
                })
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro! Tente novamente mais tarde!",
                    success: false
                })
            }
        },
        async getRetrieveContacts(search, filterType = false, page = 1) {
            this.loadingRetrieveContacts = true

            if(page === 1 && this.retrieveContacts.length) {
                this.retrieveContacts = []
            }

            if(filterType) {
                this.retrieveContactsTotal = false
                const resp = await api.getRetrieveContacts(this.operator.channelId, this.operator._id, search, page)
                console.log("resp", resp)
                if(resp.statusCode === 200) {
                    this.retrieveContacts.push(...resp.contacts)
                    this.retrieveContactsTotal = resp.count
                }
            } else if(search) {
                this.retrieveTabIndex = 3
                this.retrieveContactsTotal = true
                const resp = await api.getContactsSearch(this.operator.channelId, this.operator._id, search)
                // console.log('resp',resp)
                if(resp.statusCode === 200) {
                    if(!resp.contacts?.length) {
                        // search contacts without attendances
                        const respContacts = await api.getContactsWithoutAttendance(this.operator.channelId, { search, operatorId: this.operator._id})
                        // console.log({respContacts})
                        if(respContacts.statusCode == 200) {
                            this.retrieveContacts = respContacts.contacts
                            this.retrieveContactsTotal = this.retrieveContacts.length
                        } else {
                            this.retrieveContacts = []
                            this.retrieveContactsTotal = 0
                        }
                        this.loadingRetrieveContacts = false
                        return
                    } else {
                        this.retrieveContacts = resp.contacts
                        this.retrieveContactsTotal = this.retrieveContacts.length
                    }
                }
            }
            this.loadingRetrieveContacts = false

            if(page != this.retrieveContactsPage) {
                this.retrieveContactsPage = page
            }

            if(page === 1) {
                setTimeout(() => {
                    this.calculateScreenHeightRetrieveContactsCount()
                }, 1000)
            }
        },
        async startAttendanceByContactId(contactId) {
            if(contactId) {
                const contactResp = await api.getContact(contactId)
                if(contactResp.statusCode == 200) {
                    this.startAttendance(contactResp.contact)
                    this.$bvModal.hide("calendarEvent")
                }
            } else {
                this.$emit("msg", {
                    text: "Erro ao encontrar contato!",
                    success: false
                })
            }
        },
        async startAttendance(contact = null, fromModal = false, fromPickDepartment = false, ignoreSurvey = false) {
            if(!this.startingAttendance) {
                let sendChannel = null

                this.startAttendanceParams = {}

                if(this.departmentSelected || this.operator.department?.length == 1) {
                    let depId = null
                    if(this.departmentSelected)
                        depId = this.departmentSelected
                    else
                        depId = this.operator.department[0]
                    sendChannel = this.secondaryChannels.find(el => 
                        el.channelConfig?.operationalChannel.id == depId || 
                        el.channelConfig?.operationalChannel.id == this.operator._id
                    )
                }
                
                if(this.channelSelected) {
                    sendChannel = this.secondaryChannels.find((channel) => 
                        channel._id === this.channelSelected
                    )

                    this.departmentSelected = sendChannel?.channelConfig?.operationalChannel.action === 'redirectDepartment' ? sendChannel.channelConfig?.operationalChannel.id : this.operator.department[0]
                }

                if(!fromPickDepartment && !fromModal && this.operator.department?.length > 1 && this.operator.channel?.hasSecondaryChannels) {
                    this.contactSelected = contact

                    const officialChannels = this.secondaryChannels.filter((channel) => !channel.disableActiveCommunication && (channel.apiType === 'gupshup' || channel.apiType === 'cloud'))

                    if (officialChannels.length && this.operator?.channel?._id !== '642d9508c05bea32938e219e')
                        return this.$bvModal.show("modalPickChannelRetrieve")
                    
                    return this.$bvModal.show("modalPickDepartmentRetrieve")
                } else if(!fromPickDepartment && !fromModal && this.operator.channel?.hasSecondaryChannels) {
                    this.contactSelected = contact

                    const officialChannels = this.secondaryChannels.filter((channel) => !channel.disableActiveCommunication && (channel.apiType === 'gupshup' || channel.apiType === 'cloud'))

                    if (officialChannels.length && this.operator?.channel?._id !== '642d9508c05bea32938e219e')
                        return this.$bvModal.show("modalPickChannelRetrieve")
                }

                if(!contact) contact = this.contactSelected
                if(!contact) {
                    return this.$emit("msg", {
                        text: "Contato não selecionado!",
                        success: false
                    })
                }
                const channel = this.operator.channel || await this.getChannel()

                // in this cases, contact comes from old attendance
                if(!contact.number) contact.number = contact.clientNumber
                if(!contact.name) contact.name = contact.clientName

                if(!this.departmentSelected)
                    this.departmentSelected = this.operator.department?.at(0)

                if(sendChannel ? (sendChannel.apiType == 'cloud' || sendChannel.apiType == 'gupshup') : (channel?.apiType == 'cloud' || channel?.apiType == 'gupshup')) {
                    if(fromModal) {
                        if(!this.forwardMessage)
                            this.startingAttendance = true

                        if(!this.selectedTemplate.template) {
                            this.startingAttendance = false
                            return this.$emit("msg", {
                                text: "Template não selecionado!",
                                success: false
                            })
                        }
    
                        if(contact.contactId) {
                            const contactResp = await api.getContact(contact.contactId)
                            if(contactResp.statusCode == 200 && contactResp.contact) {
                                contact = contactResp.contact
                            }
                        }
    
                        const resp = await api.retrieveAttendance(this.operator.channelId, { 
                            contact,
                            operatorId: this.operator._id,
                            departmentId: this.departmentSelected,
                            template: this.selectedTemplate.template,
                            sendChannelId: this.channelSelected,
                            tag: {
                                clientName: contact.name || contact.clientName, 
                                clientNumber: contact.number || contact.clientNumber,
                                clientEmail: contact.email,
                                clientCode: contact.clientId,
                                operatorName: this.operator.name,
                                nickname: this.operator.nickname,
                                departmentName: this.getDepartmentName(this.operator.department[0] || this.departmentSelected),
                                headerUrl: null
                            },
                            ignoreSurvey
                        })

                        this.startingAttendance = false
                        // console.log('resp retrieveAtt',resp)

                        if(this.$refs["modalContactInSurvey"])
                            this.$refs["modalContactInSurvey"].hide()

                        if(this.$refs["modalPickDepartmentRetrieve"])
                            this.$refs["modalPickDepartmentRetrieve"].hide()
                        
                        if(this.$refs["modalPickChannelRetrieve"])
                            this.$refs["modalPickChannelRetrieve"].hide()

                        if(resp.statusCode === 406) {
                            let text

                            switch(resp.attendance.status) {
                                case 'opened':
                                    text = "Este contato está com atendimento em andamento no ChatBot"
                                    break
                                case 'offline_operators':
                                    text = "Este contato está com atendimento em andamento na fila de espera"
                                    break
                                default:
                                    if (resp.attendance.operatorName)
                                        text = `Este contato está com atendimento em andamento com ${resp.attendance.operatorName}!`
                                    else
                                        text = "Este contato está com atendimento em andamento"
                            }

                            return this.$emit("msg", {
                                text,
                                success: false
                            })
                        } else if(resp.statusCode === 409) {
                            this.startAttendanceParams = { contact, fromModal, fromPickDepartment }
                            return this.$bvModal.show("modalContactInSurvey")
                            // return this.$emit("msg", {
                            //     text: "Este contato está em pesquisa de satisfação!",
                            //     success: false
                            // })
                        } else if(resp.statusCode != 200 || !resp.attendance) {
                            return this.$emit("msg", {
                                text: "Ocorreu um erro!",
                                success: false
                            })
                        }
    
                        if(this.attendanceType == 'contacts') {
                            const found = this.attendances.find(att => att.clientNumber == resp.attendance.clientNumber)
                            if(!found) {
                                resp.attendance = await this.getAttendanceKanbanData(resp.attendance)
                                this.attendances.unshift(resp.attendance)
                            }
                            if(this.clientSearch.search) {
                                this.cleanSearchFilter()
                                this.oldAttendances = []
                            }
                            this.getAttendances2(1)
                            // this.searchOldAttendances()
                        }

                        if(this.departmentSelected)
                            this.departmentSelected = null
                        
                        if(this.channelSelected)
                            this.channelSelected = null

                        if(this.$refs["modalRetrievePickTemplate"])
                            this.$refs["modalRetrievePickTemplate"].hide()

                        if(this.$refs["modalContactInSurvey"])
                            this.$refs["modalContactInSurvey"].hide()

                        return 
                    } else {
                        if(this.forwardMessage) {
                            this.startingAttendance = false
                            return this.$emit("msg", {
                                text: "Não é possível encaminhar mensagens ao iniciar atendimento Cloud!",
                                success: false
                            })
                        }

                        this.getHSMModels()

                        this.contactSelected = contact
                        return this.$bvModal.show("modalRetrievePickTemplate")
                    }
                }

                if(!this.forwardMessage)
                    this.startingAttendance = true
                
                const resp = await api.retrieveAttendance(this.operator.channelId, { 
                    contact, 
                    operatorId: this.operator._id, 
                    departmentId: this.departmentSelected,
                    ignoreSurvey
                })
                console.log('resp retrieveAtt', resp)

                this.startingAttendance = false
                let msg
                if(resp.statusCode == 200) {
                    resp.attendance.state = contact.state
                    if(this.attendanceType == 'contacts') {
                        resp.attendance = await this.getAttendanceKanbanData(resp.attendance)
                        this.attendances.unshift(resp.attendance)
                        this.totalAttendances++
                        this.attendancesLength++
                    }

                    if(this.forwardMessage) {
                        this.sendMsg2(resp.attendance, this.forwardMessage)
                        // this.$bvModal.hide("forwardMessagePickContacts")
                    }

                    if(this.$refs['modalRetrieveContact'])
                        this.$refs['modalRetrieveContact'].hide()
                } else if(resp.statusCode == 406) {
                    let text

                    switch(resp.attendance.status) {
                        case 'opened':
                            text = "Este contato está com atendimento em andamento no ChatBot"
                            break
                        case 'offline_operators':
                            text = "Este contato está com atendimento em andamento na fila de espera"
                            break
                        default:
                            if (resp.attendance.operatorName)
                                text = `Este contato está com atendimento em andamento com ${resp.attendance.operatorName}!`
                            else
                                text = "Este contato está com atendimento em andamento"
                            break
                    }

                    msg = {
                        text,
                        success: false
                    }
                } else if(resp.statusCode === 409) {
                    this.startAttendanceParams = { contact, fromModal, fromPickDepartment }
                    return this.$bvModal.show("modalContactInSurvey")
                } else if(resp.statusCode == 408) {
                    msg = {
                        text: "Seu horário de atendimento foi finalizado!",
                        success: false,
                        countdown: 10
                    }

                    if(this.$refs['modalRetrieveContact'])
                        this.$refs['modalRetrieveContact'].hide()
                } else {
                    msg = {
                        text: "Ocorreu um erro!",
                        success: false
                    }
                }

                
                if(this.$refs["modalContactInSurvey"])
                    this.$refs["modalContactInSurvey"].hide()

                if(this.$refs["modalPickDepartmentRetrieve"])
                    this.$refs["modalPickDepartmentRetrieve"].hide()

                if(this.$refs["modalPickChannelRetrieve"])
                    this.$refs["modalPickChannelRetrieve"].hide()

                if(msg) {
                    this.$emit("msg", msg)
                }

                if(this.clientSearch) {
                    this.clientSearch = { value: '', filter: 0, filterText: '', search: false }
                    if(this.clientSelected)
                        this.clientSelected = { country: "" }
                    if(this.oldAttendances?.length)
                        this.oldAttendances = []
                }
                return resp.attendance
            }
        },
        formatNumber(number) {
            if(number) {
                let n = number
                const ddi = number.substring(0,2)

                if(ddi === '55') {
                    let has9 = false
                    if (number?.length == 13 && parseInt(number.charAt(4)) == 9) has9 = true
    
                    if(has9)
                        n = ['+',number.slice(0,2),' ',number.slice(2,4),' ',number.slice(4,9),'-',number.slice(9)].join('')
                    else
                        n = ['+',number.slice(0,2),' ',number.slice(2,4),' ',number.slice(4,8),'-',number.slice(8)].join('')
                }

                return n
            }
            return ''
        },
        inMessageSupportedTypes(type) {
            const supportedTypes = new Set([
                'image', 'video', 'ptt', 'audio', 'location', 'vcard', 'vcardArray', 'document', 'application',
                'sticker', 'list-reply', 'interactive', 'button_reply', 'text', 'chat', 'quick_reply', 'list_reply'
            ])

            return supportedTypes.has(type)
        },
        openMsgOptions(id) {
            if(!isNaN(id))
                id = Math.trunc(id)

            const msgOptions = document.querySelector(`#msgOptions-${id}`)
            if(msgOptions)
                msgOptions.style.display = 'flex'
        },
        closeMsgOptions(id) {
            if(!isNaN(id))
                id = Math.trunc(id)

            const msgOptions = document.querySelector(`#msgOptions-${id}`)
            if(msgOptions)
                msgOptions.style.display = 'none'
        },
        setReplyMessage(message) {
            // console.log('setReplyMessage',message)
            if(message.type == 'text')
                message.type == 'chat'
            this.replyMessage = {
                fromMe: message.fromMe,
                body: message.body,
                type: message.type,
                idWpp: message.idWpp
            }
            this.closeMsgOptions(message.timestamp)
        },
        setEditMessage(message) {
            if(message.type == 'text')
                message.type == 'chat'

            this.editMessage = {
                fromMe: true,
                phone: this.clientSelected.clientNumber,
                body: message.body,
                type: message.type,
                idWpp: message.idWpp,
                key: {
                    remoteJid: `${this.clientSelected.clientNumber}@s.whatsapp.net`,
                    fromMe: true,
                    id: message.idWpp,
                }
            }
            this.inputSend = message.body
            this.closeMsgOptions(message.timestamp)
        },
        deleteMessage(message) {
            this.socket.emit('delete_message', message)
        },
        async getConfigFile() {
            const config = await api.getConfigFile()
            if (config.statusCode !== 404) {
                if (config.audioExtension)
                    this.audioExtension = config.audioExtension
            }
        },
        copyToClipboard(message) {
            navigator.clipboard.writeText(message.body)
            this.closeMsgOptions(message.idWpp || message.timestamp)
        },
        parseMsgBody(body) {
            return body
        },
        getHeaderHeight() {
            const updateHeaderHeight = () => {
                const header = document.querySelector('.headeratt')
                if (header) {
                    this.headerHeight = header.offsetHeight
                }
            }

            // updates height on page load
            updateHeaderHeight()

            // updates height after 2 seconds (in case header is slow to load)
            setTimeout(() => {
                updateHeaderHeight()
            }, 2000)
            
            // updates height on window resize
            window.addEventListener('resize', updateHeaderHeight)
        },

        goToMessage(msg, include = false) {
            // console.log({msg})
            let idWpp

            if(msg.id?.id)
                idWpp = msg.id.id
            else
                idWpp = msg.id
            
            const msgs = this.clientSelected.msgs
            if (msgs && msgs.length) {
                if(msg.type == 'chat' || msg.type == 'text') {
                    if(!idWpp) {
                        const found = include
                            ? msgs.find(el => el.body?.toLowerCase().includes(msg.body.toLowerCase()))
                            : msgs.find(el => el.body == msg.body)
                        if (found) {
                          idWpp = found.idWpp
                        } else {
                            return this.$emit("msg", {
                                text: "O item citado está em um protocolo anterior ao atendimento atual.",
                                success: false
                            })
                        }
                    }
                    const element = document.getElementById(idWpp) || document.getElementById(`d${parseInt(idWpp)}`);
                    if (element) {
                        element.scrollIntoView()
                    }
                } else {
                    const element = document.getElementById(idWpp) || document.getElementById(`d${parseInt(idWpp)}`)
                    if (element) {
                        element.scrollIntoView()
                    } else {
                        return this.$emit("msg", {
                            text: "O item citado está em um protocolo anterior ao atendimento atual.",
                            success: false
                        })
                    }
                }
            }
        },
        getAttendancesStatus() {
            this.socket.emit('status_attendances_day',{ operatorId: this.operator._id })
        },
        parseGender(gender) {
            const genderMap = {
                'M': 'Masculino',
                'F': 'Feminino',
            }
            return genderMap[gender] || 'Não Identificado'
        },
        parseState(state) {
            return utils.siglaPraEstadoCompleto(state) || 'N/D'
        },
        showNotification(messageText,contactName,contactPhotoURL) {
            if(document.hasFocus())
                return

            const notification = new Notification(contactName, { body: messageText, icon: contactPhotoURL || "https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" })
            notification.onclick = () => { 
                notification.close()
                window.parent.focus()
            }
        },
        requestNotificationPermission() {
            if (!("Notification" in window))
                alert("Esse navegador não suporta notificações!")
            if (Notification.permission == "denied") {
                alert("Notificações estão bloqueadas pelo seu navegador! \nVocê pode desbloqueá-la nas configurações do seu navegador.")
            }
            Notification.requestPermission((permission) => {
               this.browserNotification = permission
                if (permission === "granted") {
                    this.$bvModal.show("notificationConfig")
                }
            })
        },
        requestAndShowPermission() {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                    this.$bvModal.show("notificationConfig")
                    this.showNotification()
                }
            });
        },
        sendBrowserNotification(data) {
            if ('Notification' in window) {
                let message
                switch(data.messages?.type) {
                    case 'text':
                    case 'chat':
                        message = data.messages?.body
                    break
                    case 'video':
                        message = "Vídeo"
                    break
                    case 'image':
                        message = "Ímagem"
                    break
                    case 'audio':
                        message = "Áudio"
                    break
                    case 'location':
                        message = "Localização"
                    break
                    case 'vcard':
                        message = "Contato"
                    break
                    case 'vcardArray':
                        message = "Contatos"
                    break
                    case 'document':
                    case 'application':
                        message = "Documento"
                    break
                    case 'sticker':
                        message = "Sticker"
                    break
                    case 'button_reply':
                        message = data.messages.body.button_reply?.title || data.messages.body.title
                    break
                    case 'list_reply':
                        message = data.messages.body.list_reply?.title || data.messages.body.title
                    break
                    default:
                        return
                }
    
                const permission = Notification.permission

                if(permission === "granted")
                    this.showNotification(message, data.attendance?.clientName || data.messages?.name, data.attendance?.photoURL)
                else if(permission === "default")
                    this.requestAndShowPermission()
            }
        },
        async getHSMModels() {
            let sendChannel, channelId = this.operator.channelId

            if(this.departmentSelected || this.operator.department?.length == 1) {
                let depId
                if(this.departmentSelected)
                    depId = this.departmentSelected
                else
                    depId = this.operator.department[0]
                sendChannel = this.secondaryChannels.find(el => 
                    el.channelConfig?.operationalChannel.id == depId || 
                    el.channelConfig?.operationalChannel.id == this.operator._id
                )
            }

            if(this.channelSelected) {
                sendChannel = this.secondaryChannels.find((channel) =>
                    channel._id === this.channelSelected
                )
                channelId = this.channelSelected
            }

            const channel = this.operator.channel || await this.getChannel()

            if(sendChannel ? (sendChannel.apiType == 'cloud' || sendChannel.apiType == 'gupshup') : (channel?.apiType == 'cloud' || channel?.apiType == 'gupshup')) {
                this.templates = []
                
                const modelsResp = await api.getMessageTemplates(channelId)
                // console.log('modelsResp',modelsResp)
                if(modelsResp.statusCode == 200 && modelsResp.templates?.length)
                    this.templates = modelsResp.templates.filter(el => el.status == "APPROVED")?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

                if(!this.templates?.length) {
                    this.$emit("msg", {
                        text: "Nenhum Modelo HSM!",
                        success: false
                    })
                }

                return
            }

            this.templates = [
                { id: '99999', nome: 'Criar abordagem', conteudo: '' }
            ]
        },
        getTemplateHeader(components) {
            if(components) {
                return components.find(el => el.type?.toLowerCase() == "header" )
            }
        },
        getTemplateBody(components) {
            if(components) {
                return components.find(el => el.type?.toLowerCase() == "body")?.text
            }
        },
        getTemplateFooter(components) {
            if(components) {
                return components.find(el => el.type?.toLowerCase() == "footer")?.text
            }
        },
        getTemplateButton(components) {
            if(components) {
                const buttons = components.find(el => el.type?.toLowerCase() == "buttons" || el.buttons?.length)
                const button = components.filter(el => el.type?.toLowerCase() == "button")
                if(buttons)
                    return buttons.buttons
                else if(button)
                    return button
            }
        },
        async pickAttendanceType(type) {
            this.attendanceType = type
            this.attendances = []
            this.oldAttendances = []
            this.clientSelected = { country: "" }
            this.internalChat = null
            if(type === 'contacts') {
                await this.getAttendances2(1)
            } else if (type === 'groups') {
                await this.getGroups()
            }
        },
        showContacts(group) {
            this.$set(group, "showContacts", !group.showContacts)
        },

        goToMessageSearch(direction = "up") {
            if(this.clientSelected.msgs?.length) {
                const found = this.clientSelected.msgs.filter( el => el.body?.toLowerCase().includes(this.inputSearchGroupMsg.toLowerCase()) && el.idWpp )
                // console.log('found',found)
                if(found?.length) {
                    let el
                    this.msgSearchTotal = found.length
                    if(this.msgSearchActual) {
                        if(direction == "up") {
                            if(this.msgSearchActual < this.msgSearchTotal)
                                ++this.msgSearchActual
                        } else
                            if(this.msgSearchActual > 1)
                                --this.msgSearchActual
                        el = found[found.length - this.msgSearchActual]
                    } else {
                        this.msgSearchActual = 1
                        el = found[found.length - this.msgSearchActual]
                    }
                    if(el) {
                        let id = document.getElementById(el.idWpp)
                        if(!id) {
                            id = document.getElementById(`d${parseInt(el.idWpp)}`)
                            if(id) 
                                id.scrollIntoView()
                        } else
                            id.scrollIntoView()
                        setTimeout(() => {
                            el.highlight = true
                            el.timestamp++
                            el.timestamp--
                        }, 100)
                        setTimeout(() => {
                            el.highlight = false
                            el.timestamp++
                            el.timestamp--
                        }, 1000)
                    }
                }
            }
        },
        searchGroupMsg() {
            this.msgSearchActual = 0
            this.msgSearchTotal = 0
            this.goToMessageSearch()
        },
        async getAttendanceQuantity() {
            const resp = await api.getAttendanceQuantity(this.operator._id)
            // console.log('resp getAttendanceQuantity',resp)
            if(resp.statusCode == 200)
                this.attendancesLength = resp.count
        },
        // async getGroupQuantity() {
        //     const resp = await api.getGroupQuantity(this.operator.channelId)
        //     // console.log('resp getGroupQuantity',resp)
        //     if(resp.statusCode == 200 || resp.count)
        //         this.groupsLength = resp.count
        // },
        async getOperatorGroupsQuantity() {
            const resp = await api.getOperatorGroupsQuantity(this.operator._id)
            console.log('resp getOperatorGroupsQuantity',resp)
            if(resp.statusCode == 200 || resp.count)
                this.groupsLength = resp.count
        },
        toggleReactInfo(item) {
            item.showReactInfo = !item.showReactInfo
            item.timestamp++
            item.timestamp--
        },
        getTagInfo(tagId) {
            return this.tags.find(el => el._id === tagId) || null
        },
        async getAllTags() {
            const resp = await api.getAllTags(this.operator.channelId)
            // console.log('resp',resp)
            if(!resp || resp.statusCode != 200) {
                this.selectTagOptions = [{ value: null, text: "Nenhum" }]
                this.tags = []
            } else {
                const options = [{ value: null, text: "Nenhum" }]
                resp.tags.map(el => {
                    options.push({
                        value: el._id,
                        text: el.name
                    })
                })
                this.selectTagOptions = options
                this.tags = resp.tags
            }
        },
        toggleTagName(item) {
            if(!item.showTagName)
                this.$set(item, "showTagName", true)
            else
                this.$set(item, "showTagName", false)
        },
        async getQuickAnswers(shortcut = null) {
            this.loadingQuickAnswers = true
            const form = {
                departmentsArray: this.operator.department,
            }

            if(this.clientSelected?.departmentId)
                form.departmentsArray = [ this.clientSelected?.departmentId ]
            if(shortcut) {
                shortcut = shortcut.slice(1)
                form.shortcut = shortcut
            }

            const resp = await api.getQuickAnswersByDepartments(this.operator.channelId,form)
            // console.log('answers resp',resp)
            this.loadingQuickAnswers = false
            if(resp.statusCode != 200)  {
                return this.quickAnswers = []
            }
            this.quickAnswers = resp.quickAnswers.filter(el => el.channelId == this.operator.channelId)
        },
        async verifyInput(input) {
            if(input == "/" || input.startsWith("/")) {
                await this.getQuickAnswers(input)
                if(this.quickAnswers?.length) {
                    this.showQuickAnswersDiv()
                } else {
                    this.hideQuickAnswersDiv()
                }
            } else {
                this.hideQuickAnswersDiv()
            }
        },
        setQuickAnswer(msg) {
            this.inputSend = msg
            this.hideQuickAnswersDiv()
        },
        toggleQuickAnswers() {
            // if(this.quickAnswers.length)
            this.showQuickAnswers = !this.showQuickAnswers
        },
        showQuickAnswersDiv() {
            if(!this.showQuickAnswers)
                this.showQuickAnswers = true
        },
        hideQuickAnswersDiv() {
            if(this.showQuickAnswers)
                this.showQuickAnswers = false
        },
        verifyOperatorHasGroups() {
            this.socket.emit("operator_has_groups", this.operator._id)
            this.timeoutGroups = setTimeout(() => {
                this.verifyOperatorHasGroups()
            }, 4000)
        },
        selectSearchFilter(filter) {
            this.clientSearch.filter = filter
            switch(filter) {
                case 1:
                    this.clientSearch.filterText = "Fila de Atendimento"
                    break
                case 2:
                    this.clientSearch.filterText = "Carteira de Clientes"
                    break
                case 3:
                    this.clientSearch.filterText = "Finalizados"
                    break
                case 4:
                    this.clientSearch.filterText = "Abandonados"
                    break
                case 5:
                    this.clientSearch.filterText = "Dado Enriquecido"
                    break
            }
            // this.$set(this.clientSearch,'filter',filter)
        },
        cleanSearchFilter() {
            this.clientSearch.value = ''
            this.clientSearch.filterText = ''
            this.clientSearch.filter = 0
            this.clientSearch.search = false
            // this.$set(this.clientSearch,'filter',0)
        },
        checkSearchInput(input) {
            if(!input)
                this.cleanSearchFilter()
        },
        getDepartmentName(departmentId) {
            if(departmentId && this.departments?.length) {
                return this.departments.find(el => el._id == departmentId)?.name
            }
            return
        },
        async channelHasSecondaryChannels() {
            if(this.operator.channel) {
                const resp = await api.getChannelHasSecondaryChannels(this.operator.channelId)
                // console.log("resp",resp)
                if(resp.statusCode == 200) {
                    this.operator.channel.hasSecondaryChannels = resp.hasSecondaryChannels
                    if(resp.hasSecondaryChannels)
                        this.getSecondaryChannels()
                }
            } else {
                setTimeout(() => {
                    this.channelHasSecondaryChannels()
                }, 1000);
            }
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.operator.channelId)
            if(resp.statusCode != 200) {
                this.secondaryChannels = []
            } else {
                this.secondaryChannels = resp.channels
                await this.secondaryChannels.map(async (el) => {
                    const config = await api.getChannelConfig(el._id)
                    this.$set(el,'channelConfig',config.channelConfig)
                    return el
                })
            }
        },
        showActiveCommunicationModal(fromPickDepartment = false) {
            this.checkActiveCommunicationInterval()
            let officialChannels

            if (this.operator.channel?.hasSecondaryChannels)
                officialChannels = this.secondaryChannels.filter((channel) => !channel.disableActiveCommunication && (channel.apiType === 'gupshup' || channel.apiType === 'cloud'))

            if(!fromPickDepartment && this.operator.department?.length > 1 && this.operator.channel?.hasSecondaryChannels) {
                if (officialChannels?.length && this.operator?.channel?._id !== '642d9508c05bea32938e219e')
                    return this.$bvModal.show("modalPickChannelNotification")

                return this.$bvModal.show("modalPickDepartmentNotification")
            } else if(!fromPickDepartment && this.operator.channel?.hasSecondaryChannels && officialChannels?.length && this.operator?.channel?._id !== '642d9508c05bea32938e219e') {
                return this.$bvModal.show("modalPickChannelNotification")
            } else {
                this.getHSMModels()
                return this.$bvModal.show("modal-activecomm")
            }
        },
        handleSelectTemplateNumberInput(input) {
            this.refreshSelectedTemplate()
        },
        async checkActiveCommunicationInterval() {
            const resp = await api.getActiveCommunicationTimeLeft(this.operator.channelId,this.departmentSelected)
            // console.log("checkActiveCommunicationInterval resp",resp)
            if(resp.statusCode == 200 && resp.activeCommunicationTimeLeft > 0) {
                this.activeCommunicationTimeLeft = resp.activeCommunicationTimeLeft
                const timer = setInterval(() => {
                    this.activeCommunicationTimeLeft -= 1000
                    if(this.activeCommunicationTimeLeft < 1000)
                        clearInterval(timer)
                }, 1000);
            } else
                this.activeCommunicationTimeLeft = 0
        },
        formatTime(ms) { // MM:SS
            if(ms) {
                const pad = function(n) { return n < 10 ? '0' + n : n; };
                const days = Math.floor(ms / (24*60*60*1000));
                const daysms = ms % (24*60*60*1000);
                const hours = Math.floor(daysms / (60*60*1000));
                const hoursms = ms % (60*60*1000);
                const minutes = Math.floor(hoursms / (60*1000));
                const minutesms = ms % (60*1000);
                const sec = Math.floor(minutesms / 1000);
                return pad(minutes) + ":" + pad(sec);
            }
            return "00:00"
        },
        formatTime2(ms) { // DD:HH:MM:SS
            if(ms && ms > 1000) {
                const pad = function(n) { return n < 10 ? '0' + n : n; };
                const days = Math.floor(ms / (24*60*60*1000));
                const daysms = ms % (24*60*60*1000);
                const hours = Math.floor(daysms / (60*60*1000));
                const hoursms = ms % (60*60*1000);
                const minutes = Math.floor(hoursms / (60*1000));
                const minutesms = ms % (60*1000);
                const sec = Math.floor(minutesms / 1000);

                let time

                if(days)
                    time = pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(sec)
                else
                    time = pad(hours) + ":" + pad(minutes) + ":" + pad(sec)

                return time
            }
            return "00:00:00"
        },
        isHSM() {
            const channel = this.operator.channel

            let sendChannel = null
            if(this.departmentSelected || this.operator.department?.length == 1) {
                let depId = null
                if(this.departmentSelected)
                    depId = this.departmentSelected
                else
                    depId = this.operator.department[0]
                sendChannel = this.secondaryChannels.find(el => 
                    el.channelConfig?.operationalChannel.id == depId || 
                    el.channelConfig?.operationalChannel.id == this.operator._id
                )
            }

            if(this.channelSelected) {
                sendChannel = this.secondaryChannels.find((channel) => 
                    channel._id === this.channelSelected
                )

                this.departmentSelected = sendChannel?.channelConfig?.operationalChannel.action === 'redirectDepartment' ? sendChannel.channelConfig?.operationalChannel.id : this.operator.department[0]
            }

            if(sendChannel ? (sendChannel.apiType == 'cloud' || sendChannel.apiType == 'gupshup') : (channel?.apiType == 'cloud' || channel?.apiType == 'gupshup')) {
                return true
            }
            return false
        },
        async getCatalogCategoriesAll() {
            const resp = await api.getCatalogCategories(this.operator.channelId)
            // console.log("resp", resp)
            const categories = []

            if(resp?.result?.statusCode == 200 && resp.result.categories?.length) {
                resp.result.categories.map(async el => {
                    if(el.visible)
                        categories.push(el)
                })
            }

            this.catalogCategoriesAll = categories?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        async getCatalogCategoriesSearch(search = "") {
            this.loadingCategories = true

            if(!search)
                search = this.catalogSearch

            const resp = await api.getCatalogProductsByKeyword(this.operator.channelId, search, true)

            let categories = []

            if(resp?.result?.statusCode == 200 && resp.result.products?.length) {
                resp.result.products = resp.result.products.filter(el => el.visible == true || !el.hidden)
                for (const element of resp.result.products) {
                    if(!categories.length) {
                        const categoryResp = await api.getCatalogCategoryById(this.operator.channelId, element.categoryId)
                        if(categoryResp?.result?.statusCode == 200 && categoryResp.result.category?._id) {
                            const category = categoryResp.result.category
                            category.products = [ element ]
                            if(!category.topCategoryId) {
                                categories.push(category)
                            } else {
                                const topCategoryResp = await api.getCatalogCategoryById(this.operator.channelId, category.topCategoryId)
                                if(topCategoryResp?.result?.statusCode == 200 && topCategoryResp.result.category?._id) {
                                    const topCategory = topCategoryResp.result.category
                                    topCategory.subcategories = [ category ]
                                    categories.push(topCategory)
                                }
                            }
                        }
                    } else {
                        let found = false
                        for await (const category of categories) {
                            if(category._id == element.categoryId) {
                                if(!category.products)
                                    category.products = []

                                category.products.push(element)
                                found = true
                                break
                            } else if(category.subcategories) {
                                for (const subcategory of category.subcategories) {
                                    if(subcategory._id == element.categoryId) {
                                        if(!subcategory.products)
                                            subcategory.products = []

                                        subcategory.products.push(element)
                                        found = true
                                        break
                                    }
                                }
                            }
                        }
                        if(!found) {
                            const categoryResp = await api.getCatalogCategoryById(this.operator.channelId, element.categoryId)
                            if(categoryResp?.result?.statusCode == 200 && categoryResp.result.category?._id) {
                                const category = categoryResp.result.category
                                category.products = [ element ]
                                if(!category.topCategoryId) {
                                    categories.push(category)
                                } else {
                                    const categoryIndex = categories.findIndex(el => el._id == category.topCategoryId)
                                    if(categoryIndex >= 0) {
                                        if(!categories[categoryIndex].subcategories)
                                            categories[categoryIndex].subcategories = []
                                        categories[categoryIndex].subcategories.push(category)
                                    } else {
                                        const topCategoryResp = await api.getCatalogCategoryById(this.operator.channelId, category.topCategoryId)
                                        if(topCategoryResp?.result?.statusCode == 200 && topCategoryResp.result.category?._id) {
                                            const topCategory = topCategoryResp.result.category
                                            topCategory.subcategories = [ category ]
                                            categories.push(topCategory)
                                        }
                                    }
                                }
                            } 
                        }
                    }
                }
                if(categories.length) {
                    // console.log({categories})
                    categories = categories.filter(el => el.visible).map(category => {
                        if(category.products?.length) {
                            category.products = category.products
                            if(category.subcategories?.length) {
                                category.subcategories = category.subcategories.map(subcategory => {
                                    if(subcategory.products?.length)
                                        subcategory.products = subcategory.products
                                    return subcategory
                                })
                            }
                        }
                        return category
                    })
                }
            }

            // console.log('categories search',categories)

            this.catalogCategories = []

            if(categories.length) {
                const all = { _id: "all", name: "Todas as opções", products: [], visible: true }
                categories.forEach(el => {
                    if(el.products?.length)
                        all.products.push(...el.products)
                    
                    if(el.subcategories) {
                        el.subcategories.forEach(sub => {
                            if(sub.products?.length)
                                all.products.push(...sub.products)
                        })
                    }
                })
                this.catalogCategories.unshift(all)
            }

            this.catalogCategories.push(...categories?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

            this.loadingCategories = false
            if(!this.hasSearchedCatalogSearch)
                this.hasSearchedCatalogSearch = true
        },
        nestCategory(categories, element) {
            for(let index = 0; index < categories.length; index++) {
                if(categories[index]._id == element.topCategoryId) {
                    categories[index].subcategories.push(element)
                    // if(categories[index].topCategoryId) {
                    //     console.log(categories[index].name,categories[index].topCategoryId)
                    // }
                } else if(categories[index].subcategories?.length) {
                    this.nestCategory(categories[index].subcategories, element)
                }
            }
        },
        refreshClientSelected() {
            const clientName = this.clientSelected.clientName ?? this.clientSelected.name

            if (!clientName) return

            this.$set(this.clientSelected, clientName ? 'clientName' : 'name', clientName.trim());
        },
        refreshSelectedTemplate() {
            this.$set(this.selectedTemplate,"refresh",1)
            delete this.selectedTemplate.refresh
        },
        async sendProductsList() {
            this.sendingProductsList = true
            
            const resp = await api.sendProductsList(this.operator.channelId,{ protocol: this.clientSelected.protocol, products: this.clientSelected.productsList  })
            // console.log('resp',resp)
            if(resp.result?.statusCode == 200) {
                const date = new Date(Date.now())
                const timestamp = (date.getTime() + date.getTimezoneOffset()) / 1000

                const msg = {
                    body: {
                        header: {
                            text: "Minha Lista",
                            type: "text"
                        },
                        body: {
                            text: "Lista de Produtos"
                        },
                        imageURL: this.clientSelected.productsList.at(0).imageLink,
                        productList: this.clientSelected.productsList,
                    },
                    fromMe: true,
                    timestamp,
                    statusAck: 0,
                    type: 'product_list'
                }
                this.clientSelected.msgs.push(msg)

                this.clientSelected.productsList = []

                this.refreshClientSelected()

                for (let i = 0; i < this.catalogProducts.length; i++) {
                    this.catalogProducts[i].listed = false
                }
                this.refreshCatalogProducts()

                this.$refs['productsList'].hide()
            } else {
                console.error('resp',resp)
                this.$emit("msg", {
                    text: "Ocorreu um erro ao enviar lista!",
                    success: false
                })
            }
            this.sendingProductsList = false
        },
        async sendProductsCart() {
            this.sendingProductsCart = true

            const resp = await api.sendProductsCart(this.operator.channelId,{ 
                protocol: this.clientSelected.protocol, 
                products: this.clientSelected.productsCart.filter(el => !el.unavailable), 
                contactId: this.clientSelected.contactId,
                operatorName: this.operator.name,
                operatorNickame: this.operator.nickname,
            })
            // console.log('resp',resp)
            if(resp.result?.statusCode == 200) {
                this.clientSelected.productsCart = []

                // const date = new Date(Date.now())
                // const timestamp = (date.getTime() + date.getTimezoneOffset()) / 1000

                // const msg = {
                //     fromMe: true,
                //     timestamp,
                //     statusAck: 0,
                //     type: 'product_cart'
                // }
                // this.clientSelected.msgs.push(msg)
                // this.refreshClientSelected()

                for (let i = 0; i < this.catalogProducts.length; i++) {
                    this.catalogProducts[i].carted = false;
                }
                this.refreshCatalogProducts()

                this.$refs['productsCart'].hide()
            } else {
                console.error('resp',resp)
                this.$emit("msg", {
                    text: "Ocorreu um erro ao enviar o carrinho!",
                    success: false
                })
            }

            this.sendingProductsCart = false
        },
        async getProducts(page = 1) {
            if(!this.catalogFilters.selectedCategoryId && !this.catalogFilters.selectedSubcategoryId) {
                return this.$emit("msg", {
                    text: "Categoria não selecionada!",
                    success: false
                })
            }

            this.loadingProducts = true

            if(page == 1) {
                this.catalogProducts = []
                this.catalogProducts.count = 0
            }

            // console.log("page",page)

            const resp = await api.getCatalogProductsPagination(this.operator.channelId, this.catalogFilters.selectedSubcategoryId || this.catalogFilters.selectedCategoryId, {hidden: false}, page)
            // console.log("resp",resp)
            if(resp.statusCode == 200) {
                if(page == 1) {
                    this.catalogProducts = resp.products
                    this.catalogProducts.count = resp.count
                } else {
                    this.catalogProducts.push(...resp.products)
                    this.catalogProducts.count = resp.count
                }
            }

            this.loadingProducts = false
            this.hasSearchedCatalogCategory = true

            if(this.currentProductsPage != page)
                this.currentProductsPage = page     
        },
        handleScrollCatalogProducts(element) {
            const thresholdPercentage = 0.7
            const isAtBottom = element.target.scrollTop + element.target.offsetHeight >= thresholdPercentage * element.target.scrollHeight
            // console.log(element.target.scrollTop + element.target.offsetHeight, element.target.scrollHeight - 5)
            if (isAtBottom) {
                // console.log("Scroll at bottom",this.catalogProducts);
                if(this.catalogProducts.length < this.catalogProducts.count)
                    this.getProducts(this.currentProductsPage + 1)
            }
        },
        handleScrollAttendance(element) {
            const thresholdPercentage = 0.7
            const isAtBottom = element.target.scrollTop + element.target.offsetHeight >= thresholdPercentage * element.target.scrollHeight
            // console.log(element.target.scrollTop + element.target.offsetHeight, thresholdPercentage * element.target.scrollHeight)
            if (this.attendanceType === 'contacts' && isAtBottom) {
                console.log("Scroll at bottom", this.attendances.length, this.totalAttendances)
                if(this.attendances.length < this.totalAttendances) {
                    if(this.attendances.length < this.attendancesPerPage) {
                        this.getAttendances2(1)
                    } else if(this.attendances.length < this.attendancesPerPage * this.currentAttendancesPage) {
                        this.getAttendances2(this.currentAttendancesPage)
                    } else {
                        this.getAttendances2(this.currentAttendancesPage + 1)
                    }
                }
            }
        },
        handleScrollRetrieveContacts(element) {
            const thresholdPercentage = 0.7
            const isAtBottom = element.target.scrollTop + element.target.offsetHeight >= thresholdPercentage * element.target.scrollHeight

            if (!this.retrieveContactsSearch && isAtBottom && this.retrieveContacts.length < this.retrieveContactsTotal) {
                const currentPage = Math.ceil(this.retrieveContacts.length / this.retrieveContactsPerPage) + 1;
                this.getRetrieveContacts(this.retrieveTabIndex + 1, true, currentPage)
            }
        },
        handleScrollCalendarContacts(element) {
            const thresholdPercentage = 0.7
            const isAtBottom = element.target.scrollTop + element.target.offsetHeight >= thresholdPercentage * element.target.scrollHeight

            if (!this.retrieveContactsSearch && isAtBottom && this.retrieveContacts.length < this.retrieveContactsTotal) {
                const currentPage = Math.ceil(this.retrieveContacts.length / this.retrieveContactsPerPage) + 1;
                this.getRetrieveContacts(this.retrieveTabIndex + 1, true, currentPage)
            }
        },
        async getProductByProductId(productId) {
            if(productId) {
                const resp = await api.getCatalogProductByProductId(this.operator.channelId, productId)
                if(resp.result?.statusCode == 200)
                    return resp.result.product
            }
            return
        },
        removeProductFromCart(product) {
            this.clientSelected.productsCart = this.clientSelected.productsCart.filter(e => e != product)
            product.carted = false
            this.catalogCategories = this.setProductCartedFalse(product, this.catalogCategories)
            this.refreshClientSelected()
            this.refreshCatalogProducts()
        },
        removeProductFromList(product) {
            this.clientSelected.productsList = this.clientSelected.productsList.filter(e => e != product)
            product.listed = false
            this.catalogCategories = this.setProductListedFalse(product, this.catalogCategories)
            this.refreshClientSelected()
            this.refreshCatalogProducts()
        },
        setProductCartedFalse(product, categories) {
            return categories.map(category => {
                if(category.products?.length) {
                    category.products = category.products.map(el => {
                        if(el._id == product._id) {
                            el.carted = false
                        }
                        return el
                    })
                }
                if(category.subcategories?.length)
                    category.subcategories = this.setProductCartedFalse(product, category.subcategories)
                return category
            })
        },
        setProductListedFalse(product, categories) {
            return categories.map(category => {
                if(category.products?.length) {
                    category.products = category.products.map(el => {
                        if(el._id == product._id) {
                            el.listed = false
                        }
                        return el
                    })
                }
                if(category.subcategories?.length)
                    category.subcategories = this.setProductListedFalse(product, category.subcategories)
                return category
            })
        },
        async checkProductQuantity(product) {
            let quantity = await this.getProductStockQuantity(product.id)
            if(isNaN(quantity))
                quantity = 1
            if(quantity <= product.quantity) {
                this.$emit("msg", {
                    text: `Apenas ${quantity} produto(s) em estoque!`,
                    success: false
                })
                product.quantity = quantity
                this.refreshClientSelected()
            }
            return quantity
        },
        async productQuantity(product, sum) {
            if(sum) {
                const quantity = await this.checkProductQuantity(product)
                if(quantity > product.quantity)
                    product.quantity++
                else {
                    this.$emit("msg", {
                        text: "Quantidade em estoque atingida!",
                        success: false
                    })
                }
            } else if(product.quantity > 1)
                product.quantity--
            this.refreshClientSelected()
        },
        async getProductStockQuantity(productId) {
            if(productId) {
                const resp = await api.getCatalogProductByProductId(this.operator.channelId, productId)
                if(resp.result?.statusCode == 200)
                    return resp.result.product.quantityToSellOnFacebook
            }
            return 1
        },
        openLink(url) {
            if(url) {
                if(!url.startsWith("http"))
                    url = `https://${url}`
                window.open(url,'_blank');
            }
        },
        setForwardMessage(message) {
            this.forwardMessage = message
            this.getRetrieveContacts(this.retrieveTabIndex + 1, true)
            this.$bvModal.show("forwardMessagePickContacts")
        },
        checkContactToForward(contact) {
            this.contactsToForward.push({...contact})
        },
        uncheckContactToForward(contact) {
            this.contactsToForward = this.contactsToForward.filter(e => e._id != contact._id)
        },
        async forwardMessageToContacts() {
            if(this.forwardMessage) {
                const promise = this.contactsToForward.map(async contact => {
                    let attendance
                    if(this.attendanceType === 'contacts') {
                        attendance = this.attendances.find(el => el.clientNumber == contact.number)
                    } else {
                        const resp = await api.getAttendances(this.operator._id)
                        if(resp.statusCode == 200)
                            attendance = resp.attendances.find(el => el.clientNumber == contact.number)
                        this.pickAttendanceType('contacts')
                    }

                    if(!attendance) {
                        attendance = await this.startAttendance(contact)
                    } else {
                        this.sendMsg2(attendance, this.forwardMessage)
                    }
                    // if(!attendance) channel has secondary channels, the message will be sent on method startAttendance
                })
                await Promise.all(promise)
                this.$bvModal.hide("forwardMessagePickContacts")
                this.contactsToForward = []
            }
        },
        async getChannelType(channelId) {
            const channelResp = await api.getChannel(channelId)
            if(channelResp?.statusCode == 200) {
                return channelResp.channel.channel.apiType
            }
            return
        },
        setCountryOptions() {
            const countryOptions = countriesOptionsJSON
                .filter((arr, index, self) => index === self.findIndex((t) => t.isoCode === arr.isoCode))
                .map(el => ({ ...el, label: this.getCountryName(el.code) }))
                .map(el => el.label !== 'Estados Unidos' && el.value === '1' ? null : el)
                .filter(el => el !== null)
            this.countryOptions = countryOptions.sort((a,b) => a.label.localeCompare(b.label))
        },
        getCountryName(countryCode) { // returns the name of the country by the received country code
            if(countryCode) {
                const d = new Intl.DisplayNames(
                    ['pt-BR'], {type: 'region'}
                )
                return d.of(countryCode)
            }
        },
        setSelectedOption (options, value) {
            console.log({options, value})
            if(value.value === undefined) {
                const option = options.find(x => x.value.toString() === value.label)
                return option.label
            } else {
                return value.label
            }
        },
        async selectProductList(obj) {
            this.selectedProductList = obj

            if(!obj.productList?.at(0)?.displayTitle) {
                const list = []
                for (const el of obj.productList) {
                    const product = await this.getProductByProductId(el.productId)
                    list.push(product)
                }
                obj.productList = list
            }
                
            this.selectedProductList = obj
        },
        async selectProductCart(obj) {
            // this.selectedProductCart = obj

            // if(!obj.productCart?.at(0)?.displayTitle) {
            //     const cart = []
            //     for (const el of obj.productCart) {
            //         const product = await this.getProductByProductId(el.productId) // bring info different than the one in obj
            //         cart.push(product)
            //     }
            //     obj.productCart = cart
            // }
                
            this.selectedProductCart = obj
        },
        calculateScreenHeightAttendancesCount() {
            const container = document.querySelector('.clientsWrapper');
            const listItem = document.querySelector('.attendancesRow');

            if (container && listItem) {
                const containerHeight = container.offsetHeight;
                const listItemHeight = listItem.offsetHeight;

                const nItemsFitOnScreen = Math.floor(containerHeight / listItemHeight)
                if(nItemsFitOnScreen > this.attendances.length && this.attendances.length < this.totalAttendances) {
                    console.log('resize load')
                    this.getAttendances2(this.currentAttendancesPage + 1)
                }
            }
        },
        calculateScreenHeightRetrieveContactsCount() {
            const container = document.querySelector('#retrieveContactsWrapper');
            const listItem = document.querySelector('#retrieveContactsRow');

            if (container && listItem) {
                const containerHeight = container.offsetHeight;
                const listItemHeight = listItem.offsetHeight;

                const nItemsFitOnScreen = Math.floor(containerHeight / listItemHeight)
                if(nItemsFitOnScreen > this.retrieveContacts.length && this.retrieveContacts.length < this.retrieveContactsTotal) {
                    console.log('resize load')
                    this.getRetrieveContacts(this.retrieveTabIndex + 1, true, this.retrieveContactsPage + 1)
                }
            }
        },
        calculateScreenHeightCalendarContactsCount() {
            const container = document.querySelector('#calendarContactsWrapper');
            const listItem = document.querySelector('#calendarContactsRow');

            if (container && listItem) {
                const containerHeight = container.offsetHeight;
                const listItemHeight = listItem.offsetHeight;

                const nItemsFitOnScreen = Math.floor(containerHeight / listItemHeight)
                if(nItemsFitOnScreen > this.retrieveContacts.length && this.retrieveContacts.length < this.retrieveContactsTotal) {
                    console.log('resize load')
                    this.getRetrieveContacts(3, true, this.retrieveContactsPage + 1)
                }
            }
        },
        retrieveTabChange(tabIndex) {
            if (tabIndex !== 3)
                this.getRetrieveContacts(tabIndex + 1, true)
        },
        toggleCrmData() {
            this.showCrmData = !this.showCrmData
            if(this.clientData)
                this.clientData = false

            if(window.innerWidth <= 425) {
                const col2 = document.getElementById('col2')
                const col3 = document.getElementById('col3')
                if (this.showCrmData) {
                    if(col2)
                        col2.style.flex = '0'
                    if(col3) {
                        col3.style.display = 'block'
                        col3.style.flex = '1'
                    }
                } else {
                    if(col2)
                        col2.style.flex = '1'
                    if(col3) {
                        col3.style.display = 'none'
                        col3.style.flex = '0'
                    }
                }
            }
        },
        async getClientCRMData() {
            this.loadingClientCRMData = true

            if(!this.clientSelected.rdStationId) {
                const contactResp = await api.getContact(this.clientSelected.contactId)
                if(contactResp.statusCode == 200) {
                    this.$set(this.clientSelected, "rdStationId", contactResp.contact.rdStationId)
                }
            }

            if(this.clientSelected.rdStationId) {
                const data = await api.rdGetContact(this.clientSelected.rdStationId)
                if(data.statusCode === 200) {
                    this.clientSelected.crmData = data.contact
                }
            } else {
                const contact = {
                    contact: {
                        name: this.clientSelected.clientName,
                        phones: [{
                            phone: this.clientSelected.clientNumber
                        }]
                    }
                }
                const create = await api.rdCreateContact(contact, this.operator.channelId)
                if(create.statusCode === 201) {
                    this.clientSelected.crmData = create.contact
                    this.clientSelected.rdStationId = create.contact.rdStationId || create.contact.id
                } else {
                    console.error(create)
                }
            }

            const orgs = await api.rdGetOrganizations(this.operator.channelId, 1)
            if(orgs.statusCode == 200 && orgs.organizations?.organizations) {
                if(this.clientSelected.crmData?.organization) {
                    if(!orgs.organizations.organizations.find(el => el._id == this.clientSelected.crmData.organization._id))
                        orgs.organizations.organizations.push(this.clientSelected.crmData.organization)
                } else {
                    // this.clientSelected.crmData.organization = orgs.organizations.organizations.at(0)
                    // this.clientSelected.crmData.organization_id = orgs.organizations.organizations.at(0)?._id
                }
                this.clientSelected.crmOrganizations = orgs.organizations.organizations
            } else {
                if(this.clientSelected.crmData?.organization)
                    this.clientSelected.crmOrganizations = [ this.clientSelected.crmData?.organization ]
            }

            
            this.loadingClientCRMData = false
            this.rdGetDealPipelines()

            if(this.clientSelected.crmData?.deal_ids?.length) {
                await this.rdGetDeals()

                if(this.clientSelected.crmData?.organization_id) {
                    this.rdFilterDeals(this.clientSelected.crmData.organization_id)
                    if(this.clientSelected.crmDeals?.length == 1) {
                        this.clientSelected.crmData.deal = this.clientSelected.crmDeals[0]
                        if(this.clientSelected.crmData.deal.user?._id)
                            this.clientSelected.crmData.deal.user_id = this.clientSelected.crmData.deal.user._id
                        this.rdPickedDeal(this.clientSelected.crmData.deal)
                    }
                }
            }
            this.rdGetUsers()
            this.rdGetCampaigns()
            this.rdGetDealSources()
        },
        async rdGetDeals() {
            this.loadingCRMDeals = true
            const crmDeals = []

            if(this.clientSelected.crmData.deal_ids?.length) {
                await Promise.all(this.clientSelected.crmData.deal_ids.map(async (_id) => {
                    const deal = await api.rdGetDeal(_id, this.operator.channelId);
                    if (deal.statusCode === 200 && !crmDeals.includes(deal.deal)) {
                        crmDeals.push(deal.deal)
                    }
                }))
            }

            this.$set(this.clientSelected, "crmDeals", crmDeals);
            this.loadingCRMDeals = false
        },
        async rdGetDealPipelines(search = '') {
            this.loadingCRMDealPipelines = true

            const pipelines = await api.rdGetDealPipelines(this.operator.channelId, 1)
            if (pipelines.statusCode === 200 || pipelines.statusCode === 201) {
                this.$set(this.clientSelected, "crmDealPipelines", pipelines.dealPipelines);
                if(this.clientSelected.crmDealPipelines?.length && search)
                    this.clientSelected.crmDealPipelines = this.clientSelected.crmDealPipelines.filter(e => e.name.toLowerCase().includes(search))
            } else {
                this.$set(this.clientSelected, "crmDealPipelines", []);
            }

            this.loadingCRMDealPipelines = false
        },
        async rdGetCampaigns(search = '') {
            this.rdLoadingCampaigns = true

            const filters = {}
            if(search)
                filters.q = search

            const resp = await api.rdGetCampaigns(this.operator.channelId, 1, filters)
            // console.log({pipelines})
            if (resp.statusCode === 200 || resp.statusCode === 201) {
                this.rdCampaigns = resp.campaigns
            } else {
                this.rdCampaigns = []
            }

            this.rdLoadingCampaigns = false
        },
        async rdGetDealSources(search = '') {
            this.rdLoadingDealSources = true

            const filters = {}
            if(search)
                filters.q = search

            const resp = await api.rdGetDealSources(this.operator.channelId, 1, filters)
            // console.log({pipelines})
            if (resp.statusCode === 200 || resp.statusCode === 201) {
                this.rdDealSources = resp.deal_sources
            } else {
                this.rdDealSources = []
            }

            this.rdLoadingDealSources = false
        },
        async rdUpdateContact(contact = null) {
            if(!contact)
                contact = this.clientSelected.crmData

            const update = await api.rdUpdateContact(this.operator.channelId, this.clientSelected.rdStationId, { contact })
            // console.log({ update })
            if(update.statusCode != 200) {
                console.error("rdUpdateContact", update)
            }

            return update
        },
        async rdOrgChanged(e) {
            this.clientSelected.crmData.deal = null
            const update = await this.rdUpdateContact(this.clientSelected.crmData)

            if(update.statusCode == 200) {
                this.clientSelected.crmData.organization = update.updatedContact.organization
                this.$root.$emit('bv::toggle::collapse', 'collapse-crmOrganization')
            }

            await this.rdGetDeals()
            this.rdFilterDeals(e)
            // console.log(this.clientSelected.crmDeals)
            if(this.clientSelected.crmDeals?.length == 1) {
                this.clientSelected.crmData.deal = this.clientSelected.crmDeals[0]
                this.rdPickedDeal(this.clientSelected.crmData.deal)
            }
        },
        rdFilterDeals(e) {
            if(e && this.clientSelected.crmDeals)
                this.clientSelected.crmDeals = this.clientSelected.crmDeals.filter(deal => deal.organization?._id == e)
        },
        async rdCreateOrganization(contactCrmData) {
            if(!this.rdCreatingOrganization) {
                this.rdCreatingOrganization = true
                
                const create = await api.rdCreateOrganization(this.newRDOrganization, this.operator.channelId)
                // console.log({create})
                this.rdCreatingOrganization = false
                
                if(create.statusCode == 201) {
                    this.$emit("msg", {
                        text: "Cliente cadastrado com sucesso!",
                        success: true
                    })
    
                    this.$bvModal.hide("rdCreateOrganization")
    
                    this.newRDOrganization = {}

                    if(contactCrmData) {
                        contactCrmData.organization_id = create.organization._id
                        contactCrmData.deal = null
                        this.clientSelected.crmOrganizations = [ create.organization ]
                        this.rdUpdateContact(contactCrmData)
                    }
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro!",
                        success: false
                    })
                }
            }
        },
        async rdCreateDeal(contactCrmData) {
            if(!this.rdCreatingDeal) {
                this.rdCreatingDeal = true

                const stages = await api.rdGetDealStages(this.operator.channelId, 1)
                if(stages.statusCode == 201 || stages.statusCode == 200) {
                    if(stages.dealStages?.deal_stages?.length) {
                        const stage = stages.dealStages.deal_stages.find(el => el.order == 1)
                        this.newRDDeal.deal.deal_stage_id = stage._id
                    }
                }

                if(contactCrmData?.organization_id)
                    this.newRDDeal.organization._id = contactCrmData.organization_id
                else
                    this.newRDDeal.organization = undefined

                const create = await api.rdCreateDeal(this.newRDDeal, this.operator.channelId)
                // console.log({create})
                this.rdCreatingDeal = false
                
                if(create.statusCode == 201) {
                    this.newRDDeal = {
                        deal: {},
                        organization: {},
                    }

                    this.$emit("msg", {
                        text: "Negócio cadastrado com sucesso!",
                        success: true
                    })
    
                    this.$bvModal.hide("rdCreateDeal")
    
                    if(contactCrmData) {
                        contactCrmData.deal_id = create.deal._id

                        if(!contactCrmData.deal_ids)
                            contactCrmData.deal_ids = []

                        contactCrmData.deal_ids.push(create.deal._id)

                        if(!this.clientSelected.crmDeals) {
                            this.clientSelected.crmDeals = []
                        }

                        this.clientSelected.crmDeals.push(create.deal)

                        this.rdPickedDeal(create.deal)
                        this.rdUpdateContact(contactCrmData)
                        contactCrmData.deal = create.deal
                        contactCrmData.selectedDeal = create.deal
                    }
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro!",
                        success: false
                    })
                }
            }
        },
        orgSegmentValidator(segment) {
            // Individual segment validator function
            return segment.length > 2
        },
        async rdGetProducts() {
            const products = await api.rdGetProducts(this.operator.channelId, 1)
            // console.log({products})
            if(products.statusCode == 200) {
                const prods = []

                if(products.products) {
                    for (const product of products.products.products) {
                        if(!product.price)
                            product.price = product.base_price.replace('.',',')
                        prods.push(product)
                    }
                }

                this.$set(this.clientSelected, "crmProducts", prods)
            }
        },
        async rdUpdateProduct($event, product, dealId) {
            // console.log($event, product)
            if($event && product.amount && product.price) {
                product.price = product.price.toString().replace(',','.')
                const update = await api.rdUpdateProduct(this.operator.channelId, product, dealId)
                // console.log(update)
            }
        },
        async rdPickedDeal(deal, setFirstStage = false) {
            if(deal) {
                await this.rdGetDealStagesByPipelineId(deal?.deal_stage?.deal_pipeline_id, setFirstStage)

                if(!this.clientSelected.crmData.deal_ids) {
                    this.clientSelected.crmData.deal_ids = []
                }
    
                if(!this.clientSelected.crmData?.deal_ids.includes(deal._id)) {
                    this.clientSelected.crmData.deal_ids.push(deal._id)
                    this.rdUpdateContact()
                }
    
                this.refreshClientSelected()
            }
        },
        async rdPickedDealPipeline(deal) { // just to know if pipeline has changed
            if(!this.rdDealpipelineChanged) {
                this.rdDealPipelineChanged = true
                this.rdPreviousDealPipelineId = deal
            }
        },
        async rdGetDealStagesByPipelineId(pipelineId = null, setFirstStage = false) {
            let stages

            if(pipelineId) {
                stages = await api.rdGetDealStagesByPipelineId(this.operator.channelId, pipelineId)
            } else {
                stages = await api.rdGetDealStages(this.operator.channelId, 1)
            }
            // console.log({stages})
            if(stages.statusCode == 201 || stages.statusCode == 200) {
                this.$set(this.clientSelected, "crmDealStages", stages.dealStages?.deal_stages)
            }

            if(setFirstStage && stages.dealStages?.deal_stages.length) {
                this.rdSavingDealPipeline = true

                const update = await this.rdUpdateStage2(this.clientSelected.crmData, stages.dealStages.deal_stages[0]._id)
                if(update?.statusCode == 200) {
                    // this.$emit("msg", { text: "Funil alterado com sucesso!", success: true })
                } else {
                    this.$emit("msg", { text: "Ocorreu um erro", success: false })
                }

                this.rdSavingDealPipeline = false
            }

            this.refreshClientSelected()
        },
        async rdUpdateStage(crmData = null) {
            if(!crmData)
                crmData = this.clientSelected.crmData

            // console.log({crmData})

            if(crmData.deal.deal_stage._id) {
                const update = await api.rdUpdateDealStage({ dealStageId: crmData.deal.deal_stage._id }, crmData.deal._id, this.operator.channelId)
                // console.log({ update })
                if(update.statusCode != 200) {
                    console.error("rdUpdateStage", update)
                }
            }
        },
        async rdUpdateStage2(crmData = null, dealStageId) {
            if(!crmData)
                crmData = this.clientSelected.crmData

            if(dealStageId) {
                const update = await api.rdUpdateDealStage({ dealStageId }, crmData.deal._id, this.operator.channelId)
                // console.log({ update })
                if(update.statusCode != 200) {
                    // console.error("rdUpdateStage2", update)
                } else {
                    crmData.deal.deal_stage = update.updatedDeal.deal_stage
                    this.refreshClientSelected()
                }
                return { statusCode: update.statusCode }
            }
            return { statusCode: 400 }
        },
        async rdUpdateDeal(crmData = null) {
            if(!crmData)
                crmData = this.clientSelected.crmData

            crmData.deal.deal_pipeline_id = crmData.deal.deal_stage.deal_pipeline_id

            if(crmData.deal) {
                const update = await api.rdUpdateDeal(crmData.deal, crmData.deal._id, this.operator.channelId)
                // console.log({ update })
                if(update.statusCode != 200) {
                    console.error("rdUpdateSDeal", update)
                } else {
                    crmData.deal = update.updatedDeal
                }
            }
        },
        async rdAssociateProduct(crmData = null) {
            if(!crmData)
                crmData = this.clientSelected.crmData

            if(!crmData.deal.deal_products)
                crmData.deal.deal_products = []

            const product = {
                name: crmData.deal.product.name,
                price: crmData.deal.product.price.replace(',','.'),
                amount: crmData.deal.product.amount || 1,
                product_id: crmData.deal.product._id
            }
            
            if(crmData.deal.deal_stage._id) {
                const create = await api.rdCreateProductInDeal(product, crmData.deal._id, this.operator.channelId)
                // console.log({ create })
                if(create.statusCode != 200) {
                    console.error("rdAssociateProduct", create)
                    this.$emit("msg", {
                        text: "Ocorreu um erro!",
                        success: false
                    })
                } else {
                    product.price = product.price.replace('.',',')
                    crmData.deal.deal_products.push(product)
                    this.$emit("msg", {
                        text: "Produto associado com sucesso!",
                        success: true
                    })
                    this.$bvModal.hide('rdAssociateProduct')
                    crmData.deal.product = null
                }
            }
        },
        async rdSearchOrganizations(search) {
            this.loadingCRMOrganizations = true
            if(search) {
                const organizations = []
                const orgs = await api.rdGetOrganizationsSearch(this.operator.channelId, search)
                if(orgs.statusCode == 200 && orgs.organizations.organizations) {
                    organizations.push(...orgs.organizations.organizations)
                    if(this.clientSelected.crmData.organization) 
                        organizations.push({ ...this.clientSelected.crmData.organization, hidden: true })
                } else {
                    if(this.clientSelected.crmData.organization)
                        organizations.push({ ...this.clientSelected.crmData.organization, hidden: true })
                }
                this.$set(this.clientSelected, "crmOrganizations", organizations)
            }
            this.loadingCRMOrganizations = false
        },
        async rdSearchDeals(search) {
            if(search) {
                const deals = await api.rdGetDealsSearch(this.operator.channelId, search)
                if(deals.statusCode == 200 && deals.deals.deals) {
                    this.$set(this.clientSelected, "crmDeals", deals.deals.deals)
                } else {
                    this.$set(this.clientSelected, "crmDeals", [])
                }
            }
        },
        rdWinChanged($event, newValue) {
            if(this.clientSelected.crmData.deal.oldWin == undefined)
                this.clientSelected.crmData.deal.oldWin = this.clientSelected.crmData.deal.win
            this.clientSelected.crmData.deal.win = newValue
        },
        async rdUpdateWin() {
            this.clientSelected.crmData.deal.oldWin = undefined; 
            await this.rdUpdateDeal(this.clientSelected.crmData)
            this.refreshClientSelected()
        },
        rdWinCanceled() {
            this.clientSelected.crmData.deal.win = this.clientSelected.crmData.deal.oldWin == undefined ? this.clientSelected.crmData.deal.win : this.clientSelected.crmData.deal.oldWin
        },
        async rdCreateAnnotation() {
            if(this.newRDAnnotation.text) {
                this.rdCreatingAnnotation = true
                this.newRDAnnotation.deal_id = this.clientSelected.crmData?.deal?._id
                const annotation = {
                    activity: this.newRDAnnotation
                }
                // if(this.operator.rdInstanceToken)
                //     this.newRDAnnotation.user_id = 
                const createResp = await api.rdCreateAnnotation(annotation, this.operator.channelId)
                this.rdCreatingAnnotation = false
                console.log({ createResp })
                if(createResp.statusCode == 201) {
                    this.$bvModal.hide('rdCreateAnnotation')
                    this.$emit("msg",{ text: "Anotação criada com sucesso!", success: true })
                } else {
                    this.$emit("msg",{ text: "Ocorreu um erro!" })
                }
            }
        },
        async rdCreateAnnotationFromMessages() {
            if(this.clientSelected.crmData.deal.msgsToAnnotate?.length) {
                this.rdCreatingAnnotation = true
                let text = `Protocolo: ${this.clientSelected.protocol}\n\n`
                text += this.clientSelected.crmData.deal.msgsToAnnotate.join('\n')
                const annotation = {
                    activity: {
                        deal_id: this.clientSelected.crmData?.deal?._id,
                        text,
                    }
                }
                const createResp = await api.rdCreateAnnotation(annotation, this.operator.channelId)
                this.rdCreatingAnnotation = false
                if(createResp.statusCode == 201) {
                    this.$bvModal.hide('rdSelectMsgsAnnotation')
                    this.clientSelected.crmData.deal.contactInfoPermission = false
                    this.clientSelected.crmData.deal.msgsToAnnotate = []
                    this.$emit("msg",{ text: "Anotação criada com sucesso!", success: true })
                } else {
                    this.$emit("msg",{ text: "Ocorreu um erro!" })
                }
            }
        },
        async rdGetAnnotations(page = 1) {
            if(this.clientSelected.crmData.deal) {
                this.rdLoadingAnnotations = true
                const annotationsResp = await api.rdGetAnnotations(this.operator.channelId, this.clientSelected.crmData.deal._id, page)
                this.rdLoadingAnnotations = false
                // console.log({annotationsResp})
                if(annotationsResp.statusCode == 200) {
                    this.clientSelected.crmData.deal.annotations = annotationsResp.annotations
                    this.clientSelected.crmData.deal.totalAnnotations = annotationsResp.total || 0
                } else {
                    this.$emit("msg", { text: "Ocorreu um erro ao carregar anotações!" })
                }

                if(this.rdCurrentAnnotationsPage != page)
                    this.rdCurrentAnnotationsPage = page
            }
        },
        rdCheckAllMessagesToAnnotate() {
            const msgsToAnnotate = []
            for (const msg of this.clientSelected.msgs) {
                if (msg.type == 'chat' || msg.type == 'text') {
                    let text = `[${this.$options.filters.date(this.handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] `
                    text += `${msg.fromMe ? this.operator.name : this.clientSelected.clientName}: `
                    text += msg.body
                    msgsToAnnotate.push(text)
                } else if(msg.type == 'image' || msg.type == 'video'
                        || msg.type == 'ptt' || msg.type == 'audio'
                        || msg.type == 'file' || msg.type == 'document' || msg.type == 'application') {
                    let text = `[${this.$options.filters.date(this.handleTimestamp(msg.timestamp),'DD/MM/YY HH:mm:ss')}] `
                    text += `${msg.fromMe ? this.operator.name : this.clientSelected.clientName}: `
                    text += this.hasFullPath(msg.mediaURL)
                    if(msg.caption)
                        text += ` ${msg.caption}`
                    msgsToAnnotate.push(text)
                }
            }
            this.$set(this.clientSelected.crmData.deal, "msgsToAnnotate", msgsToAnnotate)
        },
        rdUncheckAllMessagesToAnnotate() {
            this.clientSelected.crmData.deal.msgsToAnnotate = []
        },
        rdAnnotationsPageChange(page) {
            if(page != this.currentPage) {
                this.rdCurrentAnnotationsPage = page
                this.rdGetAnnotations(page)
            }
        },
        async rdCreateTask() {
            this.rdCreatingTask = true
            if(this.clientSelected.crmData.deal._id) {
                if(!this.rdNewTask.type)
                    this.rdNewTask.type = 'task'
    
                if(!this.rdNewTask.deal_id)
                    this.rdNewTask.deal_id = this.clientSelected.crmData.deal._id
    
                const resp = await api.rdCreateTask(this.rdNewTask, this.operator.channelId)
                if(resp.statusCode == 201) {
                    this.$emit("msg", {
                        text: 'Tarefa cadastrada com sucesso!',
                        success: true
                    })
                    this.$bvModal.hide('rdCreateTask')
                } else {
                    this.$emit("msg", {
                        text: 'Ocorreu um erro!',
                        success: false
                    })
                }
            } else {
                this.$emit("msg", {
                    text: 'Ocorreu um erro, tente novamente!',
                    success: false
                })
            }
            this.rdCreatingTask = false
        },
        async rdGetTasks(page = 1, done = "false") {
            this.rdLoadingTasks = true
            const resp = await api.rdGetTasks(this.operator.channelId, page, { deal_id: this.clientSelected.crmData.deal._id, done })
            this.rdLoadingTasks = false
            // console.log({resp})
            if(resp.statusCode == 200) {
                resp.tasks.tasks = resp.tasks.tasks.map(el => {
                    if(!el.done && new Date(el.date).getTime() < new Date().getTime()) {
                        el.pending = true
                    }
                    return el
                })
                this.rdTasks = resp.tasks.tasks.sort((a, b) => a.date - b.date)
                this.rdTasksTotal = resp.tasks.total
            } else {
                this.rdTasks = []
            }

            if(this.rdCurrentTasksPage != page)
                this.rdCurrentTasksPage = page
        },
        async rdSelectTask(task) {
            task.date = task.date.split('T')[0]
            this.rdSelectedTask = task
        },
        async rdSetTagAsDone(task) {
            task.done = "true"
            const resp = await api.rdUpdateTask(task, this.operator.channelId)
            if(resp.statusCode == 200) {
                this.$emit("msg", {
                    text: "Tarefa concluída com sucesso!",
                    success: true
                })
                this.rdTasks = this.rdTasks.filter(el => el._id != task._id)
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro!"
                })
            }
        },
        async rdUpdateTask(task = null) {
            if(!task)
                task = this.rdSelectedTask

            const resp = await api.rdUpdateTask({ ...task, pending: undefined }, this.operator.channelId)
            if(resp.statusCode == 200) {
                this.$emit("msg", {
                    text: "Tarefa editada com sucesso!",
                    success: true
                })
                this.$bvModal.hide('rdUpdateTask')
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro!"
                })
            }
        },
        async rdSaveDeal() {
            if(this.clientSelected.crmData.selectedDeal.user?._id)
                this.clientSelected.crmData.selectedDeal.user_id = this.clientSelected.crmData.selectedDeal.user._id
            this.clientSelected.crmData.deal = this.clientSelected.crmData.selectedDeal

            this.rdPickedDeal(this.clientSelected.crmData.deal, this.rdDealPipelineChanged && this.rdPreviousDealPipeline != this.clientSelected.crmData.deal_stage?.deal_pipeline_id)
            // if(!this.clientSelected.crmData.deal.user_id && this.clientSelected.crmData.selectedDeal.user)
            //     this.clientSelected.crmData.deal.user_id = this.clientSelected.crmData.selectedDeal.user._id
            this.rdUpdateDeal()
        },
        async rdSaveUser() {
            await this.rdUpdateDeal(this.clientSelected.crmData)
            this.refreshClientSelected()
        },
        async rdGetUsers() {
            this.rdLoadingUsers = true
            const resp = await api.rdGetUsers(this.operator.channelId)
            // console.log({resp})
            this.rdLoadingUsers = false
            if(resp.statusCode == 200) {
                this.rdUsers = resp.users
            } else {
                this.rdUsers = []
            }
        },
        handleTimestamp(timestamp) {
            if (timestamp > 9999999999) return timestamp
            else return timestamp *= 1000
        },
        linkedText(text) {
            const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig
            return text.replace(urlRegex, "<a href='$1' target='_blank'>$1</a>");
        },
        async checkAttendanceWindowIsOpened(attendance) {
            if(this.attendanceType && this.attendanceType !== 'contacts') return true
            if(attendance?._id) {
                if(!attendance.channelType) {
                    const channelType = await this.getChannelType(attendance.channel)
                    attendance.channelType = channelType
                }
                if(attendance.channelType !== 'gupshup' && attendance.channelType !== 'cloud') return true
    
                if(!attendance.lastMessageContact) return false
    
                const date = new Date(attendance.lastMessageContact)
                const currentDate = new Date()
    
                // Calculate the difference in milliseconds between the current date/time and the given date/time
                const timeDifference = currentDate.getTime() - date.getTime();
    
                // Calculate the number of hours in the time difference
                const hoursDifference = timeDifference / (1000 * 60 * 60);
    
                if(hoursDifference > 24) return false
                return true
            }
        },
        async updateOperatorNotificationConfig() {
            this.operator.notificationConfig = this.notificationConfigModal
            const resp = await api.updateOperator(this.operator)
            if(resp.statusCode == 200) {
                this.$emit("msg", {
                    text: "Configurações atualizadas com sucesso!",
                    success: true
                })
                this.$bvModal.hide('notificationConfig')
            } else {
                this.$emit("msg", {
                    text: "Erro ao atualizar configurações!",
                    success: false
                })
            }
        },
        refreshCatalogProducts() {
            this.catalogProducts.push({})
            this.catalogProducts.pop()
        },
        refreshClient(client) {
            this.$set(client,"name",client.name?.concat(' '))
            this.$set(client,"name",client.name?.trimEnd())
        },
        inputTemplateCountry(e) {
            this.refreshSelectedTemplate()
            this.selectedTemplate.contactNumber = e
        },
        pickAttendancesFilter(status) {
            if(status === this.statusFilter)
                this.statusFilter = ""
            else
                this.statusFilter = status
            this.getAttendances2(1)
        },
        handleVisibilityChange() {
            if (document.visibilityState === "visible") {
                const tokenTimestamp = getTokenTimestamp()
                if(!tokenTimestamp || tokenTimestamp - Date.now() <= -86400000)
                    logout()
            }
        },
        goToAttendanceByContactId(contactId) {
            const attendance = this.attendances.find(attendance => attendance.contactId == contactId)
            if(attendance) {
                this.selectClient(attendance)
                this.$bvModal.hide("calendarEvent")
            }
        },
        async updateCardStep(card, stepId, setClientStep = true) {
            let oldStepId
            if (this.clientSelected)
                oldStepId = this.clientSelected.kanbanStepId
            
            card.stepId = stepId
            
            if (this.clientSelected && setClientStep)
                this.$set(this.clientSelected, "kanbanStepId", stepId)

            const resp = await api.updateCard(card)
            if(resp.statusCode != 201 && resp.statusCode != 200) {
                this.$emit("msg", {
                    text: "Erro ao atualizar etapa!",
                    success: false
                })

                if (oldStepId) 
                    this.clientSelected.kanbanStepId = oldStepId

                return
            }

            if (!setClientStep) {
                await this.getAttendancesKanbanData(this.attendances)
            }

            this.refreshClientSelected()
        },
        windowEvents() {
            window.addEventListener("resize", () => {
                this.calculateScreenHeightAttendancesCount()
            })

            window.addEventListener("paste", (pasteEvent) => {
                if(this.insertFile) {
                    const items = (pasteEvent.originalEvent || pasteEvent).clipboardData.items
        
                    if(this.browser == 'Firefox' && !pasteEvent.clipboardData.items?.length) {
                        this.$emit("msg", {
                            text: "Área de Transferência vazia ou o Firefox não consegue identificar esse tipo de arquivo!",
                            success: false
                        })
                    }

                    const blobs = []
                    for(const item of items) {
                        if(item.kind == 'file') {
                            const blob = item.getAsFile()
                            blobs.push(blob)
                        }
                    }
                    
                    if(blobs?.length) {
                        this.setInputMedia(blobs)
                        const addMediaWrapper = document.querySelector('.addMediaWrapper')
                        if(addMediaWrapper)
                            addMediaWrapper.classList.add("invisible")
                    }
                }
            }, false)

            document.addEventListener("visibilitychange", this.handleVisibilityChange)
        },
        async loadCalendar() {
            this.activeCalendar = !this.activeCalendar
            if (this.activeCalendar)
                await this.getHSMModels()
            else if(window.innerWidth <= 425) {
                setTimeout(() => {
                    const col1 = document.getElementById('col1')
                    if(col1)
                        col1.style.flex = '1'
    
                    const col2 = document.getElementById('col2')
                    if(col2)
                        col2.style.flex = '0'
                }, 500);
            }
        },
        async getKanbanCards() {
            this.loadingKanbanCards = true

            const resp = await api.getCardsByOperatorId(this.operator._id)
            // console.log({resp})
            const steps = []
            let kanbanId
            if(resp.statusCode == 200) {
                if (resp.cards?.length) {
                    for (const index in resp.cards) {
                        const card = resp.cards[index]

                        const stepIndex = steps.findIndex(step => step._id === card.stepId)
    
                        if (stepIndex === -1) {
                            try {
                                const stepResp = await api.getStepById(card.stepId)

                                if (stepResp.statusCode === 200) {
                                    if (!kanbanId) {
                                        kanbanId = stepResp.step.idKanban

                                        try {
                                            const stepsResp = await api.getKanbanSteps(kanbanId)
                                            for (const st of stepsResp.steps) {
                                                if (st._id !== stepResp.step._id && !steps.some(el => el._id === st._id))
                                                    steps.push({ 
                                                        ...st, 
                                                        cards: [] 
                                                    })
                                            }
                                        } catch(error) {
                                            console.error(error)
                                        }
                                    }
            
                                    if (!steps.some(el => el._id === stepResp.step._id))
                                        steps.push({
                                            ...stepResp.step,
                                            cards: [card]
                                        })
                                }
                            } catch(error) {
                                console.error(error)
                            }
                        } else {
                            if (steps[stepIndex].cards)
                                steps[stepIndex].cards.push(card)
                        }
                    }
                }
            }

            this.kanbanSteps = steps.sort((a,b) => a.order - b.order)

            this.loadingKanbanCards = false
        },
        async addingToStep(e) {
            const newStep = this.kanbanSteps[parseInt(e.to.id)]
            const card = e.item._underlying_vm_

            this.loadingKanbanCards = true

            await this.updateCardStep(card, newStep._id, false)

            this.getKanbanCards()
        },
        async resendMsg(msg) {
            if (!msg.tryingResend) {
                msg.tryingResend = true

                const obj = {
                    ...msg,
                    attendanceId: this.clientSelected._id,
                    clientNumber: this.clientSelected.clientNumber,
                    channelId: this.clientSelected.channel || this.clientSelected.channelId,
                    groupId: this.clientSelected._id,
                    operatorId: this.operator._id,
                    name: this.operator.name // Nome do operador caso seja grupo
                }

                this.socket.emit('resend_message', obj)
            }
        },
        async saveAnswer() {
            if(!this.quickAnswer.name) {
                return this.$emit('msg', {
                    text: 'Campo "Título" vazio!',
                    success: false
                })
            }

            if(!this.quickAnswer.departments?.length) {
                return this.$emit('msg', {
                    text: `Campo "Departamentos" vazio!`,
                    success: false
                })                
            }

            if(this.quickAnswer.content.length > this.msgCharLimit) {
                return this.$emit('msg', {
                    text: `Campo "Conteúdo" tem o limite de ${this.msgCharLimit} caracteres!`,
                    success: false
                })
            }

            let resp

            if(this.quickAnswer._id) {
                resp = await api.updateQuickAnswer(this.quickAnswer._id, this.quickAnswer)
            } else {
                resp = await api.createQuickAnswer(this.user.channelId || this.user.roleId, this.quickAnswer)
            }
            // console.log('resp',resp)
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                this.$emit('msg', {
                    text:'Resposta Rápida criada com sucesso!',
                    success: true,
                })
                this.getQuickAnswers()
                this.$refs['createQuickAnswer'].hide()
            } else if (resp.statusCode == 422) {
                this.$emit('msg', {
                    text: 'Campo "Título" ou "Departamentos" vazio!',
                    success: false
                })
            } else if (resp.statusCode == 406) {
                this.$emit('msg', {
                    text: 'Já existe uma Resposta Rápida com este Título!',
                    success: false
                })
            } else {
                this.$emit('msg', {
                    text: 'Ocorreu um erro! Revise os dados e tente novamente.',
                    success: false
                })
            }

            this.quickAnswer = {}
        },
        depName(_id) { // returns the name of the given _id department
            if(this.departments.length)  {
                if(_id == 'all')
                    return "Todos"
                    
                return this.departments.find(el=>el._id == _id)?.name
            }
        },
        isMsgTextareaDisabled() {
            if (!this.clientSelected) return false
            return this.attendanceType === 'contacts' ? (this.clientSelected.status === 'finished' || this.clientSelected.status === 'abandoned' || this.clientSelected.status === 'in_survey' || this.clientSelected.operatorId !== this.operator._id || !this.attendanceWindowIsOpened) : !this.clientSelected.from || this.clientSelected.inactive || this.clientSelected.deletedOnWhatsapp
        },
        setWindowRemainingTime() {
            let interval
            if (this.clientSelected?.msgs) {
                interval = setInterval(() => {
                    if (!this.clientSelected?.msgs) {
                        return clearInterval(interval)
                    }
                    
                    const lastContactMessage = this.clientSelected.msgs?.findLast(el => !el.fromMe);
    
                    if (lastContactMessage) {
                        const lastMsgTimestamp = lastContactMessage.timestamp;
                        const now = new Date();
                        const lastMsgDate = new Date(lastMsgTimestamp < 9999999999 ? lastMsgTimestamp * 1000 : lastMsgTimestamp);
    
                        // Calculate the time difference in milliseconds
                        const timeDifference = now.getTime() - lastMsgDate.getTime();
    
                        // Calculate the remaining time until 24 hours have passed
                        const remainingTime = (24 * 60 * 60 * 1000) - timeDifference;
    
                        if (remainingTime > 0) {
                            const hoursDifference = Math.floor(remainingTime / (1000 * 60 * 60));
                            const minutesDifference = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                            this.windowRemainingTime = `${hoursDifference.toString().padStart(2, '0')}:${minutesDifference.toString().padStart(2, '0')}`;
                        } else {
                            this.windowRemainingTime = '00:00';
                        }
                    } else {
                        this.windowRemainingTime = '00:00';
                    }

                    this.borderStyle()
                }, 2000)
            } else if (interval) {
                clearInterval(interval)
            }
        },
        async getInternalChat(operatorId, day = 0) {
            this.internalNewMessage = null

            const operatorIds = [ this.operator._id, operatorId ]
            const resp = await api.getInternalChatPerDay(operatorIds, this.operator._id, day)

            if (resp.statusCode === 200) {
                if (day > 0) {
                    this.internalChat.chat = [...resp.chat, ...this.internalChat.chat]
                    this.internalChat.day = resp.currentDay || day
                    this.$root.$emit("loadedInternalMessages")
                } else {
                    this.internalChat = { ...resp, operatorIds, day: resp.currentDay || day }
                }

                this.internalChatsUnreadCount = this.internalChatsUnreadCount - resp.unreadCount
                
                resp.chat.map(el => {
                    if (!el.read) {
                        this.socket.emit("internal_message_ack_operator", { ...el, statusAck: 3 })
                    }
                })

                if (!resp.chat?.length && day > 0) {
                    this.$emit("msg", {
                        text: "Nenhuma mensagem encontrada!",
                        success: false
                    })
                }
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao carregar chat interno!",
                    success: false
                })
            }
        },
        borderStyle() {
            const totalMinutes = 24 * 60 // Total minutes in a day
            const [hours, minutes] = this.windowRemainingTime.split(':').map(Number)
            const remainingMinutes = hours * 60 + minutes
            const percentagePassed = Math.floor(((totalMinutes - remainingMinutes) / totalMinutes) * 100)
            
            const canvas = document.getElementById('border-canvas')

            if (canvas) {
                const ctx = canvas.getContext('2d')
                
                // Get the device pixel ratio, falling back to 1 if it is not supported
                const dpr = window.devicePixelRatio || 1
                
                // Set canvas dimensions
                const width = canvas.offsetWidth
                const height = canvas.offsetHeight
                canvas.width = width * dpr
                canvas.height = height * dpr
                
                // Scale the context to match the device pixel ratio
                ctx.scale(dpr, dpr)
                
                const radius = Math.min(width, height) / 2
                
                // Clear the canvas
                ctx.clearRect(0, 0, width, height)
                
                // Draw the gradient border
                ctx.save()
                ctx.translate(width / 2, height / 2)
                ctx.rotate(-Math.PI / 2) // Start the gradient from the top
                
                const gradient = ctx.createConicGradient(0, 0, 0)
                gradient.addColorStop(0, 'hsl(4.11deg 84.59% 50%)')
                gradient.addColorStop(percentagePassed / 100, 'hsl(4.11deg 84.59% 50%)')
                gradient.addColorStop(percentagePassed / 100, 'hsl(152.18deg 68.75% 31.37%)')
                gradient.addColorStop(1, 'hsl(152.18deg 68.75% 31.37%)')
                
                ctx.fillStyle = gradient
                ctx.beginPath()
                ctx.arc(0, 0, radius, 0, 2 * Math.PI)
                ctx.arc(0, 0, radius - 2.5, 0, 2 * Math.PI, true) // Adjust based on border width
                ctx.fill()
                ctx.restore()
            }
        },
        blinkNewMessage() {
            this.newMessage = true
            setTimeout(() => {
                this.newMessage = false
            }, 2000)
        },
        tagValidator(tag) {
            return tag.length <= 25
        },
        setContactAddressData(cep) {
            this.$viaCep.buscarCep(cep).then((obj) => {
                if(!obj.erro) {
                    this.clientSelected.address = obj.logradouro
                    this.clientSelected.neighborhood = obj.bairro
                    this.clientSelected.complement = obj.complemento
                    this.clientSelected.country = "BR"
                }
            })
        },
        async updateContact(contactId, data) {
            console.log({ contactId, data })

            if (data.cep) {
                data.cep = data.cep.replace(/[^0-9]/g, '')
            }

            if (data.country) {
                this.refreshClientSelected()
            }

            const resp = await api.updateContact({ _id: contactId, ...data })

            if (!resp.statusCode === 200) {
                this.$emit("msg", {
                    text: "Ocorreu um erro ao atualizar contato!",
                    success: false
                })
            }
        },
        async deleteSupplementaryDocument(index) {
            this.clientSelected.supplementaryDocument = this.clientSelected.supplementaryDocument.filter((_, i) => i !== index)
            await this.updateContact(this.clientSelected.contactId, { supplementaryDocument: this.clientSelected.supplementaryDocument?.length ? this.clientSelected.supplementaryDocument : null })
        },
        async calculateFreight(cep) {
            if (!cep) {
                return this.$emit("msg", {
                    text: "CEP não encontrado!",
                    success: false
                })
            }

            try {
                this.loadingFreight = true
            
                await this.updateContact(this.clientSelected.contactId, { cep })
                
                const resp = await api.getDiamantesFreight(this.clientSelected.orderId)

                console.log({resp})
                if (resp.statusCode === 200) {
                    this.clientSelected.freightOptions = resp.freight.sort((a, b) => ((a.valor_frete_exibicao || a.valor_frete) + a.valor_icms) - ((b.valor_frete_exibicao || b.valor_frete) + b.valor_icms))
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro ao buscar frete!",
                        success: false
                    })
                }

                this.loadingFreight = false

                if (!this.loadedFreight) {
                    this.loadedFreight = true
                }
            } catch (error) {
                console.error(error)
                this.loadingFreight = false
                
                if (!this.loadedFreight) {
                    this.loadedFreight = true
                }

                this.$emit("msg", {
                    text: "Ocorreu um erro ao buscar frete!",
                    success: false
                })
            }
        },
        checkInstallment(installment) {
            const freight = this.clientSelected.freight
            const totalValue = this.clientSelected.productsCart.reduce((total, product) => {
                if (product.unavailable) return total
                return total + (product.salePrice || product.price) * product.quantity
            }, 0) + freight ? (freight.valor_frete_exibicao || freight.valor_frete) + freight.valor_icms : 0
            
            if (totalValue <= 0) return false
            if (installment === 1) return true
            if (installment === 2) return totalValue > 251
            if (installment === 3) return totalValue > 401
            if (installment === 4) return totalValue > 551
            if (installment === 5) return totalValue > 701
            if (installment === 6) return totalValue > 850
        },
        async setDiamantesFreight(freight) {
            const resp = await api.setDiamantesFreight({
                orderId: this.clientSelected.orderId,
                name: freight.nome_transportador,
                cnpj: freight.cnpj_transportador,
                value: (freight.valor_frete_exibicao || freight.valor_frete) + freight.valor_icms
            })
        },
        async setDiamantesPayment() {
            if (!this.clientSelected.selectedFreight) {
                return this.$emit("msg", {
                    text: "Selecione um frete!",
                    success: false
                })
            }

            const resp = await api.setDiamantesPayment({
                orderId: this.clientSelected.orderId,
                numberInstallments: this.clientSelected.numberInstallments,
                typePayment: this.clientSelected.typePayment
            })

            if (resp.statusCode === 200) {
                this.$emit("msg", {
                    text: "Pedido realizado com sucesso!",
                    success: true
                })
            }
        },
        async checkCartAvailability() {
            if (!this.clientSelected.productsCart?.length || !this.operator.channelConfig?.modules?.products || (this.operator.channelId !== '6650c5456a5a4dca8eb3e535' && this.operator.channelId !== '62d9432176c1d572f1f67079') ) return
            
            this.checkingCartAvailability = true

            this.clientSelected.productsCart.map(async product => {
                product.checkingAvailability = true
            })

            for (const product of this.clientSelected.productsCart) {
                try {
                    const resp = await api.getDiamantesProductAvailability(product.id)
                    product.unavailable = !resp?.inStock
                    product.quantityToSellOnFacebook = resp.quantity
                    
                    product.checkingAvailability = false
                } catch (error) {
                    console.error(error)
                }
            }

            this.checkingCartAvailability = false
        },
        rootOnEvents() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (isJustShown && this.$refs["clientData"]?.show && ['collapse-contact-order-payment', 'collapse-contact-order-freight', 'collapse-contact-order-data', 'collapse-products'].includes(collapseId)) {
                        this.$root.$emit('bv::toggle::collapse', 'clientData')
                }
            })
            this.$root.$on('attendances',(attendances) => {
                console.log('attendances',attendances)
                if(attendances?.length) {
                    attendances.map(async el => {
                        if(!this.attendances) this.attendances = []
                        if(this.attendanceType == 'contacts') {
                            const found = this.attendances.find(att => att.clientNumber == el.clientNumber)
                            if(!found)
                                el = await this.getAttendanceKanbanData(el)
                                this.attendances.unshift(el)
                        }
                    })
                }
            })

            this.$root.$on('goToMessage',(msg) => {
                this.goToMessage(msg)
            })

            this.$root.$on('products_list',(product) => {
                if(!this.clientSelected.productsList) {
                    this.clientSelected.productsList = [ product ]
                } else {
                    if(this.clientSelected.productsList.length >= 30) {
                        product.listed = false
                        this.refreshCatalogProducts()
                        return this.$emit("msg", {
                            text: "O limite máximo de produtos na lista de desejos é de 30!!",
                            success: false
                        })
                    }
                    const productIndex = this.clientSelected.productsList.findIndex(el => el._id == product._id)
                    if(productIndex >= 0) {
                        this.clientSelected.productsList.splice(productIndex,1)
                    } else {
                        this.clientSelected.productsList.push(product)
                    }
                }
                this.refreshClientSelected()
            })

            this.$root.$on('product_cart',(product) => {
                product.quantity = 1
                if(!this.clientSelected.productsCart) {
                    this.clientSelected.productsCart = [ product ]
                } else {
                    if(this.clientSelected.productsCart.length >= 30) {
                        return this.$emit("msg", {
                            text: "Limite de 30 produtos atingido!",
                            success: false
                        })
                    }
                    const productIndex = this.clientSelected.productsCart.findIndex(el => el._id == product._id)
                    if(productIndex >= 0) {
                        this.clientSelected.productsCart.splice(productIndex,1)
                    } else {
                        this.clientSelected.productsCart.push(product)
                    }
                }
                this.refreshClientSelected()
            })

            this.$root.$on('clean_attendances', () => {
                if(this.clientSelected)
                    this.clientSelected = { msgs: [] }

                this.showCrmData = false
                this.clientData = false
                this.inputSend = ''
                this.insertFile = false

                this.cleanSearchFilter()

                this.getAttendances2(1)
                this.getAttendancesStatus()
                this.getAttendanceQuantity()
                this.getOperatorGroupsQuantity()
            })

            this.$root.$on('loadMoreInternalMessages', ({operatorId, day}) => {
                this.getInternalChat(operatorId, day)
            })
        },
        socketOnEvents() {
            this.socket.on('operator-calendar', async (data) => {
                console.log('operator-calendar ',data)
                try {
                    const contactResp = await api.getContact(data.contactId)
                    if (contactResp.statusCode == 200) {
                        data.contact = contactResp.contact
                    }
                } catch(error) {
                    console.error(error)
                }

                this.calendarEvent = data
                this.$bvModal.show("calendarEvent")
            })
            
            this.socket.on('message_ack', async (data) => {
                console.log('message_ack', data)
                const number = data.clientNumber

                if(!number) return

                const numberWithoutNine =
                    number?.length === 13 ? number?.slice(0, 4) + number?.slice(5) : number
                const numberWithNine =
                    number?.length === 12
                    ? number?.slice(0, 4) + '9' + number?.slice(4)
                    : number

                const numbers = [numberWithoutNine, numberWithNine]

                if(!this.clientSelected?.msgs?.length || !numbers.includes(this.clientSelected?.clientNumber?.toString())) {
                    // console.log('ack not from client selected', this.clientSelected?.msgs?.length, numbers, this.clientSelected?.clientNumber?.toString())
                    return
                }

                const findMessage = async (retrys) => {
                    const messageIndex = this.clientSelected.msgs.findIndex(e => e.idWpp == data.idWpp)

                    if (messageIndex == -1 && !retrys) {
                        setTimeout(async () => {
                            await findMessage(retrys + 1)
                        }, 1000)
                        return
                    }

                    if(messageIndex > -1)
                        this.$set(this.clientSelected.msgs[messageIndex], 'statusAck', data.statusAck)
                    // else {
                    //     if(this.attendanceType === 'groups')
                    //         return await this.loadGroupMessages()
                    //     else {
                    //         console.log('ack from message not found')
                    //         return await this.loadMessages(null, true)
                    //     }
                    // }
                }

                switch(data.statusAck) {
                    case 1:
                    case '1': {
                        // let messageIndex = this.clientSelected?.msgs.findIndex(el => el.fromMe && !el.statusAck && !el.idWpp)
                        let messageIndex = this.clientSelected?.msgs.findLastIndex(el => el.fromMe && el.statusAck && el.idWpp)
                        messageIndex = this.clientSelected?.msgs.findIndex((el,i) => el.fromMe && !el.statusAck && !el.idWpp && i > messageIndex)

                        if (messageIndex !== -1) {
                            this.$set(this.clientSelected.msgs[messageIndex], 'statusAck', data.statusAck)
                            this.clientSelected.msgs[messageIndex].idWpp = data.idWpp
                            this.clientSelected.newMsgs++
                            this.clientSelected.newMsgs--
                        }
                        break
                    }
                    default: {
                        findMessage(0)
                        break
                    }
                }
            })

            this.socket.on('new_message',async (data) => {
                console.log('new_message', data.messages)
                if(data.messages.type == "vcard") {
                    if(!data.messages.vcardName)
                        data.messages.vcardName = data.messages.body.split('FN:')[1].split('\n')[0]
                    if(!data.messages.vcardNumber)
                        data.messages.vcardNumber = data.messages.body.split('waid=')[1].split(':')[0]
                } else if (data.messages.type === 'vcardArray') {
                    data.messages.contacts = []
                    for (const index in data.messages.body) {
                        const obj = {
                            vcardName: data.messages.body[index].split('FN:')[1].split('\n')[0],
                            vcardNumber: data.messages.body[index].split('waid=')[1].split(':')[0]
                        }
                        data.messages.contacts[index] = obj
                    }
                }

                if(!data.messages.fromMe && this.operator.notificationConfig?.contacts)
                    this.sendBrowserNotification(data)

                const chat = this.attendances.find(e => e._id === data.attendance._id)
                if(chat) {
                    if(chat.msgs) {
                        if(typeof data.messages.timestamp === 'string') data.messages.timestamp = parseInt(data.messages.timestamp)

                        if(data.messages.timestamp > 9999999999)
                            data.messages.timestamp = Math.floor(data.messages.timestamp / 1000)

                        const date = new Date().toLocaleDateString('en-US')

                        if(!chat.msgs.find(el => el.date === date)) {
                            chat.msgs.push({ type: 'date', timestamp: Date.now() / 1000 - 1, date })
                        }

                        chat.msgs.push(data.messages)
                        
                        if(chat._id === this.clientSelected._id) {
                            if(!this.attendanceWindowIsOpened)
                                this.attendanceWindowIsOpened = true

                            if (this.clientSelected.msgs.findIndex(el => el.idWpp == data.messages.idWpp) === -1) {
                                this.clientSelected.msgs.push(data.messages)
                            }

                            if (!data.messages.fromMe) {
                                this.blinkNewMessage()
                            }
                        }
                    } else {
                        chat.lastMsg = data.messages
                    }
                    
                    chat.lastMessageContact = new Date().getTime()
                } 
                // else {
                //     return console.log('chat não encontrado')
                // }

                if(!data.messages.fromMe) {
                    this.attendancesUnreadCount++
                    if(chat) {
                        if(!chat.newMsgs) chat.newMsgs = 1  
                        else chat.newMsgs++
                    }
                }

                if(this.attendanceType === 'contacts') {
                    const index = this.attendances.findIndex(({ _id }) => _id === chat._id)
                    if (index !== -1) {
                        this.attendances.splice(index, 1)
                        this.attendances.unshift({...chat})
                    }
                }

                if(!data.messages.fromMe)
                    this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-message-pop-alert-2354.mp3?alt=media&token=e76c575b-4ce7-4e57-8408-834229b653bf')
            })

            this.socket.on('new_message_group',async (data) => {
                if(this.operatorHasGroups) {
                    console.log('new_message_group',data)
                    if(data.messages?.phone == this.operator.channel?.channelNumber)
                        return
                    if(data.messages.type == "vcard") {
                        if(!data.messages.vcardName)
                            data.messages.vcardName = data.messages.body.split('FN:')[1].split('\n')[0]
                        if(!data.messages.vcardNumber)
                            data.messages.vcardNumber = data.messages.body.split('waid=')[1].split(':')[0]
                    }
                    
                    if(this.groups.find(e => e.from == data.from)) {
                        if(this.operator.notificationConfig.groups)
                            this.sendBrowserNotification(data)
                        this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-message-pop-alert-2354.mp3?alt=media&token=e76c575b-4ce7-4e57-8408-834229b653bf')
                        this.groupsUnreadCount++
                    }
                    const chat = this.attendances.find(e => e.from == data.from)
                    if(chat) {
                        if(chat.msgs) {
                            if(typeof data.messages.timestamp == 'string') data.messages.timestamp = parseInt(data.messages.timestamp)

                            const date = new Date().toLocaleDateString('en-US')

                            if(!chat.msgs.find(el => el.date == date)) {
                                chat.msgs.push({ type: 'date', timestamp: Date.now() / 1000 - 1, date })
                            }

                            chat.msgs.push(data.messages)
                            
                            this.blinkNewMessage()
                        } else {
                            chat.lastMsg = data.messages
                        }

                        if(!chat.newMsgs) chat.newMsgs = 1 
                        else chat.newMsgs++
                    } 
                    // else
                    //     return console.log('chat não encontrado')
    
                    if(this.attendanceType === 'groups') {
                        const index = this.attendances.findIndex(({ _id }) => _id === chat._id)
                        if (index !== -1) {
                            this.attendances.splice(index, 1)
                            this.attendances.unshift({...chat})
                        }
                    }
                }
            })

            this.socket.on('new_attendance', async (data) => {
                console.log('new_attendance',data)
                if(data.attendances) {
                    const atts = []
                    data.attendances.forEach(el => {
                        atts.unshift(el)
                        this.totalAttendances++
                    })

                    if(this.attendanceType === 'contacts') {
                        this.attendances = atts.sort((a,b) => (a.lastMessage > b.lastMessage) ? -1 : ((b.lastMessage > a.lastMessage) ? 1 : 0))
                        this.getAttendancesState(this.attendances)
                        this.getAttendancesKanbanData(this.attendances)
                    }
                } else if(data.attendance) {
                    await this.getAttendanceState(data.attendance)
                    data.attendance = await this.getAttendanceKanbanData(data.attendance)
                    if(this.attendanceType === 'contacts') {
                        const found = this.attendances.find(att => att.clientNumber == data.attendance.clientNumber)
                        if(!found) {
                            this.attendances.unshift(data.attendance)
                            this.totalAttendances++
                        }
                    }
                } else {
                    if(this.attendanceType === 'contacts') {
                        const found = this.attendances.find(att => att.clientNumber == data.clientNumber)
                        if(!found) {
                            await this.getAttendanceState(data)
                            data = await this.getAttendanceKanbanData(data)
                            this.attendances.unshift(data)
                            this.totalAttendances++
                        }
                    }
                }
                this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-elevator-tone-2863.wav?alt=media&token=fc2fc2bb-09d1-4515-a7f6-e877b9a2c9c0')
                this.getAttendanceQuantity()
                this.getAttendancesStatus()
            })

            this.socket.on('updated_attendance',(attendance) => {
                console.log('updated_attendance',attendance)
                const att = this.attendances.find(e => e._id === attendance._id)
                if (att) {
                    const i = this.attendances.indexOf(att)
                    let currentAttendance = this.attendances[i]
                    currentAttendance = { ...attendance, msgs: currentAttendance.msgs }
                    this.clientSelected = currentAttendance
                    // console.log('ok')
                }
            })

            this.socket.on('update_avatar',(attendance) => {
                console.log('update_avatar',attendance)
                const att = this.attendances.find(e=> e._id === attendance._id)
                if (att) {
                    const i = this.attendances.indexOf(att)
                    this.attendances[i] = attendance
                }
            })

            this.socket.on('load_messages',async (data) => {
                const msgs = []
                console.log('load_messages',data)
                await data.messages.forEach(el => {
                    if(el.type=="vcard") {
                        try {
                            if(!el.vcardName)
                                el.vcardName = el.body.split('FN:')[1].split('\n')[0]
                            if(!el.vcardNumber)
                                el.vcardNumber = el.body.split('waid=')[1].split(':')[0]
                        } catch(err) {
                            console.error('vcard err',err)
                        }
                    }
                    msgs.push(el)
                })
                this.$set(this.clientSelected, 'msgs', msgs)
                this.loadingMessages = false
            })

            this.socket.on('online_operators',(data) => {
                if(data?.length)
                    this.onlineOperators = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            })

            this.socket.on('get_departments',(data) => {
                this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            })

            // this.socket.on('load_protocol_messages',async (data) => {
            //     console.log('load_protocol_messages',data)
            //     let timestamp = (new Date(data.messages[0].timestamp).getTime())-7
            //     let createdAt = new Date(data.messages[0].timestamp*1000)
            //     let protocolStart = {type:'protocolStart',createdAt,timestamp}

            //     await data.messages.reverse().forEach(el=>{
            //         this.clientSelected.msgs.unshift(el)
            //     })
            //     this.clientSelected.msgs.unshift(protocolStart)
            //     this.loadingProtocol = false
            // })

            this.socket.on('finish_attendance',async (data) => {
                console.log('finish_attendance',data)
                this.attendances = this.attendances.filter(el=>el._id != data._id)
                this.totalAttendances--
                this.getAttendancesStatus()
                if(this.clientSelected._id == data._id) {
                    this.clientSelected = {}
                    this.clientData = false
                    this.loadingMessages = false
                }
            })

            this.socket.on('search_old_attendances', (data) => {
                console.log('old_atts',data)
                this.oldAttendances = data.reverse().reduce((att, current) => {
                    const x = att.find(item => item.clientNumber === current.clientNumber);
                    if (!x) {
                        return att.concat([current]);
                    } else {
                        if(current.status!='finished' && current.status!='abandoned') {
                            return att.concat([current]);
                        }
                        return att;
                    }
                }, []);
            })

            this.socket.on('work_time_over', () => {
                console.log('work_time_over')
                this.socket.emit('operator_status_update', { operatorId: this.operator._id, status: false })
                this.operator.status = false
                this.workTimeOver = true
                this.$root.$emit('workTimeOver', this.workTimeOver)
                this.$refs['modal-workTimeOver'].show()
            })

            this.socket.on('forward_to_department', (data) => {
                console.log('forward_to_department',data)
                if(data.status) {
                    this.attendances = this.attendances.filter((el) => { return el._id != this.clientSelected._id; })
                    this.clientSelected = {}
                    this.totalAttendances--
                    this.getAttendanceQuantity()
                    this.getAttendancesStatus()
                } else {
                    this.$emit("msg", {
                        text: "Nenhum Operador online no Departamento selecionado!",
                        success: false
                    })
                }
            })

            this.socket.on('forward_to_operator', (data) => {
                console.log('forward_to_operator',data)
                if(data.status == true) {
                    this.attendances = this.attendances.filter((el) => { return el._id != this.clientSelected._id; })
                    this.clientSelected = {}
                    this.totalAttendances--
                    this.getAttendanceQuantity()
                    this.getAttendancesStatus()
                } else if(data.status == 'simultaneous_attendance')
                    this.$emit("msg", {
                        text: data.message,
                        success: false
                    })
                else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro!",
                        success: false
                    })
                }
            })

            // this.socket.on('get_contact_data',async (data) => {
            //     // console.log('clientData',data)
            //     this.$set(this.clientSelected, 'email', data.email)
            //     this.$set(this.clientSelected, 'document', data.document)
            //     this.$set(this.clientSelected, 'state', data.state)
            //     this.$set(this.clientSelected, 'clientId', data.clientId)
            //     this.$set(this.clientSelected, 'contact_id', data._id)
            // })

            this.socket.on('attendance_forwarded',(data) => {
                console.log('attendance_forwarded',data)
                this.attendances = this.attendances.filter(el => el._id!=data)
            })

            // this.socket.on('get_notification_templates',(data) => {
            //     // console.log(data)
            //     data.forEach(el=>{
            //         if(!el.nome) {
            //             el.nome = el.conteudo
            //         }
            //     })
            //     this.templates = data
            //     this.templates.unshift({id:'99999',nome:'Criar abordagem',conteudo:''})
            // })

            this.socket.on('existing_contact',(data) => {
                console.log('existing_contact',data)
                this.loadingSN = false

                if(this.$refs["modalPickDepartmentNotification"])
                    this.$refs["modalPickDepartmentNotification"].hide()

                if(this.$refs["modalPickChannelNotification"])
                    this.$refs["modalPickChannelNotification"].hide()

                if(this.$refs["modal-activecomm"])
                    this.$refs["modal-activecomm"].hide()

                this.$emit("msg", {
                    text: 'Este contato já está inserido na plataforma!',
                    success: false
                })
            })

            this.socket.on('send_notification',(data) => {
                console.log('send_notification',data)
                this.loadingSN = false
                let msg = null

                if(this.$refs["modalPickDepartmentNotification"])
                    this.$refs["modalPickDepartmentNotification"].hide()

                if(this.$refs["modalPickChannelNotification"])
                    this.$refs["modalPickChannelNotification"].hide()

                if(data.statusCode == 408) {
                    msg = {
                        text: "Seu horário de atendimento foi finalizado!",
                        success: false
                    }
                } else {
                    switch(data.status) {
                        case 'sended':
                            if(this.operator.channel?.apiType != 'business') {
                                this.selectedTemplate.bodyId = undefined
    
                                if(this.$refs['modal-activecomm'])
                                    this.$refs['modal-activecomm'].hide()
    
                                msg = {
                                    text: 'Comunicação inserida na fila com sucesso!',
                                    success: true,
                                }
                            }
                            break
                        case 'invalid_number':
                            msg = {
                                text: 'Número Inválido!',
                                success: false,
                                countdown: 10
                            }
                            break
                        case 'unsent_message':
                        case 'api_error':
                            msg = {
                                text: 'Não foi possível identificar uma conta de WhatsApp neste número',
                                success: false,
                                countdown: 10
                            }
                            break
                        case 'channel_not_found':
                            msg = {
                                text: 'Ocorreu um erro ao enviar a comunicação ativa!',
                                success: false,
                                countdown: 10
                            }
                            break
                        // default:
                        //     msg = {
                        //         text: 'Ocorreu um erro ao enviar a comunicação ativa!',
                        //         success: false,
                        //         countdown: 10
                        //     }
                        //     break
                    }
                }

                if(msg)
                    this.$emit("msg", msg)
                //invalid_number,  channel_not_found,  sended, unsent_message
            })

            this.socket.on('api_error',(data) => {
                console.log('api_error', data)
                if(data.attendanceId) {
                    if(this.attendances.find(el => el._id == data.attendanceId)) {
                        this.attendances = this.attendances.filter(el => el._id != data.attendanceId)
    
                        if(this.clientSelected._id == data.attendanceId) {
                            this.clientSelected = {}
                            this.clientData = false
                        }
    
                        this.getAttendanceQuantity()
                        this.getAttendancesStatus()

                        if(this.$refs['modal-activecomm']) {
                            this.$emit("msg", {
                                text: 'Não foi possível identificar uma conta de WhatsApp neste número',
                                success: false,
                                countdown: 10
                            })
                        }
                    }
                }
            })

            this.socket.on('operator_break', data => {
                console.log('operator_break', data)

                this.operator.status = data.status
            })

            this.socket.on('valid_contact',(data) => {
                console.log('valid_contact', data)
                if(this.$refs['modal-activecomm']?.isVisible) {
                    this.selectedTemplate.bodyId = undefined
    
                    this.$refs['modal-activecomm'].hide()
    
                    this.$emit("msg", {
                        text: 'Comunicação inserida na fila com sucesso!',
                        success: true,
                    })

                    this.$refs['modal-activecomm'].hide()
                }

                const attendance = this.attendances.find(el => el.clientNumber == data.data?.phone)
                if(attendance) {
                    if(attendance.clientNumber != data.contactExist.user)
                        attendance.clientNumber = data.contactExist.user
                }
            })

            this.socket.on('status_attendances_day', (data) => {
                // console.log('status_attendances_day',data)
                this.inAttendanceCount = data.inAttendance
                this.finishedAttendanceCount = data.finished
                this.waitingAttendanceCount = data.waiting
                this.queueAttendanceCount = data.offlineOperators
            })

            this.socket.on('update_attendances_count', (data) => {
                console.log('update_attendances_count',data)
            })

            // this.socket.on('groups', (data) => {
            //     console.log('groups',data)
            //     if(this.attendanceType === 'groups') {
            //         if(this.clientSearch)
            //             this.attendances = data.filter(el => (el.name.toLowerCase().includes(this.clientSearch.toLowerCase())) )
            //         else
            //             this.attendances = data
            //     }
            // })
            this.socket.on('groups_operator', (data) => {
                // console.log('groups_operator', data)
                this.loadingAttendances = false
                let groups = data
                if (this.attendanceType === 'groups') {
                    if(this.clientSearch.value)
                        groups = groups.filter(el => (el.name.toLowerCase().includes(this.clientSearch.value.toLowerCase())) )

                    this.attendances = groups
                    this.getAttendancesLastMessage(groups)
                } else {
                    this.groups = groups
                }
                this.getAttendancesUnreadCount(groups, true)
            })

            this.socket.on('operator_has_groups', (data) => {
                if(this.timeoutGroups)
                    clearTimeout(this.timeoutGroups)
                if(data)
                    this.getGroups()
                this.operatorHasGroups = data
            })

            this.socket.on('message_revoke_everyone', (data) => {
                console.log('message_revoke_everyone',data)
            })

            this.socket.on('message_revoke_me', (data) => {
                console.log('message_revoke_me',data)
            })

            this.socket.on('message_react', (data) => {
                console.log('message_react',data)
                const msg = this.clientSelected.msgs?.find(el => el.idWpp == data.idWpp)
                if(msg) {
                    if(!msg.react)
                        msg.react = [ data.react ]
                    else {
                        const index = msg.react.findIndex(el => el.contactId == data.react.contactId)
                        if(index != -1)
                            msg.react[index] = data.react
                        else
                            msg.react.push(data.react)
                    }
                    msg.timestamp++
                    msg.timestamp--
                }
            })

            this.socket.on('offline_operators_queue', (data) => {
                // console.log('offline_operators_queue',data)
                const atts = []

                if(data.length) {
                    for (const att of data) {
                        if(att.operatorId == this.operator._id || this.operator.department.includes(att.departmentId)) {
                            atts.push(data)
                        }
                    }
                }
                
                this.queueAttendanceCount = atts.length
            })

            this.socket.on('has_secondary_channels', (data) => {
                // console.log("has_secondary_channels",data)
                this.operator.channel.hasSecondaryChannels = data
                this.getSecondaryChannels()
            })

            this.socket.on('attendance_picked', (attendanceId) => {
                this.attendances = this.attendances.filter(el => el._id != attendanceId)
                this.getAttendanceQuantity()
                this.getAttendancesStatus()
                if(this.clientSelected._id == attendanceId) {
                    this.clientSelected = {}
                    this.clientData = false
                    this.loadingMessages = false
                }
            })

            this.socket.on('adm_operator_logout', () => {
                console.log('adm_operator_logout')
                logout()
            })

            this.socket.on('resent_message', async (message) => {
                console.log('resent_message', message)
                message.tryingResend = false

                const index = this.clientSelected?.msgs?.findIndex(el => el._id == message._id)
                if (index >= 0) {
                    // this.clientSelected.msgs.splice(index, 1)
                    // this.clientSelected.msgs.push(message)
                    this.loadingMessages = true
                    await this.loadMessages()
                    this.loadingMessages = false
                }
            })

            this.socket.on('new_internal_message', (data) => {
                console.log('new_internal_message', data)

                if (
                    this.internalChat?.operatorIds?.length === data.operatorIds.length &&
                    this.internalChat?.operatorIds?.every((val) => data.operatorIds.includes(val))
                ) {
                    this.internalNewMessage = data
                } else {
                    if (this.attendanceType !== 'internalChat') {
                        const operator = this.operators.find(el => el._id == data.operatorId)
                        this.sendBrowserNotification({ messages: { ...data, name: `Chat Interno${operator ? ': ' + operator.name : '' }` } })
                        this.playAudio('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fmixkit-message-pop-alert-2354.mp3?alt=media&token=e76c575b-4ce7-4e57-8408-834229b653bf')
                    } else {
                        this.internalNewMessage = { ...data, checkChat: true }
                    }

                    this.internalChatsUnreadCount++
                }
            })
        }
    },
    data() {
        return {
            saveCalendar: { color: "#000", timeToNotify: 0 },
            JSON,
            console,
            createCalendar:false,
            activeCalendar:false,
            documentTitle: document.title,
            window,
            loadingSN: false,
            loadingProtocol: false,
            loadingPreviousGroupMessages: false,
            protocolsCount: 1,
            clientProtocols: [],
            onlineOperators: [],
            operators: [],
            departments: [],
            attendances: [],
            totalAttendances: 0,
            attendancesPerPage: 15,
            forward:{type:''},
            file: [],
            replyMessage: {},
            editMessage: {},
            whatsappicons: [
                // {
                //     name: 'search',
                //     notificationsnumber: '',
                //     tooltip: 'Buscar no Atendimento',
                // },
                {
                    name: 'paperclip',
                    notificationsnumber: '',
                    style: 'transform: rotate(45deg)'
                },
                // {
                //     name: 'journal-text',
                //     notificationsnumber: '2',
                //     tooltip: 'Novo Agendamento',
                // },
                // {
                //     name: 'three-dots',
                //     notificationsnumber: '',
                // },
            ],
            clientSelected: { msgs: [], country: "" },
            contactSelected: null,
            departmentSelected: null,
            channelSelected: null,
            clientSearch: { value: '', filter: 0, filterText: '', search: false },
            insertFile: false,
            showEmojiDialog: false,
            selectedItemEmoji: null,
            browserNotification: Notification.permission,
            newMessage: false,
            inputSend: '',
            mediaToSend: [],
            recorder: false,
            chunks: [],
            recordInterval: '',
            recordingTime: '00:00',
            recordedMedia: { url: null, file: null },
            selectedMedia: '',
            waitingAttendanceCount: 0,
            queueAttendanceCount: 0,
            finishedAttendanceCount: 0,
            inAttendanceCount: 0,
            currentMedia: null,
            inputMediaOverlay: false,
            fileParam: null,
            loadingMessages: false,
            oldAttendances: [],
            clientData: false,
            tabulations: [],
            browser: '',
            templates: [
                { id: '99999', nome: 'Criar abordagem', conteudo: '' }
            ],
            selectedTemplate: { body: '', id: '99999' },
            lastNotificationSent: null,
            editClientName: false,
            editClientEmail: false,
            editClientId: false,
            editClientDocument: false,
            editClientGenre: false,
            headerHeight: 0,
            workTimeOver: false,
            attendanceType: "contacts",
            attendancesLength: 0,
            groupsLength: 0,
            inputSearchGroupMsg: "",
            msgSearchActual: 0,
            msgSearchTotal: 0,
            msgsHeight: 0,
            tags: [],
            selectTagOptions: [
                { value: null, text: "Nenhum" }
            ],
            loadingQuickAnswers: false,
            quickAnswers: [],
            showQuickAnswers: false,
            operatorHasGroups: false,
            startingAttendance: false,
            loadingAttendances: false,
            loadingRetrieveContacts: false,
            retrieveContactsSearch: '',
            retrieveContacts: [],
            retrieveContactsTotal: 0,
            retrieveContactsPage: 1,
            retrieveTabIndex: 0,
            retrieveContactsPerPage: 10,
            secondaryChannels: [],
            catalogCategories: [],
            catalogCategoriesAll: [],
            catalogProducts: [],
            catalogSearch: "",
            loadingCategories: false,
            loadingProducts: false,
            hasSearchedCatalogSearch: false,
            hasSearchedCatalogCategory: false,
            activeCommunicationTimeLeft: 0,
            groupsUnreadCount: 0,
            attendancesUnreadCount: 0,
            internalChatsUnreadCount: 0,
            forwardMessage: null,
            contactsToForward: [],
            countryOptions: [],
            catalogFilters: {
                type: null,
                selectedCategoryId: null,
                selectedSubcategoryId: null
            },
            currentProductsPage: 1,
            currentAttendancesPage: 1,
            sendingProductsCart: false,
            sendingProductsList: false,
            selectedProductList: null,
            selectedProductCart: null,
            loadingClientCRMData: false,
            loadingCRMOrganizations: false,
            loadingCRMDeals: false,
            loadingCRMDealPipelines: false,
            newRDOrganization: {},
            newRDDeal: {
                deal: {},
                organization: {},
            },
            newRDAnnotation: {},
            rdCreatingOrganization: false,
            rdCreatingDeal: false,
            rdCreatingAnnotation: false,
            rdLoadingAnnotations: false,
            showCrmData: false,
            rdSavingDealPipeline: false,
            rdCurrentAnnotationsPage: 1,
            rdNewTask: {
                type: 'task'
            },
            rdTasks: [],
            rdTasksTotal: 0,
            rdCurrentTasksPage: 1,
            rdLoadingTasks: false,
            rdSelectedTask: null,
            rdCreatingTask: false,
            rdUsers: [],
            rdLoadingUsers: false,
            rdSavingUser: false,
            rdCampaigns: [],
            rdLoadingCampaigns: false,
            rdDealSources: [],
            rdLoadingDealSources: false,
            rdDealPipelineChanged: false,
            rdPreviousDealPipeline: null,
            attendanceWindowIsOpened: true,
            sendingAudio: false,
            statusFilter: '',
            timeoutGroups: null,
            notificationConfigModal: {
                contacts: true,
                groups: true
            },
            calendarEvent: null,
            loadingKanbanCards: false,
            kanbanSteps: [],
            showKanbanCards: false,
            resentTime: 10,
            msgCharLimit: 65536,
            quickAnswer: {},
            showQuickAnswerDialog: false,
            windowRemainingTime: '00:00',
            audioExtension: 'ogg',
            startAttendanceParams: {},
            internalSearch: "",
            internalChat: null,
            internalNewMessage: null,
            loadingFreight: false,
            loadedFreight: false,
            checkingCartAvailability: false,
        }
    }
}
</script>

<style>
    .tablemsgs tr{
        display: flex;
        width: 100%;
    }
    .tablemsgs th{
        width: 100%;
    }
    .tablemsgs td{
        padding-bottom: 0.25em;
        overflow-wrap: break-word;
        word-break: break-word;
        box-shadow: none;
        width: 100%;
    }
    .tablemsgs tr:not(:first-child) td{
        padding-top: 0;
    }
    input#calendarNotifyCheckbox:checked {
        display: block;
    }
    input#calendarNotifyCheckbox:not(:checked) {
        display: none;
    }
    .dropdownanexes{
        background-color: transparent !important;
        border-color: transparent !important;
        flex-direction: column;
        min-width: fit-content;
        cursor: default;
        margin-left: -0.3em;
        z-index: 2;
    }
    .dropdownanexes .b-icon{
        color: #fff;
        font-size: 1.5em !important;
    }
    .dropdownanexes svg{
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    .dropdownanexesitem{
        margin: 15px 0;
        margin-left: -12px;
        box-shadow: 1.4px 2px 5px #555 !important;
    }
    .dropdownanexesitem:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .dropdownanexesitembtn{
        padding: 12px;
        padding-bottom: 8px;
        width: fit-content;
        background-color: transparent !important;
    }
    .clientRating .b-rating-star{
        max-width: fit-content;
    }
     .tabnavactive{
        background-color: transparent !important;
        border: none !important;
        border-bottom: 3px solid hsl(244deg 26% 80%) !important;
    }
    .mediaTab .nav-link{
        color: hsl(244deg 6% 42%) !important;
        padding: 0px 10px;
        font-size: 0.9em;
    }
    .mediaTab .nav-link:hover{
        border: none !important;
        border-bottom: 3px solid hsl(244deg 26% 90%) !important;
    }
    .navwrapper{
        width: 100%;
        overflow-x: hidden;
    }
    .navwrapper ul{
        width: 100px;
        flex-flow: nowrap;
        border-bottom: 1px solid transparent;
    }
    .mediaTabContent{
        margin: auto -1rem;
    }
    .inputFileSmall{
        height: 65px !important;
        width: 65px;
    }
    .inputFileSmall .custom-file-label{
        /* height: 65px !important; */
        border: none;
        font-size: 0.5em;
        background-color: white !important;
        width: 65px;
        margin-bottom: 1em;
    }
    .inputFile input, .inputFile .custom-file-label::after{
        display: none;
    }
    .inputFile.border-0 .custom-file-label{
        border: none;
    }
    .inputFile .custom-file-label{
        background-color: transparent;
        /* height: 250px; */
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: hsl(246deg 15% 41%);
        border-color: hsl(246deg 29% 77%);
        border-radius: 0;
        text-transform: uppercase;
    }
    .modalreceivedmediafooter{
        justify-content: flex-start;
    }
    .carouselReceivedMedia .carousel-control-prev-icon, .carouselReceivedMedia .carousel-control-next-icon{
        background-size: 75%;
        background-color: #8078f8;
        border-radius: 50%;
    }
    .countrySelect .vs__dropdown-toggle{
        border-color: rgb(222 226 230);
        border-radius: 0.3em;
        padding: 0.25em 0px;
    }
    .clientCountrySelect .vs__dropdown-toggle{
        border-color: #eee;
        border-radius: 0.3em;
    }
    .clientCountrySelect .vs__selected-options {
        padding: 0.21em 0;
    }
    .darkenTextNavActive {
        color: #444 !important;
    }
    .searchSelect .vs__dropdown-toggle {
        border-color: #eee !important;
        padding: .3em .25em;
    }
    .quickAnswerEmojiPicker {
        position: absolute;
        width: 250px;
    }
    .quickAnswerEmojiPicker #Categories {
        order: 5;
    }
    .quickAnswerEmojiPicker #InputSearch {
        order: 4;
    }
    .quickAnswerEmojiPicker .border {
        border: none !important;
    }
    .quickAnswerEmojiPicker .emoji {
        display: flex !important;
        justify-content: center !important;
    }
    .quickAnswerEmojiPicker .container-emoji {
        overflow: auto !important;
    }
    .inputReactEmojiPicker #Emojis, .inputReactEmojiPicker #Emojis .container-emoji {
        height: 100%;
    }
    @media (max-width: 576px) {
        #protocolDiv{
            font-size: 0.8em;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    @media (max-width: 425px) {
        #retrieveContactsTab .nav-tabs {
            white-space: nowrap;
            flex-wrap: nowrap;
            overflow: auto;
            justify-content: start !important;
        }
        .msgsTextarea {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
            font-size: 0.9em;
        }
    }
</style>

<style scoped>
    .numbercircle{
        background-color: hsl(140deg 38% 59%);
        color: #fff;
        position: absolute;
        top: -5px;
        right: -20px;
        padding: 0px 5px;
        font-size: 0.7em;
        border-radius: 50%;
    }
    .numbercircle2{
        padding: 0px 5px;
        font-size: 0.7em;
    }
    .panelicons{
        position: relative;
        cursor: pointer;
    }
    .panelicon{
        font-size: 1.4em;
        color: #222;
    }
    .panelicons:hover .panelicon{
        color: #555;
    }
    .panelicons:not(:last-child) {
        margin-right: 20px;
    }
    .iconnotifications{
        background-color: hsl(10deg 79% 63%);
        color: #fff;
        position: absolute;
        top: -5px;
        right: -8px;
        padding: 0px 5px;
        font-size: 0.7em;
    }
    .clientsDiv{
        cursor: pointer;
    }
    .clientsDiv:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .clientsDivInfo{
        width:100%;
        /* overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
    }
    .clientStatus{
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
    }
    .clientsWrapper{
        overflow-y: auto;
    }
    .msgsdiv{
        background-image: url('https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fassets_whatsapp%5B1%5D.png?alt=media&token=6b8e699e-d1cf-4d2a-a3cd-ce5cba3cc205');
        background-repeat: repeat-x;
        overflow-y: auto;
        /* position: relative; */
        height: 100%;
    }
    .arrowLeft{
        position: absolute;
        left: 0;
        top: 0.75em;
    }
    .arrowRight{
        position: absolute;
        right: 0;
        top: 0.75em;
    }
    .inputFileSub{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid hsl(245deg 12% 63%) !important;
        border-radius: 0;
    }
    .inputFileSubEmoji{
        position: absolute;
        right: 0;
        top: 1em;
    }
    .inputFileSubEmojiPicker{
        position: absolute;
        top: -25em;
        right: 1em;
        z-index: 1;
        flex-direction: column-reverse !important;
    }
    .inputMessageEmojiPicker{
        right: unset;
        top: -26em;
        left: 0;
    }
    .inputReactEmojiPicker{
        position: absolute;
        flex-direction: column-reverse !important;
        z-index: 1;
        /* bottom: 0; */
        top: 0;
        left: 1.5em;
    }
    .inputFileSendBtn{
        position: absolute;
        right: 1.2em;
        /* top: 0em; */
        border: none;
        box-shadow: -2px 5px 5px #555!important;
    }
    .msgsScrollButton{
        position: absolute;
        bottom: 1.5em;
        right: 1.5em;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        opacity: 0.75;
    }
    .msgsScrollButton:hover{
        opacity: 1;
    }
    .tablemsgs{
        margin: 0;
        position: relative;
        height: inherit;
        overflow-y: scroll;
    }
    .msgdtday{
        background-color: #95a5a6;
        color: #fff;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        text-align: center;
        display: flex;
        margin: 0 auto;
        width: fit-content;
    }
    .msgsent{
        background-color: hsl(94deg 78% 87%);
        border-radius: 0.25rem;
        padding: 5px 10px;
        box-shadow: 2px 2px 5px #999;
        position: relative;
        padding-right: 3.25em;
        margin-left: 1em !important;
        margin-right: 0.5em !important;
        min-width: 8%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-break: auto;
    }
    /* .msgsent:last-child{
        margin-bottom: 5px;
    }
    .msgsent:only-child{
        margin: 5px 0;
    } */
    .msgreceived{
        background-color: #fff;
        border-radius: 0.25rem;
        box-shadow: -2px 2px 5px #999;
        padding: 5px 10px;
        position: relative;
        padding-right: 3em;
        margin-right: 1em !important;
        margin-left: 0.5em !important;
        min-width: 8%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-break: auto;
    }
    #msgs:hover .reactButton{
        display: flex !important;
    }
    /* .msgreceived:last-child{
        margin-bottom: 5px;
    }
    .msgreceived:only-child{
        margin: 5px 0;
    } */
    .downloadmediadiv{
        background-color: #999;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.25em 2em;
        color: #222;
    }
    a{
        text-decoration: none !important;
    }
    .receivedmediadiv{
        flex-wrap:wrap;
        margin: 0.1em;
    }
    .receiveddownloadmediadiv{
        max-height: 75px;
        width: 82px;
        font-size: 0.8em;
        border-radius: 0;
    }
    .downloadmediadiv:hover {
        color: #000;
    }
    @-moz-document url-prefix() {
        .audioplayer{
            height: 40px !important;
        }
    }
    /* .controls > * {
        float:left;
        width:3.90625%;
        height:100%;
        margin-left:0.1953125%;
        display:block;
    } */
    .audioplayer::-webkit-media-controls-current-time-display{
        position: absolute;
        bottom: 0;
        left: 3em;
        font-size: 1em;
    }
    .audioplayer::-webkit-media-controls-time-remaining-display {
        position: absolute;
        bottom: 0;
        left: 5.25em;
        font-size: 1em;
    }
    .audioplayer::-webkit-media-controls-timeline {
        padding: 0;
        margin: 0;
    }
    .audioplayer::-webkit-media-controls-panel {
        display: -webkit-flex !important;
        background-color: inherit;
    }
    .apreceived::-webkit-media-controls-panel{
        background-color: #fff;
    }
    .apsent::-webkit-media-controls-panel{
        background-color: hsl(94deg 78% 87%);
    }
    .msgdttime{
        font-size: 0.7em;
        position: absolute;
        right: 5px;
        bottom: 2px;
        color: #555;
    }
    .msgdttimefloat{
        float: left;
    }
    .msgstatus{
        font-size: 1.6em;
        float: right;
        margin-left: 2px;
    }
    .answermsgdiv{
        display: flex;
        align-items: center;
        background-color: hsl(251deg 49% 93%);
        padding: 20px;
        position: relative;
    }
    .answermsgicon{
        color: hsl(250deg 13% 63%);
        font-size: 2em;
        cursor: pointer;
    }
    .answermsgicon:hover{
        color: hsl(250deg 13% 53%);
    }
    .answermsginput{
        border-radius: 25px;
        color: hsl(250deg 13% 53%);
        margin: 0 10px;
        border: none;
    }
    .answermsginput::placeholder{
        color: hsl(250deg 13% 63%);
    }
    .modalreceivedmediaheadericons{
        color: #777;
        padding-right: 15px;
    }
    .modalreceivedmediaheadericons .b-icon{
        margin-left: 20px;
    }
    .modalreceivedmediaheadericons .b-icon:hover{
        color: #333;
    }
    .modalreceivedmediaimg{
        height: 100%;
        width: 100%;
        max-width: 75vw;
        max-height: 300px;
        cursor: pointer;
    }
    .modalreceivedmediaarrow{
        background-color: hsl(138deg 43% 52%);
        color: #fff;
        padding: 5px 0;
        font-size: 2em !important;
    }
    .modalreceivedmediaarrow:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .modalreceivedmediafooter .modalreceivedmediaimg{
        height: 100px;
        max-width: 100px;
        cursor: pointer;
    }
    .receivedMediaImg{
        width: 33%;
    }
    .quickAnswer:last-child {
        border: none !important;
    }
    .msgsTextarea::-webkit-scrollbar {
        display: none;
    }
    .msgsTextarea {
        -ms-overflow-style: none;
        scrollbar-width: none;
        min-height: 3em;
    }
    #collapse-products.show {
        display: flex;
    }
    .collapsed > .when-open,
    .not-collapsed > .when-closed {
        display: none;
    }
    .productFromList:last-child > .row {
        border: none !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
    .crmButtonWrapper:hover > div {
        border-color: #555 !important;
        color: #555;
    }
    .crmProductDiv:last-child > span, .crmAnnotationsDiv:last-child {
        border: none !important;
    }
    .toggle-emoji {
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .toggle-emoji-icon {
        cursor: pointer;
        font-size: 2em;
    }
    .toggle-emoji-icon:hover {
        color: #555;
    }
    @media (min-height:618px) {
        .insertFileBackground{
            height: 354px;
        }
    }
    @media (min-height:657px) {
        .insertFileBackground{
            height: 460px;
        }
    }
    @media (min-height:725px) {
        .insertFileBackground{
            height: 535px;
        }
    }
    @media (min-height:768px) {
        .insertFileBackground{
            height: 474px;
        }
    }
    @media (min-height:800px) {
        .insertFileBackground{
            height: 504px;
        }
    }
    @media (min-height:870px) {
        .insertFileBackground{
            height: 600px;
        }
    }
    @media (min-height:925px) {
        .insertFileBackground{
            height: 634px;
        }
    }
    @media (min-height:947px) {
        .insertFileBackground{
            height: 652px;
        }
    }
    @media (min-height:962px) {
        .insertFileBackground{
            height: 668px;
        }
    }
    @media (min-height:1080px) {
        .insertFileBackground{
            height: 784px;
        }
    }
    @media (max-width: 425px) {
        #col1 {
            flex: auto;
        }
        #protocolDiv {
            line-height: 1.75em;
        }
        .finishBtn, .forwardBtn {
            padding: 0.5em
        }
    }
</style>